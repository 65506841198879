import Text from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../../utils/constants";
import FolderMenu from "./FolderMenu";
import MoreIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/FolderOpen";
import {
  FolderSharedByMe,
  FolderSharedToMe,
} from "../../../assets/FolderShareAssest";
import { useMyFolder } from "./MyFolder.hook";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    "& .MuiTooltip-arrow": {
      color: "#FFF",
    },
  },
}));

const MyFolder = () => {
  const {
    anchorEl,
    closeMenu,
    currentFolder,
    handleOptionClick,
    handleTableCellClick,
    myFolders,
    addModal,
  } = useMyFolder();

  return (
    <>
      <Button variant="outlined" sx={{ my: 2, ml: "auto" }} onClick={addModal}>
        Add New Folder
      </Button>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>Folder Name</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell># of Docs</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {myFolders &&
              myFolders?.map((row) => (
                <TableRow hover key={row.userfolder_id}>
                  <TableCell
                    onClick={() =>
                      handleTableCellClick({
                        id: row.userfolder_id,
                        name: row.folder_name,
                      })
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FolderIcon />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          ml: 1,
                        }}
                      >
                        <Text
                          fontSize={14}
                          marginLeft={1}
                          sx={{ cursor: "pointer" }}
                        >
                          {row.folder_name}
                        </Text>

                        {row.shared_by_me && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: 1,
                            }}
                          >
                            <FolderSharedByMe />
                            <Text
                              fontSize={"0.625rem"}
                              marginLeft={1}
                              color={Colors.primary}
                            >
                              Shared by me
                            </Text>
                          </Box>
                        )}
                        {row.shared_with_me && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: 1,
                            }}
                          >
                            <FolderSharedToMe />
                            <LightTooltip
                              title={row.shared_from}
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <Text
                                fontSize={"0.625rem"}
                                marginLeft={1}
                                sx={{ cursor: "default", letterSpacing: 0.4 }}
                              >
                                Shared with me ({row.shared_from})
                              </Text>
                            </LightTooltip>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleTableCellClick({
                        id: row.userfolder_id,
                        name: row.folder_name,
                      })
                    }
                  >
                    <Text fontSize={14}>{row.created_on}</Text>
                  </TableCell>

                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>{row.no_of_records ?? 0}</Text>

                    <IconButton onClick={(e) => handleOptionClick(e, row)}>
                      <MoreIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {currentFolder && (
        <FolderMenu
          open={Boolean(anchorEl)}
          anchor={anchorEl}
          onClose={closeMenu}
          currentFolder={currentFolder}
        />
      )}
    </>
  );
};

export default MyFolder;
