import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";
import { Colors } from "../../../utils/constants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Stack from "@mui/material/Stack";
import { useGetExternalFoldersLinks } from "../../../api/MyLibrary/Links.api";
import ExternalLinkMenu from "./ExternalLinkMenu";
import { GetExternalFolderLinksData } from "../../../types/GetExternalFolder.types";
import validator from "validator";
import { usagelogApi } from "../../../api/usageReportApi";

const ExternalLinksDetails = () => {
  const navigate = useNavigate();
  const { state }: { state: { id: string; folder_name: string } } =
    useLocation();
  const dispatch = useAppDispatch();

  const [currentFolder, setCurrentFolder] =
    useState<GetExternalFolderLinksData | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: myLinks } = useGetExternalFoldersLinks(state.id);
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const handleOptionClick = (
    event: React.MouseEvent<HTMLElement>,
    row: GetExternalFolderLinksData
  ) => {
    event.stopPropagation();
    setCurrentFolder(row);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const addModal = () =>
    dispatch(
      setModal({
        modalType: "ADD_LINK",
        modalProps: {
          show: true,
          title: "Add New Link",
          folder_id: state.id,
        },
      })
    );

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 2,
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          marginBottom={1}
          marginLeft={-1}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <Text variant="h6" fontWeight={"600"}>
            {state.folder_name}
          </Text>
        </Stack>

        <Button variant="outlined" onClick={addModal}>
          Add New Link
        </Button>
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>Links</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Descriptions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {myLinks &&
              myLinks?.map((row) => (
                <TableRow hover key={row.externallibrarydocuments_id}>
                  <TableCell>
                    <Text
                      fontSize={14}
                      sx={{ color: Colors.primary }}
                      onClick={() => logBasicSearchUsageData(114)}
                    >
                      {validator?.isURL(row?.topicurl) ? (
                        <a href={row.topicurl} target="_blank">
                          {row.topicurl}
                        </a>
                      ) : (
                        row.topicurl
                      )}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text fontSize={14}>{row.topic_name}</Text>
                  </TableCell>

                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>{row.remarks_1 === "" ? "-" : row.remarks_1}</Text>

                    <IconButton onClick={(e) => handleOptionClick(e, row)}>
                      <MoreIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {currentFolder && (
        <ExternalLinkMenu
          open={open}
          anchor={anchorEl}
          onClose={closeMenu}
          currentFolder={currentFolder}
        />
      )}
    </>
  );
};

export default ExternalLinksDetails;
