import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// Icons
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import SearchCircle from "../../assets/img/search-builder.png";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
// Assets
import "./style.css";
import { Stack } from "@mui/system";
import { navStyles as sx, StyledLink } from "./Nav.style";
import { setSearchKeyword } from "../../store/slice/browseJournal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardData,
  updateTocPageSearch,
} from "../../store/slice/browseJournal";
import { usagelogApi, usageReport } from "../../api/usageReportApi";
import {
  updateSort,
  updateSortName,
} from "../../store/slice/browseFilterSlice";
import { updateVolumeOfTOC } from "../../store/slice/browserFilterSliceDetails";
interface SideDrawerProps {
  toggleNav: boolean;
  drawerRef: React.MutableRefObject<null>;
  closeNav: () => void;
}

const SideDrawer = ({ toggleNav, drawerRef, closeNav }: SideDrawerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
  };
  // Drawer for mobile screen
  const navItems = [
    { label: "Search", path: "/search", icon: <SearchOutlinedIcon /> },
    {
      label: "Search Builder",
      path: "/search-builder",
      icon: (
        <img
          src={SearchCircle}
          alt="Advanced Search"
          style={{ padding: "2px" }}
        />
      ),
    },
    {
      label: "Author Finder",
      path: "/authorfinder",
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      label: "Journal Finder",
      path: "/BrowseJournalTableSearch",
      icon: <PlagiarismOutlinedIcon />,
    },
    // {
    //   label: "Book Finder",
    //   path: "/BookSearch",
    //   icon: <MenuBookOutlinedIcon />,
    // },
  ];

  function navLinkClick(path: string) {
    console.log("path", path);
    if (path === "/search-builder") {
      // usageReport("12");
      logBasicSearchUsageData(12);
    } else if (path === "/authorfinder") {
      // usageReport("15");
      logBasicSearchUsageData(15);
    } else if (path === "/BrowseJournalTableSearch") {
      // usageReport("76");
      logBasicSearchUsageData(76);
    } else if (path === "/search") {
      // usageReport("76");
      logBasicSearchUsageData(8);
    }

    dispatch(getCardData({}));
    dispatch(updateTocPageSearch([]));
    dispatch(
      setSearchKeyword({
        searchType: "",
        words: "",
      })
    );
    navigate(path);
    closeNav();
  }

  return (
    <Box
      ref={drawerRef}
      width={250}
      position={"fixed"}
      sx={{
        ...sx.drawerWrapper,
        transform: toggleNav ? "translateX(0)" : "translateX(-250px)",
        marginTop: "64px",
      }}
    >
      <List>
        {navItems?.map((item, i) => (
          <Stack
            key={i}
            direction={"row"}
            alignItems="center"
            sx={{
              ...sx.drawerItem,
            }}
          >
            {item.icon}
            <ListItem
              key={item.label}
              onClick={() => {
                dispatch(updateSort(""));
                dispatch(updateSortName("Journal"));
                sessionStorage.setItem("getTabSwitch", "0");
                navLinkClick(item.path);
                dispatch(updateVolumeOfTOC(""));
              }}
              sx={{ cursor: "pointer" }}
            >
              <StyledLink sx={sx.styledLink}>{item.label}</StyledLink>
            </ListItem>
          </Stack>
        ))}
        <Stack
          direction={"row"}
          alignItems="center"
          className="nav-items"
          sx={{
            display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
            paddingLeft: "20px",
          }}
        >
          <ManageSearchIcon />
          <ListItem key={"search-history"}>
            <StyledLink sx={sx.styledLink}>Search History</StyledLink>
          </ListItem>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          className="nav-items"
          sx={{
            display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
            paddingLeft: "20px",
          }}
        >
          <SavedSearchIcon />
          <ListItem key={"mark-res"}>
            <StyledLink sx={sx.styledLink}>View Marked Results</StyledLink>
          </ListItem>
        </Stack>
      </List>
    </Box>
  );
};

export default SideDrawer;
