import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Text from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import { Switch } from "../../components/Switch";
import HeaderSection from "./HeaderSection";
import Chip from "@mui/material/Chip";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearSearchTerm,
  updateSearchResults,
  updateSearchTerm,
  updateDataType,
  updateSubjectType,
  updateSubjectName,
  updateFullText,
  clearDataTypeFilters,
  updateRow,
  updatePage,
  updateMarkedArticleCount,
  updateSavedArticles,
  updateAlertSettingData,
  updateLinkToDisplay,
  reset,
  updateSearchType,
} from "../../store/slice/searchSlice";
import { queryConstructor } from "../../api/formQuery";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearLimits,
  clearPublicationFilter,
  updataLibraryOpac,
  updateAllFilter,
  updateSort,
} from "../../store/slice/filterSlice";
import { onSearch as search } from "../Search/onSearch";
import { useForm } from "react-hook-form";
import { useGetAutoComplete } from "../../api/Search/Autocomplete.api";
import useClickOutsideListner from "../../hooks/ClickOutsideListner.hook";
import {
  basicSearchForCounts,
  basicSearchMain,
} from "../../api/Search/BasicSearch.api";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import {
  GetCustomerByIDDatatype,
  GetCustomerByIDSubject,
} from "../../types/GetCustomerByID.types";
import { temporaryQueryConstructor } from "../AdvancedSearch/TemporaryQueryConstructor";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useGetMySearchPreference } from "../../api/Preferences/Subjects.api";
import { isPreferenceAdded, showIsProfileToast } from "../../utils/helper";
import { setModal } from "../../store/slice/modalSlice";
import { SaverArticlesType } from "../../types/interface";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import AutoCompleteField from "./AutoCompleteField";
import { validateSearchTerm } from "../../utils/validateSearchTerm";
import {
  resetAppliedQueries,
  updateQueries,
} from "../../store/slice/appliedQueries";
import { getAlertSettings } from "../../api/MyLibrary/TOCAlerts.api";
import getCustomerDetailsByIp from "../../api/getCustomerDetailByIp";
import { notify } from "../../utils/Notify";
import { loginSuccess } from "../../store/slice/loginSlice";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import {
  clearCustomerDetails,
  updateCustomerDetails,
} from "../../store/slice/customerDetails";
import { usagelogApi } from "../../api/usageReportApi";
import { GetMyPreferenceData } from "../../types/GetMySubjects.types";
import { ThemeContext } from "../../contexts";
import { dataTypes } from "../../utils/constants";
import { clearAllSetSearch } from "../../store/slice/setSearch";
import { getRemoteLogUserDetails } from "../../api/Auth/RemoteLog.api";
import { updateIsAuthorResult } from "../../store/slice/authorFinderSlice";
import { userLogin } from "../../api/userApi";
import { updateBrowseJournalSearch } from "../../store/slice/browseJournal";
import { getOpacData } from "../../api/Search/CollectionApi";
import { CustomTooltip } from "../MyLibrary/MyAlerts/Alerts.style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Drawer, IconButton, Link, ListItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Modal, keyframes } from "@mui/material";
import WelcomePage from "./WelcomePage";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import SearchCircle from "../../assets/img/search-builder.png";
import api from "../../api/endpoints";
import RightDrawer from "../../components/rightDrawer/rightDrawer";
import TemporaryDrawer from "../../components/rightDrawer/bannerRightDrawer";
import BannerRightDrawer from "../../components/rightDrawer/bannerRightDrawer";
import { BorderAll, BorderHorizontalOutlined } from "@mui/icons-material";

interface SearchInput {
  searchterm: string;
}

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const themeContext = useContext(ThemeContext);
  const { handleSubmit, control, setValue, watch, setError, clearErrors } =
    useForm<SearchInput>();
  const location = useLocation();

  const selector = useAppSelector((state) => state.login);
  const searchHistoryData = useAppSelector(
    (state) => state.searchHistory.handleHistory
  );
  // console.log("searchHistoryData", searchHistoryData);
  const searchReducer = useAppSelector((state) => state.searchReducer);

  const {
    ref,
    showList: showAutoComplete,
    setShowList: setShowAutoComplete,
  } = useClickOutsideListner(false);

  const isPreferenceEnabled = sessionStorage.getItem("preference") === "true";

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [autoCompleteData, setAutoCompleteData] = useState<string[] | null>(
    null
  );
  const [isPreference, setIsPreference] = useState(isPreferenceEnabled);
  const [allTags, setAllTags] = useState<GetCustomerByIDDatatype[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [subjectData, setSubjectData] = useState<GetCustomerByIDSubject[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<number[]>([0]);
  const [selectedSubjectNames, setSelectedSubjectNames] = useState<string[]>(
    []
  );
  const [isAllSubjectSelected, updateSubjectSelected] = useState(false);
  const [showIpLoginModal, setIpLoginModal] = useState(false);

  //banner
  const [isBanner, setIsBanner] = useState(false);

  //Open close welcome modal
  const [modalIsOpen, setModalIsOpen] = useState(false);

  //Get Customer Data
  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  //Get Personalized Data for Preference
  const { data: myPreference, refetch } = useGetMySearchPreference(
    selector.user_id,
    isPreference
  );
  const [isProfileUser, setIsProfileUser] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const searchBoxSearchTerm = urlParams.get("q") || "";

  const logSearchUsageData = (usageActionID: any) => {
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      ipAddress,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  useEffect(() => {
    if (api.baseApi !== "https://diskoverapi.jgatenext.com") {
      setIsBanner(true);
    } else {
      setIsBanner(false);
    }
  }, [isBanner]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data?.ip);
      } catch (error) {
        setIpAddress("0");
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
    fetchRemoteLogUserDetails();
  }, []);

  useEffect(() => {
    if (location.pathname === "/search/") {
      handleSearchBoxSearch();
    }
  }, [location.pathname, searchBoxSearchTerm]);

  async function fetchRemoteLogUserDetails() {
    if (customerData?.informaticscustomer_id) return;
    let customerid = sessionStorage.getItem("informaticscustomer_id");
    if (customerid) return;

    const response = await getRemoteLogUserDetails();
    if (response) {
      sessionStorage.setItem(
        "informaticscustomer_id",
        response?.informaticscustomer_id?.toString()
      );
      sessionStorage.setItem("isProfileUser", "false");
      window.location.reload();
    }
  }

  useEffect(() => {
    dispatch(clearAllFilters());
    // dispatch(clearDataTypeFilters());
    dispatch(updateMarkedArticleCount([]));
    dispatch(clearAllFiltersValue());
    dispatch(resetAppliedQueries());
    dispatch(clearPublicationFilter());
    dispatch(clearAllSetSearch());
    dispatch(updateSearchType("1"));
    dispatch(updateIsAuthorResult(false));
    dispatch(updateBrowseJournalSearch(false));
    dispatch(clearLimits());
    // fetch remote log user and authenticate
    // fetchRemoteLogUserDetails()
  }, []);

  useEffect(() => {
    // fetch mypreference data
    sessionStorage.getItem("isProfileUser") === "true" && refetch();
  }, [sessionStorage.getItem("isProfileUser")]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const userId = sessionStorage.getItem("isProfileUser");
      if (userId === "true") {
        setIsProfileUser(true);
      } else if (userId === "false") {
        setIsProfileUser(false);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  //Set Initial Data
  useEffect(() => {
    try {
      if (customerData) {
        let filteredSubjectData: GetCustomerByIDSubject[] = [];
        let filteredSubjectIds: number[] = [];
        let filteredSubjectNames: string[] = [];
        let filteredAllTags: GetCustomerByIDDatatype[] = [];
        let filteredSelectedTags: number[] = [];
        let isAllSelected: boolean = true;
        const selectedDocTypes =
          Array.isArray(searchReducer.dataType) &&
          searchReducer.dataType?.filter((x) => x !== 2);
        const selectedSubjects =
          Array.isArray(searchReducer.subjectType) && searchReducer.subjectType;

        //Clear All Tag's Filters
        // dispatch(clearDataTypeFilters());

        if (isPreference && myPreference) {
          //Get My Subject Data
          filteredSubjectData = myPreference.Subjects?.map(
            ({ subject2_details }) =>
              subject2_details.filter(({ is_favorite }) => is_favorite)
          )
            .flat()
            ?.map((l2) => {
              return {
                datsubjectmasterlevel1_id: -1, //Just for Integrity's Sake
                datsubjectmasterlevel2_id: l2.subject_id2,
                level2subject_name: l2.subject_name2,
              };
            });

          //Get My Subject Data IDs
          filteredSubjectIds = filteredSubjectData?.map(
            ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
          );
          //Get My Subject Data Names
          filteredSubjectNames = filteredSubjectData?.map(
            ({ level2subject_name }) => level2subject_name
          );

          //Get My Sources
          filteredAllTags = myPreference?.DataTypes?.map((source) => {
            return {
              dat_resourcetype_id: source.datatype_id,
              accesstype: source.datatype_name,
              image_small: source.datatype_name.toLocaleLowerCase(),
            };
          });

          //Get My Source ID's
          filteredSelectedTags = myPreference?.DataTypes.filter(
            ({ is_favorite }) => is_favorite
          )?.map(({ datatype_id }) => datatype_id);

          filteredAllTags = customerData?.datatypes?.filter(
            (x) => x.dat_resourcetype_id !== 2
          );

          //Should Select All Tags if True
          isAllSelected =
            filteredSelectedTags.length === myPreference?.DataTypes.length;
        } else {
          //Get All Subject Data

          filteredSubjectData = customerData.subject;

          //Get All Subject Data IDs
          filteredSubjectIds = Array.isArray(filteredSubjectData)
            ? filteredSubjectData?.map(
                ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
              )
            : [];

          //Get My Subject Data Names
          filteredSubjectNames = Array.isArray(filteredSubjectData)
            ? filteredSubjectData?.map(
                ({ level2subject_name }) => level2subject_name
              )
            : [];

          //Get All Sources
          filteredAllTags = customerData?.datatypes?.filter(
            (x) => x.dat_resourcetype_id !== 2
          );
        }

        //Update Subjects
        setSubjectData(() => filteredSubjectData);

        let docArray: any = [];
        let lib_baseUrl = customerData?.lib_baseurl && {
          dat_resourcetype_id: 0,
          accesstype: "All",
          image_small: "all",
        };

        if (filteredAllTags) {
          if (customerData?.lib_baseurl) {
            docArray = [
              {
                dat_resourcetype_id: 0, // Custom ID, 0 => All
                accesstype: "All",
                image_small: "all",
              },
              ...filteredAllTags,
              {
                dat_resourcetype_id: 100,
                accesstype: "Library Catalogue",
                image_small: "library_catalogue",
              },
            ];
          } else {
            docArray = [
              {
                dat_resourcetype_id: 0, // Custom ID, 0 => All
                accesstype: "All",
                image_small: "all",
              },
              ...filteredAllTags,
            ];
          }
        }

        let selectedDoc = isAllSelected
          ? docArray?.map((x: any) => x?.dat_resourcetype_id)
          : filteredSelectedTags;

        /**
         * updating doc types
         */
        if (selectedTags.length && !isPreference) {
          setSelectedTags(selectedTags);
        } else if (
          selectedDocTypes &&
          selectedDocTypes.length &&
          !isPreference
        ) {
          setSelectedTags(selectedDocTypes);
        } else {
          setSelectedTags(selectedDoc);
        }

        /**
         * updating subject types
         */
        if (selectedSubjects && selectedSubjects.length && !isPreference) {
          setSelectedSubjects(() => selectedSubjects);
          // updating subnames
          let subNames: string[] = [];
          for (let id of selectedSubjects) {
            for (let obj of customerData?.subject) {
              if (id === obj?.datsubjectmasterlevel2_id) {
                subNames.push(obj?.level2subject_name);
              }
            }
          }
          dispatch(updateSubjectName(subNames));
          setSelectedSubjectNames(() => subNames);
        } else {
          setSelectedSubjects(() => filteredSubjectIds);
          //Update Subject Names
          dispatch(updateSubjectName(filteredSubjectNames));
          setSelectedSubjectNames(() => filteredSubjectNames);
        }

        //Update Tags
        setAllTags(() => docArray);
        // dispatch(updateDataType(selectedDoc));
      }
    } catch (err) {
      console.log("Unknown error occoured", err);
    }
  }, [customerData, dispatch, isPreference, myPreference]);

  //Get AutoComplete Data
  const { data: autoComplete } = useGetAutoComplete(searchValue);

  //Show AutoComplete List
  useEffect(() => {
    const subscription = watch(({ searchterm }: string | any) => {
      if (searchterm) {
        clearErrors("searchterm");
        setSearchValue(searchterm?.toLocaleLowerCase());
        searchterm.length >= 3
          ? setShowAutoComplete(true)
          : setShowAutoComplete(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [setShowAutoComplete, watch]);

  useEffect(() => {
    updateAllSubject();
  }, [selectedSubjects.length, customerData?.subject?.length]);

  useEffect(() => {
    selectAllTags();
  }, [selectedTags.length]);

  //Set AutoComplete Data
  useEffect(() => {
    if (autoComplete) {
      const arr = autoComplete?.map((x) => Object.keys(x)[0]);
      setAutoCompleteData(() => arr);
    }
  }, [autoComplete]);

  //Handle Getting names of selected subjects
  const handleSubjectName = (arr: number[]) => {
    let names: string[] = [];
    subjectData.forEach((item) => {
      if (arr.includes(item.datsubjectmasterlevel2_id))
        names.push(item.level2subject_name);
    });
    setSelectedSubjectNames(() => names);
  };

  //Handle Subject Dropdown
  const handleSubjectDropdown = (data: GetCustomerByIDSubject) => {
    //If id exists in selectedSubjects
    // usageReport("9");

    logSearchUsageData(9);
    if (selectedSubjects.includes(data.datsubjectmasterlevel2_id)) {
      //Remove
      const newArr = selectedSubjects.filter(
        (x) => x !== data.datsubjectmasterlevel2_id
      );

      handleSubjectName(newArr);
      setSelectedSubjects(() => newArr);
    } else {
      //Add
      const newArr = [...selectedSubjects, data.datsubjectmasterlevel2_id];

      handleSubjectName(newArr);
      setSelectedSubjects(() => newArr);
    }
  };

  //Handle Tags
  const handleTags = (id: number) => {
    // usageReport("10");
    logSearchUsageData(10);
    sessionStorage.setItem("preference", "false");
    setIsPreference(false);
    let allDocIds: any = customerData?.datatypes
      .filter((x) => x.dat_resourcetype_id !== 2)
      ?.map((obj) => obj?.dat_resourcetype_id);
    if (id === 0) {
      // if the preference is on and user select all the preference toggle should be turn off
      sessionStorage.setItem("preference", "false");
      setIsPreference(false);
      //"All" is Selected
      if (
        selectedTags.length === allDocIds?.length + 2 ||
        selectedTags.length === allDocIds?.length + 1
      ) {
        setSelectedTags([]);
      } else {
        setSelectedTags(() => [...allDocIds, 0, 100]);
      }
    } else {
      if (selectedTags.includes(id)) {
        //Remove
        const newArr = selectedTags
          .filter((item) => item !== id)
          .filter((x) => x !== 0);
        setSelectedTags(() => newArr);
      } else {
        //Add Current & deselct "All"
        let curr = selectedTags.filter((item) => item !== 0);
        setSelectedTags([...curr, id]);
      }
    }
  };

  function selectAllTags() {
    let allDocIds: any = customerData?.datatypes
      .filter((x) => x.dat_resourcetype_id !== 2)
      ?.map((obj) => obj?.dat_resourcetype_id);

    let selectedDocs = selectedTags.filter((str) => str !== 2);
    selectedDocs = selectedDocs.filter((str) => str !== 100);

    if (allDocIds?.length === selectedDocs.length) {
      setSelectedTags((prev) => [...prev, 0]);
      sessionStorage.setItem("preference", "false");
      setIsPreference(false);
    }
  }

  // Main Search Function
  async function handleSearch({ searchterm }: SearchInput) {
    if (loading) return;
    //Clear States
    dispatch(clearSearchTerm());
    dispatch(clearAllFiltersValue());

    if (Array.isArray(selectedSubjects) && selectedSubjects.length === 0) {
      setError("searchterm", {
        type: "custom",
        message: "Minimum one Subject should be selected to perform search",
      });
      return;
    }

    if (Array.isArray(selectedTags) && selectedTags.length === 0) {
      setError("searchterm", {
        type: "custom",
        message:
          "Minimum one Document type should be selected to perform search",
      });
      return;
    }
    // if (selectedTags.includes(100) && selectedTags.length === 1) {
    //   dispatch(updataLibraryOpac(true));
    //   await dispatch(search());
    // }

    if (!searchterm.trim()) {
      setError("searchterm", {
        type: "required",
        message: "Please input valid keyword / topic to search",
      });
      return;
    }

    if (!validateSearchTerm(searchterm)) {
      setError("searchterm", {
        type: "required",
        message: "Please input valid keyword / topic to search",
      });
      return;
    }

    setLoading(true);

    const searchedData = {
      key: "search-term",
      value: searchterm.trim(),
    };

    //Update States
    dispatch(updateAllFilter(searchedData));
    dispatch(updateSearchTerm(searchterm.trim()));
    dispatch(updateSubjectType(selectedSubjects));
    dispatch(updateSubjectName(selectedSubjectNames));
    dispatch(updateRow(15));
    dispatch(updatePage(1));
    dispatch(updateSort("dateofpublication desc"));
    dispatch(
      updateQueries({
        key: "searchTerm",
        value: searchterm.trim(),
      })
    );

    let encodedSubjects = "";
    if (selectedSubjects.length === 0) {
      encodedSubjects = `(${temporaryQueryConstructor(
        subjectData?.map((x) => x.datsubjectmasterlevel2_id)
      )})`;
    } else {
      encodedSubjects = `(${temporaryQueryConstructor(selectedSubjects)})`;
    }

    let encodedTags = "";
    let tags = [...selectedTags];
    if (tags.includes(1) && !tags.includes(2)) tags.push(2);

    dispatch(updateDataType(tags));

    if (tags.length === 7 && tags.includes(0)) {
      encodedTags = "( 1 OR 4 OR 2 OR 3 OR 8 OR 24 )";
    } else {
      encodedTags = `(${temporaryQueryConstructor(tags)})`;
    }
    if (tags.length === 0) encodedTags = "( 1 OR 4 OR 2 OR 3 OR 8 OR 24 )";

    const queryObj: any = {
      titleKeywordsAbs: searchterm.trim(),
      sort: "dateofpublication desc",
      fq__resource_type: encodedTags,
      rows: 15,
      op_mode: "and",

      sessionid: sessionStorage.getItem("session_key"),
      profileuserid: sessionStorage.getItem("user_id"),
      datsearchtype_id: "1",
      customerid: sessionStorage.getItem("informaticscustomer_id"),
    };

    if (
      encodedSubjects &&
      selectedSubjects.length &&
      !selectedSubjects.includes(0)
    ) {
      queryObj.fq__subjects_id_l2 = encodedSubjects;
    }

    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      queryObj.fq__acl_group = customerData.consortia_filter;
    }
    const customerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : "0";
    const fullTextQuery = await queryConstructor({
      ...queryObj,
      // fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${customerId}))`,
    });

    const fullTextQueryHistory = await queryConstructor({
      ...queryObj,
      //fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${customerId}))`,
    });

    const allQuery = await queryConstructor({
      ...queryObj,
    });

    // const logSearchQuery = await queryConstructor({
    //   ...queryObj,
    //   logsearchhistory: true,
    // });

    const logSearchQuery = await queryConstructor({
      ...queryObj,
      "fq__(fulltext": `true OR acl_group=(${customerId}))`,
      logsearchhistory: true,
    });

    basicSearchMain(logSearchQuery);

    dispatch(
      updateQuery({
        fullTextQuery: fullTextQuery,
        allTextQuery: allQuery,
      })
    );

    if (selectedTags.includes(100) && selectedTags.length === 1) {
      dispatch(updataLibraryOpac(true));
      let kohaQueryObj = {};
      Object.assign(kohaQueryObj, {
        search_keyword: searchterm.trim(),
      });
      Object.assign(kohaQueryObj, {
        customer_id: customerData?.informaticscustomer_id,
      });
      Object.assign(kohaQueryObj, { page_no: 1 });
      Object.assign(kohaQueryObj, { per_page: 15 });

      let kohaQuery = await queryConstructor(kohaQueryObj, "AND", true);

      const data: any = await getOpacData(kohaQuery);
      dispatch(updateSearchResults(data?.data));

      if (data?.data?.docs) {
        navigate("/basicSearchScreen?searchterm=" + searchterm, {
          state: {
            searchTerm: searchterm,
            fullTextQuery: fullTextQuery,
            allQuery: allQuery,
          },
        });
      } else {
        notify("error", "No results found");
        setLoading(false);
      }
      return;
    }

    const fullTextData = await basicSearchMain(fullTextQueryHistory);
    //const allText = await basicSearchForCounts(allQuery);
    const fulltext = await basicSearchForCounts(fullTextQuery);
    await checkArticleExistance(fullTextData?.docs);
    await findAlertTypeSetting(fullTextData?.docs);
    await linkToDisplay(fullTextData?.docs);

    dispatch(updateSearchResults(fullTextData));
    dispatch(updateFullText(true));
    logSearchUsageData(11);
    logSearchUsageData(29);

    setLoading(false);

    if (fullTextData?.docs) {
      navigate("/basicSearchScreen?searchterm=" + searchterm, {
        state: {
          searchTerm: searchterm,
          fullTextQuery: fullTextQuery,
          allQuery: allQuery,
          //allCount: allText?.hits,
          fullCount: fulltext?.hits,
        },
      });
    }
  }

  const handleAutoCompleteSelect = (
    term: string,
    reason: "createOption" | "selectOption" | "removeOption"
  ) => {
    const searchTerm = `"${term}"`;
    if (reason === "createOption") {
      setValue("searchterm", term);
    } else {
      setValue("searchterm", searchTerm);
    }
    setShowAutoComplete(false);
  };

  const handlePreference = (event: React.ChangeEvent<HTMLInputElement>) => {
    logSearchUsageData(112);
    if (isProfileUser) {
      let isMyPrefAdded: boolean = true;
      if (myPreference) {
        isMyPrefAdded = isPreferenceAdded(myPreference);
      }

      if (!isMyPrefAdded && event.target.checked) {
        // navigate("/preferences");
        dispatch(
          setModal({
            modalType: "PREFERENCE",
            modalProps: {
              open: true,
              checkPreferenceAdded,
            },
          })
        );
      }
      if (!event.target.checked) {
        dispatch(clearDataTypeFilters());
        // On dissabling the preference select all doc types
        let allDocIds: any = customerData?.datatypes
          .filter((x) => x.dat_resourcetype_id !== 2)
          ?.map((obj) => obj?.dat_resourcetype_id);
        setSelectedTags(() => [...allDocIds, 0]);
      }
      setIsPreference(event.target.checked);
      sessionStorage.setItem("preference", event.target.checked.toString());
    } else {
      showIsProfileToast("Please login as profile user to use the preferences");
    }
  };

  async function checkPreferenceAdded(myPreference: GetMyPreferenceData) {
    if (!myPreference) return;
    const filteredSubjectData = myPreference.Subjects?.map(
      ({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
    ).flat();

    const filteredSelectedTags = myPreference?.DataTypes.filter(
      ({ is_favorite }: any) => is_favorite
    ).map(({ datatype_id }) => datatype_id);

    if (filteredSelectedTags?.filter((ele: any) => ele !== 2)?.length === 0) {
      setIsPreference(false);
      sessionStorage.setItem("preference", "false");
    }
  }

  function selectAllSubject() {
    if (!customerData) return;
    let filteredSubjectData: GetCustomerByIDSubject[] = [];
    let filteredSubjectIds: number[] = [];
    let filteredSubjectNames: string[] = [];
    if (isPreference && myPreference) {
      // if prefrence toggle is on
      filteredSubjectData = myPreference.Subjects?.map(({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
      )
        .flat()
        ?.map((l2) => {
          return {
            datsubjectmasterlevel1_id: -1, //Just for Integrity's Sake
            datsubjectmasterlevel2_id: l2.subject_id2,
            level2subject_name: l2.subject_name2,
          };
        });

      if (filteredSubjectData.length === selectedSubjects.length) {
        filteredSubjectIds = [];
        filteredSubjectNames = [];
      } else {
        filteredSubjectIds = filteredSubjectData?.map(
          ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
        );
        //Get My Subject Data Names
        filteredSubjectNames = filteredSubjectData?.map(
          ({ level2subject_name }) => level2subject_name
        );
      }
    } else {
      filteredSubjectData = customerData.subject;
      // if all subject are selected clear all subject
      if (customerData.subject.length === selectedSubjects.length) {
        // filteredSubjectData = [];
        filteredSubjectIds = [];
        filteredSubjectNames = [];
      } else {
        //Get All Subject Data IDs
        filteredSubjectIds = customerData.subject?.map(
          ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
        );

        //Get My Subject Data Names
        filteredSubjectNames = customerData.subject?.map(
          ({ level2subject_name }) => level2subject_name
        );
      }
    }
    setSubjectData(filteredSubjectData);
    //Update Subject Ids
    dispatch(updateSubjectType(filteredSubjectIds));
    setSelectedSubjects(() => filteredSubjectIds);

    //Update Subject Names
    dispatch(updateSubjectName(filteredSubjectNames));
    setSelectedSubjectNames(() => filteredSubjectNames);
    logSearchUsageData(9);
  }

  function updateAllSubject() {
    let preferenceSubject =
      myPreference &&
      myPreference?.Subjects?.map(({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
      )
        .flat()
        ?.map((l2) => {
          return {
            datsubjectmasterlevel1_id: -1, //Just for Integrity's Sake
            datsubjectmasterlevel2_id: l2.subject_id2,
            level2subject_name: l2.subject_name2,
          };
        });

    let isAllSelected = isPreference
      ? preferenceSubject?.length === selectedSubjects.length
      : customerData?.subject?.length === selectedSubjects.length;

    updateSubjectSelected(isAllSelected);
  }

  // to check the article exixtance in personal folders
  async function checkArticleExistance(dataArray: any) {
    if (!Array.isArray(dataArray)) return;
    const articleData = new FormData();

    const articleIds =
      Array.isArray(dataArray) && dataArray?.map((x: any) => x?.article_id);
    articleData.append("profile_id", selector.user_id);
    articleData.append("article_ids", articleIds.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateSavedArticles(filter_is_saved));
  }

  async function linkToDisplay(dataArray: any) {
    if (!Array.isArray(dataArray)) return;
    let formData = new FormData();

    let data = dataArray?.map((obj: any) => {
      let object: any = {
        customer_id: customerData?.informaticscustomer_id,
        article_id: obj?.article_id,
        consortia_virtualid: customerData?.consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      return object;
    });

    let formApiData = {
      data,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      dispatch(updateLinkToDisplay(response?.data));
    }
  }

  async function findAlertTypeSetting(dataArray: any) {
    try {
      // const resourcemaster_ids =
      //   Array.isArray(dataArray) &&
      //   dataArray?.map((x: any) => x?.resourcemaster_id);
      const resourcemasterData =
        Array.isArray(dataArray) &&
        dataArray?.filter((x: any) => x?.resourcemaster_id !== undefined);

      const resourcemaster_ids =
        Array.isArray(resourcemasterData) &&
        resourcemasterData
          ?.map((x: any) => x?.resourcemaster_id)
          ?.filter((y: any) => y !== undefined);

      if (Array.isArray(resourcemaster_ids)) {
        const alertSettingData = await getAlertSettings(
          selector.user_id,
          resourcemaster_ids
        );
        let alertDataRes = alertSettingData.data.data[0][0];
        dispatch(updateAlertSettingData(alertDataRes));
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleWelcomeClick() {
    setModalIsOpen(true);
  }

  function handleCloseModal() {
    setModalIsOpen(false);
  }
  const animationGreeting = keyframes`
    0%{
      transform: translateX(-68%);
    }
    100%{
      transform: translateX(62%);
    }
  `;

  const navItems = [
    {
      label: "Search Builder",
      path: "/search-builder",
      icon: (
        <img
          src={SearchCircle}
          alt="Advanced Search"
          style={{ padding: "2px" }}
        />
      ),
    },
    {
      label: "Author Finder",
      path: "/authorfinder",
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      label: "Journal Finder",
      path: "/BrowseJournalTableSearch",
      icon: <PlagiarismOutlinedIcon />,
    },
  ];

  const navLinkClick = (urldata: any) => {
    if (urldata === "/search-builder") {
      navigate("/search-builder");
    }

    if (urldata === "/authorfinder") {
      navigate("/authorfinder");
    }

    if (urldata === "/BrowseJournalTableSearch") {
      navigate("/BrowseJournalTableSearch");
    }
  };

  useEffect(() => {
    const searchedData = {
      key: "search-term",
      value: searchBoxSearchTerm.trim(),
    };
    if (location.pathname === "/search/") {
      dispatch(updateAllFilter(searchedData));
    }
  }, [searchBoxSearchTerm, location.pathname, dispatch]);

  async function handleSearchBoxSearch() {
    const searchedData = {
      key: "search-term",
      value: searchBoxSearchTerm.trim(),
    };

    dispatch(updateAllFilter(searchedData));
    dispatch(updateSearchTerm(searchBoxSearchTerm.trim()));
    dispatch(
      updateQueries({
        key: "searchTerm",
        value: searchBoxSearchTerm.trim(),
      })
    );

    dispatch(updateRow(15));
    dispatch(updatePage(1));
    dispatch(updateSort("dateofpublication desc"));

    let queryObj: any = {
      titleKeywordsAbs: searchBoxSearchTerm.trim(),
      sort: "dateofpublication desc",
      fq__resource_type: "(0 OR 1 OR 3 OR 4 OR 5 OR 8 OR 24 OR 2)",
      rows: 15,
      op_mode: "and",

      // sessionid: sessionStorage.getItem("session_key"),
      // profileuserid: sessionStorage.getItem("user_id"),
      datsearchtype_id: "1",
      // customerid: sessionStorage.getItem("informaticscustomer_id"),
      fq__subjects_id_l2:
        "(35 OR 34 OR 30 OR 21 OR 41 OR 22 OR 26 OR 23 OR 37 OR 27 OR 33 OR 39 OR 31 OR 36 OR 38 OR 40 OR 28 OR 24 OR 42 OR 43 OR 25 OR 32 OR 29)",
    };

    const customerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : "0";
    const fullTextQuery = await queryConstructor({
      ...queryObj,
      // fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${customerId}))`,
    });

    const fullTextQueryHistory = await queryConstructor({
      ...queryObj,
      //fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${customerId}))`,
    });

    const allQuery = await queryConstructor({
      ...queryObj,
    });

    const logSearchQuery = await queryConstructor({
      ...queryObj,
      logsearchhistory: true,
    });
    basicSearchMain(logSearchQuery);

    dispatch(
      updateQuery({
        fullTextQuery: fullTextQuery,
        allTextQuery: allQuery,
      })
    );

    const fullTextData = await basicSearchMain(fullTextQueryHistory);
    const allText = await basicSearchForCounts(allQuery);
    const fulltext = await basicSearchForCounts(fullTextQuery);
    await checkArticleExistance(fullTextData?.docs);
    await findAlertTypeSetting(fullTextData?.docs);
    await linkToDisplay(fullTextData?.docs);

    dispatch(updateSearchResults(fullTextData));
    dispatch(updateFullText(true));
    // logSearchUsageData(11);
    // logSearchUsageData(29);

    if (fullTextData?.docs) {
      navigate("/basicSearchScreen?searchterm=" + searchBoxSearchTerm, {
        state: {
          searchTerm: searchBoxSearchTerm,
          fullTextQuery: fullTextQuery,
          allQuery: allQuery,
          allCount: allText?.hits,
          fullCount: fulltext?.hits,
        },
      });
    }
  }

  return (
    <Box className="search-container">
      <HeaderSection />

      <Box sx={{ p: "0 66px", flexGrow: 2 }} className="search-field">
        {/* {isBanner && (
          <Box
            sx={{
              borderBottom: "1px solid black",
              borderTop: "1px solid black",
              backgroundColor: "#7852fb",

              padding: "10px 0",

              overflow: "hidden",
            }}
          >
            <Modal
              open={modalIsOpen}
              onClose={handleCloseModal}
              sx={{
                height: "fit-content",
                width: "90%",
                overflow: "hidden",
                margin: "0 auto",
                marginTop: "20px",
                borderRadius: "10px",
              }}
            >
              <WelcomePage handleClose={handleCloseModal} />
            </Modal>
            <Typography
              onClick={handleWelcomeClick}
              variant="h5"
              fontFamily={"Helvetica Neue"}
              fontWeight={"600"}
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "white",
                textAlign: "center",
                animationName: `${animationGreeting}`,
                animationDuration: "15s",
                animationTimingFunction: "linear",
                animationIterationCount: "infinite",
              }}
            >
              Welcome to J-Gate 3.0
            </Typography>
          </Box>
        )} */}
        {customerData?.consortiamaster_id === 1874 && (
          <Stack direction={"row"} spacing={5}>
            <Stack direction={"row"} spacing={1} marginTop={0} flexWrap="wrap">
              <Text sx={{ cursor: "pointer" }}>
                <Link
                  target="_blank"
                  href=" https://www.indiastatagri.com/"
                  style={{ cursor: "pointer", color: "green" }}
                  underline="none"
                >
                  India Agristat
                </Link>
              </Text>
            </Stack>
            <Stack direction={"row"} spacing={1} marginTop={0} flexWrap="wrap">
              <Text sx={{ cursor: "pointer" }}>
                <Link
                  target="_blank"
                  href="https://jgatenext.com/cdn/cerabooks.html"
                  style={{ cursor: "pointer", color: "green" }}
                  underline="none"
                >
                  CeRA Subscribed Elsevier e-books
                </Link>
              </Text>
            </Stack>
            <Stack direction={"row"} spacing={1} marginTop={0} flexWrap="wrap">
              <Text sx={{ cursor: "pointer" }}>
                <Link
                  target="_blank"
                  href={`${api.baseApi}/static/media/Curie-Trial.pdf`}
                  style={{ cursor: "pointer", color: "green" }}
                  underline="none"
                >
                  CURIE for Academic Writing
                </Link>
              </Text>
            </Stack>
          </Stack>
        )}
        <Stack
          direction={"row"}
          spacing={10}
          alignItems="center"
          paddingTop={{ xs: "2rem" }}
        >
          <Text variant="h5" fontFamily={"Helvetica Neue"} fontWeight={"600"}>
            Search
          </Text>
          <Stack direction={"row"} spacing={1} alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  sx={{ m: 1 }}
                  checked={isPreference}
                  onChange={handlePreference}
                  inputProps={{ "aria-label": "Preferences Switch" }}
                />
              }
              label="Preferences"
              labelPlacement="start"
            />
            <Stack>
              <CustomTooltip
                title={
                  <>
                    <span style={{ fontSize: "14px" }}>
                      Enable & Configure the preferences for Subjects & Document
                      types for customized search.
                    </span>
                    <br />
                    <span style={{ fontSize: "14px" }}>
                      Click on "Preferences" from Profile dropdown for settings
                    </span>
                  </>
                }
                arrow
                placement="top"
              >
                <IconButton size="medium">
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </CustomTooltip>
            </Stack>
          </Stack>

          <Stack direction={"row"} spacing={1} marginTop={0} flexWrap="wrap">
            {navItems?.map((item, i) => (
              <Stack key={i} direction={"row"} alignItems="center">
                {item.icon}
                <ListItem
                  key={item.label}
                  onClick={() => {
                    navLinkClick(item.path);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {item.label}
                </ListItem>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* Tags */}
        <Stack direction={"row"} spacing={1.2} marginTop={2} flexWrap="wrap">
          {allTags?.map((data) => (
            <Chip
              key={data.dat_resourcetype_id}
              label={
                data.accesstype === dataTypes.journals
                  ? dataTypes.journalArticle
                  : data?.accesstype
              }
              color="primary"
              variant={"outlined"}
              onClick={() => handleTags(data.dat_resourcetype_id)}
              sx={{
                p: "4px",
                borderRadius: "6px",
                border: "1px solid",
                borderColor: selectedTags.includes(data.dat_resourcetype_id)
                  ? "primary.main"
                  : "#DCD2EF",
                background: selectedTags.includes(data.dat_resourcetype_id)
                  ? "#DCD2EF"
                  : "#FFF",
                color: selectedTags.includes(data.dat_resourcetype_id)
                  ? "primary.main"
                  : "#71717A",
                textTransform: "capitalize",
                "&:hover": {
                  background: "#DCD2EF",
                },
              }}
            />
          ))}
        </Stack>

        {/* Search Fields */}
        <Stack
          direction={"row"}
          marginTop={2}
          alignItems="flex-end"
          mb={3}
          sx={{
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "flex-end" },
            gap: 2,
          }}
        >
          <Box
            width={300}
            sx={{ width: { xs: "90%", sm: "300px", lg: "300px" } }}
          >
            <Text fontWeight="500" fontFamily={"Helvetica Neue"}>
              Subject
            </Text>
            <Select
              fullWidth
              multiple
              renderValue={(selected) => selected && selected.join(", ")}
              value={isAllSubjectSelected ? ["All"] : selectedSubjectNames}
              sx={{ backgroundColor: "#fff" }}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: `${themeContext.windowHeight * 0.7}px` },
                },
                anchorOrigin: { vertical: "center", horizontal: "center" },
                transformOrigin: { vertical: "center", horizontal: "center" },
              }}
            >
              <MenuItem onClick={selectAllSubject}>
                <Checkbox checked={isAllSubjectSelected} />
                <Text>All</Text>
              </MenuItem>
              {Array.isArray(subjectData) &&
                subjectData?.map((data) => (
                  <MenuItem
                    key={data.datsubjectmasterlevel2_id}
                    value={data.datsubjectmasterlevel2_id}
                    onClick={() => handleSubjectDropdown(data)}
                  >
                    <Checkbox
                      checked={selectedSubjects.includes(
                        data.datsubjectmasterlevel2_id
                      )}
                    />
                    <Text>{data.level2subject_name}</Text>
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <AutoCompleteField
            fieldName="searchterm"
            options={autoCompleteData}
            control={control}
            handleSubmit={handleSubmit}
            handleSearch={handleSearch}
            handleAutoCompleteSelect={handleAutoCompleteSelect}
            setValue={setValue}
            loading={loading}
          />

          <Box>
            <Button
              onClick={handleSubmit(handleSearch)}
              size="large"
              variant="contained"
              sx={{
                height: "56px",
                width: "130px",
                marginBottom: { xs: "32px", sm: 0 },
              }}
              disabled={!selectedTags.length}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#fff",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                "Search"
              )}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Search;
