import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  time: number;
  counter: number;
  mainCounter: number;
}

const initialState: InitialState = {
  time: 0,
  counter: 1,
  mainCounter: 0,
};

const botListner = createSlice({
  name: "botListner",
  initialState,
  reducers: {
    incrementCounter(state) {
      state.counter += 1;
    },
    incrementMainCounter(state) {
      state.mainCounter += 1;
    },
    startTimer(state) {
      // if time is 3 minutes then reset
      if (state.time === 180) {
        state.time = 0;
        state.counter = 0;
        state.mainCounter = 0;
        return;
      }
      state.time += 1;
    },
    resetCounter(state) {
      state.counter = 0;
    },
    resetTimer(state) {
      state.time = 0;
    },
    resetBotListner(state) {
      state.time = 0;
      state.counter = 0;
      state.mainCounter = 0;
    },
  },
});

export const {
  incrementCounter,
  incrementMainCounter,
  startTimer,
  resetCounter,
  resetTimer,
  resetBotListner,
} = botListner.actions;
export default botListner.reducer;
