import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Facet } from "../modals/Analytics.modal";
import { useEffect, useState } from "react";
import {
  updateAllFilter,
  updateAuthor,
  updateInstitution,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateSpeaker,
} from "../../store/slice/filterSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { onSearch as search } from "../../screens/Search/onSearch";
import { hideModal } from "../../store/slice/modalSlice";
import { onAuthorResult as authorsearch } from "../../screens/AuthorFinder/onAuthorResult";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  updateAuthorPage,
  updateAuthorRow,
} from "../../store/slice/authorFinderSlice";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    textTransform: "capitalize",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "0.75rem",
    fontWeight: 400,
    letterSpacing: 0.4,
    "& .MuiTooltip-arrow": {
      color: "#FFF",
    },
  },
}));

const AnalyticsHorizontalChart = ({
  chartData,
  count,
  type,
}: {
  chartData: Facet[];
  count: number;
  type:
    | "institution"
    | "author"
    | "publisher"
    | "researcher"
    | "speaker"
    | "research";
}) => {
  const dispatch = useAppDispatch();
  const authorReducer = useAppSelector((state) => state.authorReducer);

  const [horizontalMax, setHorizontalMax] = useState<number>(0);
  const [horizontalBars, setHorizontalBar] = useState<Facet[] | null>(null);
  const [isNoData, setIsNoData] = useState<boolean>(false);

  useEffect(() => {
    if (chartData?.length > 0) {
      const currentCountArr = chartData.slice(0, count);

      let max = currentCountArr[0].count;
      for (let i = 1; i < currentCountArr.length; ++i) {
        if (currentCountArr[i].count > max) {
          max = currentCountArr[i].count;
        }
      }
      setHorizontalBar(currentCountArr);
      setHorizontalMax(max);
      setIsNoData(false);
    } else {
      setIsNoData(true);
    }
  }, [chartData, count]);

  const handleSelectedValue = (value: string) => {
    if (type === "author") {
      dispatch(updateAuthor([value]));
      dispatch(
        updateAllFilter({
          key: "author",
          value: value,
        })
      );
    } else if (type === "institution") {
      dispatch(updateInstitution([value]));
      dispatch(
        updateAllFilter({
          key: "institutions",
          value: value,
        })
      );
    } else if (type === "publisher") {
      dispatch(updatePublisher([value]));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value: value,
        })
      );
    } else if (type === "researcher") {
      dispatch(updateResearcher([value]));
      dispatch(
        updateAllFilter({
          key: "researcher",
          value: value,
        })
      );
    } else if (type === "speaker") {
      dispatch(updateSpeaker([value]));
      dispatch(
        updateAllFilter({
          key: "speaker",
          value: value,
        })
      );
    } else if (type === "research") {
      dispatch(updateResearch([value]));
      dispatch(
        updateAllFilter({
          key: "research",
          value: value,
        })
      );
    }

    authorReducer.isAuthorAnalytics
      ? dispatch(updateAuthorPage(1)) &&
        dispatch(updateAuthorRow(15)) &&
        dispatch(authorsearch())
      : dispatch(search("", null, true));

    dispatch(hideModal());
  };

  return (
    <>
      {isNoData ? (
        <Box
          sx={{
            height: "63vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            fontSize: "1.4rem",
          }}
        >
          No Data Available
        </Box>
      ) : (
        <Box
          sx={{
            height: "63vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            p: 1,
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3,
              overflowY: "auto",
            }}
          >
            <Grid container spacing={2} sx={{ width: "100%" }}>
              {horizontalBars &&
                horizontalBars?.map((data) => (
                  <>
                    <Grid xs={3} onClick={() => handleSelectedValue(data.val)}>
                      <LightTooltip title={data.val} placement="right">
                        <Text
                          sx={{
                            color: "#1F2A37",
                            textTransform: "capitalize",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          noWrap
                        >
                          {data.val}
                        </Text>
                      </LightTooltip>
                    </Grid>
                    <Grid xs={8} onClick={() => handleSelectedValue(data.val)}>
                      <LightTooltip title={data.val}>
                        <Box
                          sx={{
                            width: "100%",
                            background: "#E5E7EB",
                            height: "20px",
                            borderRadius: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              width: `${(data.count / horizontalMax) * 100}%`,
                              background: "#7852FB",
                              height: "20px",
                              borderRadius: "6px",
                              "&:hover": {
                                cursor: "pointer",
                                background: "#6740ec",
                              },
                            }}
                          />
                        </Box>
                      </LightTooltip>
                    </Grid>
                    <Grid xs={1}>
                      <Text sx={{ color: "#1F2A37" }}>{data.count}</Text>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Box>
          <Grid xs={2}>
            <Text
              sx={{
                position: "relative",
                writingMode: "vertical-rl",
                transform: "rotate(180deg)",
                color: "blue",
              }}
            >
              Document Count
            </Text>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AnalyticsHorizontalChart;
