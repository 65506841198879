import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAlertSettings,
  getAllManageResources,
  updateFavorites,
} from "../../../api/MyLibrary/TOCAlerts.api";
import {
  GetManageAlertsData,
  GetManageAlertsDoc,
} from "../../../types/GetManageAlerts.types";
import { SelectChangeEvent } from "@mui/material/Select";
import { getResourceCount } from "./AddTOC.hook";
import { queryClient } from "../../../App";
import { GetAlertSettingsData } from "../../../types/GetAlertSettings.types";
import { usagelogApi } from "../../../api/usageReportApi";

export const useManageTOC = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.user_id);

  const [search, setSearch] = useState("");
  const [enableSearch, setEnableSearch] = useState("");
  const [tocData, setTOCData] = useState<GetManageAlertsDoc[]>([]);
  const [currentResIDArr, setCurrentResIDArr] = useState<string[]>([]);
  const [hitCount, setHitCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(15);
  const [count, setCount] = useState({
    favCount: 0,
    alertCount: 0,
  });
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  type ResCount = { favorite_set_count: number; alert_count: number };
  const { data: resCount } = useQuery({
    queryKey: ["ResourceCount", userId],
    queryFn: () => getResourceCount(userId),
    select: ({ data }): ResCount => data.data[0][0],
  });

  const { data: allJournals } = useQuery({
    queryKey: ["GetAllLibResources", userId, page, pageSize, enableSearch],
    queryFn: () =>
      getAllManageResources(userId, page, pageSize, search, enableSearch),
    select: ({ data }): GetManageAlertsData => data.data,
    onSuccess: (data) => {
      let alertCount = 0;
      let favCount = 0;
      data.docs.forEach((item) => {
        if (item.alerttype_id) alertCount++; //Any ID > 0 == True
        if (item.is_favourite) favCount++;
      });

      setCount({
        favCount: favCount,
        alertCount: alertCount,
      });
      setCurrentResIDArr(data.docs?.map((item) => item.resourcemaster_id));
      // setTOCData(data.docs);
      setHitCount(Math.ceil(data.hits / pageSize));
      // setLoading(false);
    },
  });

  useQuery({
    queryKey: ["GetAlertSettings", userId, currentResIDArr],
    queryFn: () => getAlertSettings(userId, currentResIDArr),
    select: ({ data }): GetAlertSettingsData[] => data.data[0][0],
    enabled: currentResIDArr.length > 0,
    onSuccess: (data) => {
      let modifiedData = allJournals!.docs?.map((item, i) => ({
        ...item,
        alerttype_id: data[i].alert_type,
        is_favourite: data[i].favorite_set,
      }));
      // modifiedData = modifiedData?.filter(
      //   (item, i) => data[i].alert_type || data[i].favorite_set
      // );

      setCurrentResIDArr([]);
      setTOCData(modifiedData);
      setLoading(false);
    },
  });

  const updateFavoritesHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => updateFavorites(obj),
    onSuccess: () => {
      // refetch();
      queryClient.invalidateQueries({
        queryKey: ["GetAllLibResources"],
      });
      queryClient.invalidateQueries({
        queryKey: ["GetAlertSettings"],
      });
      queryClient.invalidateQueries({
        queryKey: ["ResourceCount"],
      });
    },
  });

  const handleFav = (curr: GetManageAlertsDoc, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      111,
      null,
      null,
      articleArticle_Id,
      articleResourcemaster_id,
      publisherId,
      articleResourceissue_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
    const data = {
      usermaster_id: userId,
      resource_ids: [Number(curr.resourcemaster_id)],
      is_favourite: !curr.is_favourite,
    };

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(data));
    updateFavoritesHandler.mutate({ formData });
  };

  const handleOnSearchChange = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => setSearch(currentTarget.value);

  const handleAlert = (curr: GetManageAlertsDoc) => {
    dispatch(
      setModal({
        modalType: "SET_ALERT",
        modalProps: {
          show: true,
          isTOC: true,
          alertData: {
            resource_id: curr.resourcemaster_id,
            resource_name: curr.resource_name,
            notification_set: curr.alerttype_id > 0,
            favorite_set: curr.is_favourite,
            dat_resourcetype_id: curr.resource_type,
          },
        },
      })
    );
  };

  const handleSearch = () => {
    console.log("Search");
    if (!search) {
      setEnableSearch("");
      return;
    }
    setPage(1);
    setEnableSearch(search);
  };

  const handleClear = () => setSearch("");

  const handlePerPage = (event: SelectChangeEvent) => {
    setPageSize(Number(event.target.value));
  };

  return {
    search,
    tocData,
    hitCount,
    pageSize,
    count,
    resCount,
    loading,
    setPage,
    handleOnSearchChange,
    handleFav,
    handleAlert,
    handleSearch,
    handleClear,
    handlePerPage,
  };
};
