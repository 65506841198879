import { useAppSelector } from "../../store/hooks";
import AddAVThesesAlertModal from "./MyLibrary/AddAVThesesAlertModal";
import AddPrePrintAlertModal from "./MyLibrary/AddPrePrint.modal";
import FilterSubjectModal from "./MyLibrary/FilterSubject.modal";
import ShareFolderModal from "./MyLibrary/ShareFolder.modal";
import UnshareFolderModal from "./MyLibrary/Unshare.modal";
import DeleteFolderModal from "./MyLibrary/DeleteFolder.modal";
import RenameFolderModal from "./MyLibrary/RenameFolder.modal";
import AddFolderModal from "./MyLibrary/AddFolder.modal";
import AddLinkModal from "./MyLibrary/AddLink.modal";
import AnalyticsModal from "./Analytics.modal";
import SetAlertModal from "./MyLibrary/SetAlert.modal";
import PreprintDeleteModal from "./MyLibrary/DeletePreprint.modal";
import RSSSuccess from "./MyLibrary/AlertSuccess.modal";
import PreferenceModal from "./Preference.modal";
import RemoveArticle from "./MyLibrary/RemoveFolder.modal";
import LoginModal from "./login.modal";
import SelectUserModal from "./SelectUser.modal";
import SessionTimeoutModal from "./SessionTimeOut.modal";
import CaptchaModal from "./CaptchaModal";
import FolderArticleDelete from "./MyLibrary/DeleteFolderArticle.modal";

export const modalBaseStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  color: "#000",
  borderRadius: "0.2rem",
  padding: "1rem",
} as const;

const MODAL_COMPONENTS: any = {
  // MY LIBRARY ==============>
  ADD_AV_THESIS: AddAVThesesAlertModal,
  ADD_PRE_PRINT: AddPrePrintAlertModal,
  FILTER_SUBJECT: FilterSubjectModal,
  SHARE_FOLDER: ShareFolderModal,
  UNSHARE_FOLDER: UnshareFolderModal,
  DELETE_FOLDER: DeleteFolderModal,
  RENAME_FOLDER: RenameFolderModal,
  ADD_FOLDER: AddFolderModal,
  ADD_LINK: AddLinkModal,
  SET_ALERT: SetAlertModal,
  ALERT_SUCCESS: RSSSuccess,
  DELETE_PREPRINT: PreprintDeleteModal,
  DELETE_FOLDER_ARTICLE: FolderArticleDelete,
  // BASIC SEARCH ==============>
  ANALYTICS: AnalyticsModal,
  PREFERENCE: PreferenceModal,
  REMOVE_ARTICLE: RemoveArticle,
  SHOW_LOGIN: LoginModal,
  USER_SELECT: SelectUserModal,
  SESSION_TIMEOUT_MODAL: SessionTimeoutModal,
  CAPTCHA: CaptchaModal,
};

const RootModal = () => {
  const { modalType, modalProps } = useAppSelector((state) => state.modal);
  const CurrentModal = MODAL_COMPONENTS[modalType];

  if (CurrentModal) {
    return <CurrentModal {...modalProps} />;
  } else {
    return null;
  }
};

export default RootModal;
