import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Typography,
  Button,
  Divider,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Colors } from "../../utils/constants";
// import FilterAuthor from "../../components/filterPopups/ModalTwoOfBrowseJournal";
import FilterSubjectOfBrowser from "../../components/filterPopups/ModalTwoOfBrowserPublisher";
import FilterL2SubjectOfBrowser from "../../components/filterPopups/ModuleTwoOfPublisherSubjects";

import FilterRanking from "../../components/filterPopups/ModalOneOfBrowserPublisher";
import FilterAuthor from "../../components/filterPopups/ModalOneOfBrowserPublisher";

import FilterCountry from "../../components/filterPopups/ModalTwoOfBrowserPublisher";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { goToTop } from "../../utils/helper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateSourceType,
  updateSubject,
  // updateResearch,
  // updateJournal,
  // updateSourceIndex,
  updateCountryOfPublication,
  // updatePublisher,
  updateJournalRank,
  // updateCountryOfPublishingAuthor,
  clearAllFilters,
  updateOpenAccess,
  // updateRetractedArticle,
  // updateDateset,
  updateCurrentUpdate,
  updateBrowserPage as updateBrowserPagePublisher,
} from "../../store/slice/browserFilterSlicePublisher";
import { ReducerObj } from "../../types/interface";
import { useAppSelector } from "../../store/hooks";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { usagelogApi } from "../../api/usageReportApi";

// Types

// type YearType = { val: string; count: string };

// interface PropType {
//   yearFromData?: YearType[];
//   jsonFacets?: any;
// }

const SearchFilterComponentOfPublisher = (props: any) => {
  // Props
  const { yearFromData, jsonFacets, setFilters, setIsPublisher } = props;

  const dispatch = useDispatch();
  // const searchTerm = getParamsByName("searchterm");
  const selectedFilters: any = useSelector(
    (state: any) => state.browserFilterSlicePublisher
  );

  // const searchReducer: any = useSelector((state: ReducerObj) => state);

  const searchResult = useSelector(
    (state: any) => state.browserPublisher?.browserResult?.facets?.facet_fields
  );

  const subject_l2_publisher_searchResult = useSelector(
    (state: any) =>
      state.browserPublisher?.browserResult?.jsonfacets?.subjects_name_l2
  );

  const [sourceTypeModalStatus, setSourceTypeModalStatus] = useState(false);
  const [filterSubjectModalStatus, setFilterSubjectModalStatus] =
    useState(false);
  // const [filterYearModalStatus, setFilterYearModalStatus] = useState(false);
  // const [filterCategoryModalStatus, setFilterCategoryModalStatus] =
  //   useState(false);
  const [filterAuthorModalStatus, setFilterAuthorModalStatus] = useState(false);
  const [filterSpeakerModalStatus, setFilterSpeakerModalStatus] =
    useState(false);
  const [filterResearchModalStatus, setFilterResearchModalStatus] =
    useState(false);
  const [filterJournalModalStatus, setFilterJournalModalStatus] =
    useState(false);
  const [filterRankingModalStatus, setFilterRankingModalStatus] =
    useState(false);
  // const [filterSourceModalStatus, setFilterSourceModalStatus] = useState(false);
  const [filterCountryModalStatus, setFilterCountryModalStatus] =
    useState(false);
  const [filterPublisherModalStatus, setFilterPublisherModalStatus] =
    useState(false);
  const [filterUnivModalStatus, setFilterUnivModalStatus] = useState(false);
  const [triggerCount, setTriggerCount] = useState<number>(0);
  const [copaModalStatus, setCopaModalStatus] = useState(false);
  const [filterL2SubjectModalStatus, setFilterL2SubjectModalStatus] =
    useState(false);

  const profileData = useAppSelector((state) => state.login);
  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const selectedPublisherFilters: any = useSelector(
    (state: any) => state.browserFilterSlicePublisher
  );

  // Applied filter values
  // const sourceIndex = useSelector(
  //   (state: ReducerObj) => state.filterReducer.sourceIndex
  // );
  // const openAccess = useAppSelector((state) => state.filterReducer.openAccess);

  const openAccess = useAppSelector(
    (state) => state.browserFilterSlicePublisher.currentUpdate
  );
  // console.log("independentfilter", openAccess);

  // const retractedArticle = useAppSelector(
  //   (state) => state.filterReducer.retractedArticle
  // );

  // const datasetAvailability = useAppSelector(
  //   (state) => state.filterReducer.datesetAvailability
  // );

  const contextId =
    selectedPublisherFilters.collectionsValuePublisher === "J-GateCollection"
      ? 1
      : selectedPublisherFilters.collectionsValuePublisher ===
        "Consortia Collections"
      ? 2
      : selectedPublisherFilters.collectionsValuePublisher ===
        "My Library Collections"
      ? 3
      : selectedPublisherFilters.collectionsValuePublisher ===
        "MyPersonalLibraryCollection"
      ? 4
      : selectedPublisherFilters.collectionsValuePublisher ===
        "ONOS Collections"
      ? 5
      : selectedPublisherFilters.collectionsValuePublisher === "Library OPAC"
      ? 6
      : selectedPublisherFilters.collectionsValuePublisher ===
        "Open Access Collections"
      ? 7
      : null;

  const logJournalFinderUsageData = (usageActionID: any) => {
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  useEffect(() => {
    if (triggerCount) applySearch();
  }, [triggerCount]);

  // Handle source type modal status changes
  // const handleChangeSouceType = () => {
  //   setSourceTypeModalStatus(false);
  // };

  // Handle Filter subject modal status changes
  const handleChangeFilterSubject = () => {
    setFilterSubjectModalStatus(false);
  };

  // // Handle Filter year modal status changes
  // const handleChangeFilterYear = () => {
  //   setFilterYearModalStatus(false);
  // };

  // // Handle Filter category modal status changes
  // const handleChangeFilterCategory = () => {
  //   setFilterCategoryModalStatus(false);
  // };

  // Handle Filter Author modal status changes
  const handleChangeFilterAuthor = () => {
    setFilterAuthorModalStatus(false);
  };

  // // Handle Filter speaker modal status changes
  // const handleChangeFilterSpeaker = () => {
  //   setFilterSpeakerModalStatus(false);
  // };

  // // Handle Filter Research modal status changes
  // const handleChangeFilterResearch = () => {
  //   setFilterResearchModalStatus(false);
  // };

  // // Handle Filter journal modal status changes
  // const handleChangeFilterJournal = () => {
  //   setFilterJournalModalStatus(false);
  // };

  // Handle Filter ranking modal status changes
  const handleChangeFilterRanking = () => {
    setFilterRankingModalStatus(false);
  };

  // // Handle Filter source modal status changes
  // const handleChangeFilterSource = () => {
  //   setFilterSourceModalStatus(false);
  // };

  // Handle Filter country modal status changes
  const handleChangeFilterCountry = () => {
    setFilterCountryModalStatus(false);
  };

  // // Handle Filter publisher modal status changes
  // const handleChangeFilterPublisher = () => {
  //   setFilterPublisherModalStatus(false);
  // };

  // // Handle Filter univ modal status changes
  // const handleChangeFilterUniv = () => {
  //   setFilterUnivModalStatus(false);
  // };

  // // Get filterd values
  // const getSouceTypeValues = (data: string[]) => {
  //   dispatch(updateSourceType(data));
  // };

  // // Get category values
  // const getCategoryTypeValues = (data: string[]) => {};

  // // Get subject values
  // const getSubjectTypeValues = (data: string[]) => {
  //   dispatch(updateSubject(data));
  // };

  // Get Author values
  const getAuthorTypeValues = (data: string[]) => {
    dispatch(updateSourceType(data));
    logJournalFinderUsageData(82);
  };
  // Get speaker values
  const getSpeakerTypeValues = (data: any) => {
    dispatch(updateSubject(data));
    logJournalFinderUsageData(81);
  };

  // // Get research values
  // const getResearchTypeValues = (data: string[]) => {
  //   dispatch(updateResearch(data));
  // };

  // // Get journal values
  // const getJournalTypeValues = (data: string[]) => {
  //   dispatch(updateJournal(data));
  // };

  // Get ranking values
  const getRankingTypeValues = (data: string[]) => {
    dispatch(updateJournalRank(data));
    logJournalFinderUsageData(80);
  };

  // Get cop values
  const getCopTypeValues = (data: string[]) => {
    dispatch(updateCountryOfPublication(data));
    logJournalFinderUsageData(79);
  };

  // // Get cop values
  // const getCopaTypeValues = (data: string[]) => {
  //   dispatch(updateCountryOfPublishingAuthor(data));
  // };

  // // Get publisher values
  // const getPublisherTypeValues = (data: string[]) => {
  //   dispatch(updatePublisher(data));
  // };

  // function apply filter
  async function applyFilter() {
    // if (!searchTerm) return;
    setTriggerCount((prev) => prev + 1);

    goToTop();
  }

  async function applySearch() {
    // dispatch(updateLoadingState(true));
    // generatedFilterString();
    let returnData = generatedFilterString();

    setFilters(returnData);
    // let data = await search(searchReducer, selectedFilters, dispatch);
    // dispatch(updateSearchResults(data));
    // dispatch(updateLoadingState(false));
  }

  // clear all filters
  function handleClearAllFilters() {
    dispatch(updateCurrentUpdate(false));
    dispatch(clearAllFilters());
    setTriggerCount((prev) => prev + 1);
  }

  // // Handle independent filter source index
  // function handleSourceIndex() {
  //   dispatch(updateSourceIndex(!sourceIndex));
  //   setTriggerCount((prev) => prev + 1);
  // }

  function handleOpenAccess() {
    dispatch(updateCurrentUpdate(!openAccess));
    setTriggerCount((prev) => prev + 1);
    logJournalFinderUsageData(83);
  }

  // function handleRetractedArticles() {
  //   dispatch(updateRetractedArticle(!retractedArticle));
  //   setTriggerCount((prev) => prev + 1);
  // }

  // function handleDateSet() {
  //   dispatch(updateDateset(!datasetAvailability));
  //   setTriggerCount((prev) => prev + 1);
  // }
  const updateFilters: any = useSelector(
    (state: any) => state.browserFilterSlicePublisher
  );

  const l2Subjects = updateFilters?.subject.filter(
    (val: any) => val.isSelected
  );

  const selected_l2_subjects = l2Subjects?.map((val: any) => val.val);

  const l3Subjects: any = selectedFilters?.subject
    ?.filter((subject: any) => !subject.isSelected)
    .flatMap((subject: any) => subject.subjects_name_l3.buckets)
    .filter((bucket: any) => bucket.isChecked);

  const selected_l3_subjects = l3Subjects?.map((val: any) => val.val);

  function generatedFilterString() {
    const ranking = updateFilters?.journalRank?.length
      ? "&" +
        objectToQueryString({
          filter_metrix:
            '("' + updateFilters?.journalRank.join('" OR "') + '")',
        })
      : "";
    const publisherCountry = updateFilters?.countryOfPublication?.length
      ? "&" +
        objectToQueryString({
          primary_publisher_country:
            '("' + updateFilters?.countryOfPublication?.join('" OR "') + '")',
        })
      : "";

    const source_index = updateFilters?.sourceType?.length
      ? "&" +
        objectToQueryString({
          fq__resource_source_index:
            '("' + updateFilters?.sourceType?.join('" OR "') + '")',
        })
      : "";

    // const subjects = updateFilters?.subject?.length
    //   ? "&" +
    //     objectToQueryString({
    //       subjects_name_l3: encodeURIComponent(
    //         '("' + updateFilters?.subject?.join('" OR "') + '")'
    //       ),
    //     })
    //   : "";

    const subjects =
      selected_l2_subjects.length > 0 && selected_l3_subjects.length > 0
        ? "&" +
          objectToQueryString({
            "fq__(subjects_name_l2": encodeURIComponent(
              '("' +
                selected_l2_subjects.map((val: any) => val).join('" OR "') +
                '")'
            ),
          }) +
          " OR " +
          objectToQueryString({
            subjects_name_l3: encodeURIComponent(
              '("' +
                selected_l3_subjects.map((val: any) => val).join('" OR "') +
                '")' +
                ")"
            ),
          })
        : selected_l2_subjects.length > 0
        ? "&" +
          objectToQueryString({
            fq__subjects_name_l2: encodeURIComponent(
              '("' +
                selected_l2_subjects.map((val: any) => val).join('" OR "') +
                '")'
            ),
          })
        : selected_l3_subjects.length > 0
        ? "&" +
          objectToQueryString({
            fq__subjects_name_l3: encodeURIComponent(
              '("' +
                selected_l3_subjects.map((val: any) => val).join('" OR "') +
                '")'
            ),
          })
        : "";
    // const encodeSubject = encodeURIComponent(subjects);
    // console.log("encodedSubject", encodeSubject);
    return publisherCountry + source_index + subjects + ranking;
  }

  const handleChangeFilterL2Subject = () => {
    setFilterL2SubjectModalStatus(false);
  };

  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={[webStyle.flexBox]}>
          <Typography
            sx={{
              alignSelf: "center",
              fontFamily: "Lora",
              fontWeight: 700,
              fontSize: "1.125rem",
            }}
          >
            Filters
          </Typography>
          <Button onClick={handleClearAllFilters}>Clear All</Button>
        </Box>
        <span
          style={{
            color: "gray",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <InfoOutlinedIcon sx={{ fill: "#1976d2", fontSize: "18px" }} />
          Count shows as per journal.
        </span>

        {Array.isArray(jsonFacets?.data_type?.buckets) &&
        jsonFacets?.data_type?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Document Type
              </Typography>
              <Button onClick={() => setSourceTypeModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {/* <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
          <Typography sx={{ fontSize: "0.875rem", alignSelf: "center" }}>
            Category
          </Typography>
          <Button onClick={() => setFilterCategoryModalStatus(true)}>
            <ControlPointIcon></ControlPointIcon>
          </Button>
        </Box>
        <Box>
          {categoryValue.length
            ? categoryValue?.map((data) => (
                <Stack direction={"row"} alignItems="center">
                  <Button
                    onClick={() =>
                      removeFilter(data, categoryValue, setCategoryValue)
                    }
                  >
                    <CloseIcon sx={{ color: "#6B7280" }} />
                  </Button>
                  <Typography>{data}</Typography>
                </Stack>
              ))
            : null}
        </Box>
        <Divider /> */}

        {/* {searchResult?.subjects_name_l3.length !== 0 ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Subject
              </Typography>
              <Button onClick={() => setFilterSubjectModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null} */}

        {subject_l2_publisher_searchResult?.buckets?.length !== 0 ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Subject
              </Typography>
              <Button onClick={() => setFilterL2SubjectModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.authors_tk?.buckets) &&
        jsonFacets?.authors_tk?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Author
              </Typography>
              <Button onClick={() => setFilterAuthorModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.speakers?.buckets) &&
        jsonFacets?.speakers?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Speaker
              </Typography>
              <Button onClick={() => setFilterSpeakerModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.guide_name_tk?.buckets) &&
        jsonFacets?.guide_name_tk?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                {/* Guides (Ph.D Theses) */}
                Research Guides
              </Typography>
              <Button onClick={() => setFilterResearchModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.journal_name?.buckets) &&
        jsonFacets?.journal_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Journal
              </Typography>
              <Button onClick={() => setFilterJournalModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {searchResult?.filter_metrix?.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Ranking
              </Typography>
              <Button onClick={() => setFilterRankingModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {/* <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
          <Typography sx={{ fontSize: "0.875rem", alignSelf: "center" }}>
            Year(3345)
          </Typography>
          <Button onClick={() => setFilterYearModalStatus(true)}>
            <ControlPointIcon></ControlPointIcon>
          </Button>
        </Box>
        <Box>
          {year.length
            ? year?.map((data) => (
                <Stack direction={"row"} alignItems="center">
                  <Button onClick={() => removeFilter(data, year, setYear)}>
                    <CloseIcon sx={{ color: "#6B7280" }} />
                  </Button>
                  <Typography>{data}</Typography>
                </Stack>
              ))
            : null}
        </Box>
        <Divider /> */}

        {searchResult?.resource_source_index.length !== 0 ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Source Index
              </Typography>
              <Button onClick={() => setFilterAuthorModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : (
          ""
        )}

        {searchResult?.primary_publisher_country.length !== 0 ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                {/* Country of Publication */}
                Publication Country
              </Typography>
              <Button onClick={() => setFilterCountryModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.author_country_name?.buckets) &&
        jsonFacets?.author_country_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                {/* Country of Publishing Author */}
                Author Country
              </Typography>
              <Button onClick={() => setCopaModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : (
          ""
        )}

        {Array.isArray(jsonFacets?.publisher_name?.buckets) &&
        jsonFacets?.publisher_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Publishers
              </Typography>
              <Button onClick={() => setFilterPublisherModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(jsonFacets?.author_address?.buckets) &&
        jsonFacets?.author_address?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Helvetica Neue"}
              >
                Institution
              </Typography>
              <Button onClick={() => setFilterUnivModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        <Box>
          <Typography
            mt={2}
            mb={2}
            sx={{
              fontSize: "0.875rem",
              alignSelf: "center",
              fontFamily: "Helvetica Neue",
              fontWeight: "600",
            }}
          >
            Independent Filters (1)
          </Typography>
          <Divider />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={openAccess} />}
              label="By current Update"
              onChange={handleOpenAccess}
              componentsProps={{
                typography: {
                  fontFamily: "Helvetica Neue",
                  fontWeight: "500",
                  fontSize: "14px",
                },
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox />}
              label="Web of Science"
              onChange={handleRetractedArticles}
              sx={{ fontFamily: "Helvetica Neue", fontWeight: "500" }}
              componentsProps={{
                typography: {
                  fontFamily: "Helvetica Neue",
                  fontWeight: "500",
                  fontSize: "14px",
                },
              }}
            /> */}
            {/* <FormControlLabel
              control={<Checkbox />}
              label="DOAJ"
              onChange={handleDateSet}
              sx={{ fontFamily: "Helvetica Neue", fontWeight: "500" }}
              componentsProps={{
                typography: {
                  fontFamily: "Helvetica Neue",
                  fontWeight: "500",
                  fontSize: "14px",
                },
              }}
            /> */}
          </FormGroup>
        </Box>
      </Box>

      {/* Filter popups */}
      {/* <SourceType
        isOpen={sourceTypeModalStatus}
        handleClose={handleChangeSouceType}
        modalName="Filter Source Type"
        getFilterdValues={getSouceTypeValues}
        filterValues={jsonFacets?.data_type?.buckets}
        applyFilter={applyFilter}
        appliedFilterValues={selectedFilters?.sourceType}
      /> */}

      {/* <FilterCategory
        isOpen={filterCategoryModalStatus}
        handleClose={handleChangeFilterCategory}
        modalName="Filter Category"
        getFilterdValues={getCategoryTypeValues}
        filterValues={jsonFacets?.openaccess_type?.buckets}
      /> */}
      {/* <FilterCountryOfPublication
        isOpen={filterSubjectModalStatus}
        handleClose={handleChangeFilterSubject}
        modalName="Filter Subject"
        getFilterdValues={getSubjectTypeValues}
        filterData={jsonFacets?.subjects_name_l3?.buckets}
        applyFilter={applyFilter}
        placeholderName="subject"
        appliedFilterValues={selectedFilters?.subject}
        facetKey="subjects_name_l3"
      /> */}
      {/* <FilterSubject
        isOpen={filterSubjectModalStatus}
        handleClose={handleChangeFilterSubject}
        modalName="Filter Subject"
        getFilterdValues={getSubjectTypeValues}
        filterData={jsonFacets?.subjects_name_l3?.buckets}
        applyFilter={applyFilter}
        placeholderName="subject"
        appliedFilterValues={selectedFilters?.subject}
        facetKey="subjects_name_l3"
      /> */}
      {/* <FilterYear
        isOpen={filterYearModalStatus}
        handleClose={handleChangeFilterYear}
        modalName="Filter Year`"
        getFilterdValues={getYearTypeValues}
        yearData={yearFromData}
      /> */}

      <FilterSubjectOfBrowser
        setFilters={setFilters}
        isOpen={filterSubjectModalStatus}
        handleClose={handleChangeFilterSubject}
        modalName="Filter Subject"
        getFilterdValues={getSpeakerTypeValues}
        filterData={searchResult?.subjects_name_l3}
        applyFilter={applyFilter}
        placeholderName="Subject name"
        appliedFilterValues={selectedFilters?.subject}
        facetKey="subjects_name_l3"
      />

      <FilterL2SubjectOfBrowser
        setFilters={setFilters}
        isOpen={filterL2SubjectModalStatus}
        handleClose={handleChangeFilterL2Subject}
        modalName="Filter Subject"
        getFilterdValues={getSpeakerTypeValues}
        // filterData={searchResult?.subjects_name_l3}
        filterData={subject_l2_publisher_searchResult?.buckets}
        applyFilter={applyFilter}
        placeholderName="Subject name"
        appliedFilterValues={selectedFilters?.subject}
        facetKey="subjects_name_l3"
        setIsPublisher={setIsPublisher}
      />

      <FilterCountry
        setFilters={setFilters}
        isOpen={filterCountryModalStatus}
        handleClose={handleChangeFilterCountry}
        modalName="Filter Publication Country"
        getFilterdValues={getCopTypeValues}
        filterData={searchResult?.primary_publisher_country}
        applyFilter={applyFilter}
        placeholderName="Publication Country name"
        appliedFilterValues={selectedFilters?.countryOfPublication}
        facetKey="primary_publisher_country"
      />

      <FilterRanking
        setFilters={setFilters}
        isOpen={filterRankingModalStatus}
        handleClose={handleChangeFilterRanking}
        modalName="Filter Journal Ranking"
        getFilterdValues={getRankingTypeValues}
        filterValues={searchResult?.filter_metrix}
        applyFilter={applyFilter}
        appliedFilterValues={selectedFilters?.journalRank}
      />

      <FilterAuthor
        setFilters={setFilters}
        isOpen={filterAuthorModalStatus}
        handleClose={handleChangeFilterAuthor}
        modalName="Filter Source Index"
        getFilterdValues={getAuthorTypeValues}
        filterValues={searchResult?.resource_source_index}
        applyFilter={applyFilter}
        // placeholderName="resource index"
        appliedFilterValues={selectedFilters?.sourceType}
        // facetKey="resource_source_index"
      />
    </>
  );
};

export default SearchFilterComponentOfPublisher;

const webStyle = {
  // flexBox: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  // },
  // leftBox: {
  //   width: "92%",
  //   maxWidth: "340px",
  //   backgroundColor: Colors.white,
  //   borderWidth: 1,
  //   borderColor: Colors.gray_1000,
  //   borderRadius: 2,
  //   marginTop: "3%",
  //   padding: "7%",
  //   boxShadow: 1,
  // },
  // titleHeader: {
  //   fontFamily: "Helvetica Neue",
  //   fontWeight: 700,
  //   textAlign: "left",
  //   fontSize: "1.125rem",
  // },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "3%",
    padding: "7%",
    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
