import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Colors } from "../../utils/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSort,
  updateSortName,
} from "../../store/slice/browseFilterSlice";
import Tooltip from "@mui/material/Tooltip";
import { usagelogApi, usageReport } from "../../api/usageReportApi";
import { useAppSelector } from "../../store/hooks";
import { valueExistsBrowse } from "../../utils/helper";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const SortByRelevenceForBrowser = ({
  selectedRankSort,

  packageDetails,
  sortData,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [selectedFilters.sortName, setSelectedSort] = React.useState("Journal");
  const [triggerEffect, setTriggerEffect] = React.useState(0);
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );

  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const profileData = useSelector((state: any) => state?.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);

  const contextId =
    selectedFilters.collectionsValueJournal === "J-GateCollection"
      ? 1
      : selectedFilters.collectionsValueJournal === "Consortia Collections"
      ? 2
      : selectedFilters.collectionsValueJournal === "My Library Collections"
      ? 3
      : selectedFilters.collectionsValueJournal ===
        "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionsValueJournal === "ONOSCollections"
      ? 5
      : selectedFilters.collectionsValueJournal === "Library OPAC"
      ? 6
      : selectedFilters.collectionsValueJournal === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const PackagePublisherId = details?.publisher_id
      ? details?.publisher_id[0]
      : null;
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourcemaster_id,
      PackagePublisherId,
      articleResourceissue_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
  };
  const checkValue: any = useSelector(
    (state: any) => state.browseFilterSlice.sort
  );
  // console.log("dta", checkValue);

  React.useEffect(() => {
    if (triggerEffect) onSearch();
  }, [triggerEffect]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleSort(val: string) {
    setTriggerEffect((prev) => prev + 1);
    if (val === "") {
      logBasicSearchUsageData(85, packageDetails);
      dispatch(updateSort(""));
      // setSelectedSort("Journal");
      dispatch(updateSortName("Journal"));
    } else if (val === "Best Match") {
      // dispatch(updateSort("score desc"));
      // setSelectedSort("Best Match");
      dispatch(updateSortName("Best Match"));
    } else if (val === "sjr_score desc") {
      logBasicSearchUsageData(84, packageDetails);
      // setSelectedSort("SJR");
      dispatch(updateSort("sjr_score desc"));
      dispatch(updateSortName("SJR"));
    } else if (val === "sjr_hindex desc") {
      logBasicSearchUsageData(84, packageDetails);
      // setSelectedSort("H-Index");
      dispatch(updateSortName("H-Index"));
      dispatch(updateSort("sjr_hindex desc"));
    } else if (val === "naas_value desc") {
      logBasicSearchUsageData(84, packageDetails);
      // setSelectedSort("NAAS");
      dispatch(updateSortName("NAAS"));
      dispatch(updateSort("naas_value desc"));
    }

    // setSort(val);
    // let res = await getBrowserDetailsOnSort(val, getKeywords);
    // dispatch(browseJournalSearchResult(res));
    // let resourceIdList: string[] = [];
    // if (res?.docs) {
    //   res?.docs?.map((element: any) => {
    //     resourceIdList.push(element.resourcemaster_id);
    //   });
    // }
    // let response = await resourceSettingAlert(resourceIdList);
    // console.log("response", response);
    // dispatch(alertsAndFav(response));
    handleClose();
    // console.log("sortValue", getKeywords.setAlphabet);
  }

  async function onSearch() {
    // Make api req
    // dispatch(updateLoadingState(true));
    // let data = await search(searchReducer, selectedFilters, dispatch);
    // // Dispatch to redux
    // dispatch(updateSearchResults(data));
    // dispatch(updateLoadingState(false));
  }

  return (
    <div>
      <Button
        style={{
          textAlign: "left",
          color: Colors.black,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Button
          sx={{
            // fontSize: "0.8rem",
            color: Colors.black,
            // minHeight: 0,
            // padding: "5px 5px",

            fontFamily: "Helvetica Neue",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
          }}
        >
          Sort By
          <span style={{ fontSize: "0.8rem", paddingLeft: "6px" }}>
            {selectedFilters.sortName}
          </span>
        </Button>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            color: selectedFilters.sortName === "Journal" ? Colors.primary : "",
            fontSize: 14,
          }}
          onClick={() => handleSort("")}
          disableRipple
        >
          Journal
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {sortData?.length >= 1 ? (
          <div>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              sx={{
                color:
                  selectedFilters.sortName === "Ranking" ? Colors.primary : "",
                fontSize: 14,
                cursor: "default",
              }}
              //onClick={() => handleSort("Ranking")}
              disableRipple
            >
              Ranking
            </MenuItem>
          </div>
        ) : null}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          // value={publicationValue}
          value={selectedFilters.sortName}
          // onChange={handleChange}
          sx={{ padding: 1 }}
        >
          {valueExistsBrowse("SJR", sortData) && (
            <Tooltip title="Scimago Journal Ranking">
              <FormControlLabel
                checked={checkValue === "sjr_score desc" ? true : false}
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: 14 },
                }}
                value="sjr_rank"
                control={<Radio />}
                label="SJR"
                onChange={() => handleSort("sjr_score desc")}
              />
            </Tooltip>
          )}
          {valueExistsBrowse("H-Index", sortData) && (
            <Tooltip title="Hirsch index">
              <FormControlLabel
                checked={checkValue === "sjr_hindex desc" ? true : false}
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: 14 },
                }}
                value="sjr_hindex desc"
                control={<Radio />}
                label="H-Index"
                onChange={() => handleSort("sjr_hindex desc")}
              />
            </Tooltip>
          )}
          {consortiamaster_id === 1874 &&
            valueExistsBrowse("NAAS", sortData) && (
              <Tooltip title="National Academy of Agricultural Sciences">
                <FormControlLabel
                  checked={checkValue === "naas_value desc" ? true : false}
                  sx={{
                    "& .MuiFormControlLabel-label": { fontSize: 14 },
                  }}
                  value="naas_value desc"
                  control={<Radio />}
                  label="NAAS"
                  onChange={() => handleSort("naas_value desc")}
                />
              </Tooltip>
            )}
        </RadioGroup>
      </StyledMenu>
    </div>
  );
};

export default SortByRelevenceForBrowser;
