import { Grid, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthorPublicationDate from "../../components/leftBarComponent/authorPublicationComponent";
import AuthorSearchFilter from "../../components/leftBarComponent/authorSearchFilter";
// import CollectionsComponent from "../../components/leftBarComponent/collections";
import AuthorCollectionsComponent from "../../components/leftBarComponent/collectionsOfAuthor";
import PublicationDate from "../../components/leftBarComponent/publicationComponent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearLimits,
  clearPublicationFilter,
  updataLibraryOpac,
  updateCollectionValue,
  updateConsortiaCollection,
  updateJgateCollection,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
} from "../../store/slice/filterSlice";
// import SearchFilter from "../../components/leftBarComponent/searchFilter";
import dayjs from "dayjs";
import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";
import { updateAuthorFullText } from "../../store/slice/authorFinderSlice";
import customerDetails from "../../store/slice/customerDetails";

const AuthorLeftbarFilters = ({
  searchResults,
  resultCount,
  allCount,
}: any) => {
  const dispatch = useAppDispatch();
  const selectedCollection = useAppSelector(
    (state) => state.filterReducer.collectionValue
  );
  const customerDetails = useAppSelector(
    (state: any) => state.customer.customerDetails
  );
  const [publicationValue, setPublicationValue] = useState("");
  // const [collectionsValue, setcollectionsValue] = useState("");
  const [collectionsValue, setcollectionsValue] = useState(selectedCollection);

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState<string>("");

  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);
  const filterReducer = useAppSelector((state) => state.filterReducer);

  const DateObj = {
    currentYear: dayjs().year(),
    month: dayjs().month() + 1,
    date: dayjs().date(),
  };

  useEffect(() => {
    clearCollection();
  }, [selectedCollection]);

  useEffect(() => {
    updateCollectionValues();
  }, [
    filterReducer.jgateCollection,
    filterReducer.consortiaCollection,
    filterReducer.myLibraryCollection,
    filterReducer.onosCollection,
    filterReducer.libraryOpac,
    filterReducer.myPersonalLibCollection,
  ]);

  useEffect(() => {
    updatePublication();
  }, [
    filterReducer.earlyOnline,
    filterReducer.dateFrom,
    filterReducer.monthFrom,
    filterReducer.yearFrom,
  ]);

  function clearAllLimits() {
    dispatch(clearLimits());
    dispatch(clearPublicationFilter());
    setPublicationValue("");
    dispatch(search());
    setFromValue("");
    setToValue("");
    dispatch(updateAuthorFullText(true));
    if (
      customerDetails?.product_type === "1" ||
      customerDetails?.product_type === "1,7"
    ) {
      setcollectionsValue("J-GateCollection");
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(true));
    } else if (customerDetails?.product_type === "7") {
      // setcollectionsValue("ConsortiaSubscriptions");
      // dispatch(updateMyLibraryCollection(false));
      // dispatch(updateJgateCollection(false));
      // dispatch(updateConsortiaCollection(true));
      // dispatch(updateOnosCollection(false));
      // dispatch(updatePersonalLibrary(false));
      // dispatch(updataLibraryOpac(false));

      setcollectionsValue("J-GateCollection");
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(true));
    }
  }

  function clearCollection() {
    if (!selectedCollection) setcollectionsValue("");
  }

  function updateCollectionValues() {
    setcollectionsValue("");
    if (filterReducer.jgateCollection) {
      setcollectionsValue("J-GateCollection");
      dispatch(updateCollectionValue("J-GateCollection"));
    }
    if (filterReducer.consortiaCollection) {
      setcollectionsValue("ConsortiaSubscriptions");
      dispatch(updateCollectionValue("ConsortiaSubscriptions"));
    }
    if (filterReducer.myLibraryCollection) {
      setcollectionsValue("MyLibraryCollection");
    }
    if (filterReducer.onosCollection) {
      setcollectionsValue("ONOSCollections");
    }
    if (filterReducer.libraryOpac) {
      setcollectionsValue("Library OPAC");
    }
    if (filterReducer.myPersonalLibCollection) {
      setcollectionsValue("MyPersonalLibraryCollection");
    }
  }

  function updatePublication() {
    let condition = "TO";
    let currentYear = `[${DateObj.currentYear} ${condition} ${DateObj.currentYear}]`;
    setPublicationValue("");
    if (filterReducer.earlyOnline) {
      setPublicationValue("EarlyOnline");
    }
    if (filterReducer.dateFrom) {
      setPublicationValue("LastOneWeek");
    }
    if (filterReducer.monthFrom) {
      setPublicationValue("LastOneMonth");
    }
    if (filterReducer.yearFrom.toString() === currentYear.toString()) {
      setPublicationValue("CurrentYear");
    }
    if (!filterReducer.yearFrom) {
      setFromValue("");
      setToValue("");
    }
  }

  return (
    <Grid item xs={2.5}>
      {/* LEFT COMPONENT */}
      <Box sx={{ paddingBlock: 2 }}>
        <Typography fontWeight={"700"} fontSize={"20px"} lineHeight={"24px"}>
          Results{" "}
          {/* {resultCount
            ? resultCount?.toLocaleString("en-IN")
            : searchResults?.hits?.toLocaleString("en-IN")} */}
          {searchResults?.hits?.toLocaleString("en-IN")}
        </Typography>
      </Box>

      {/* <PublicationDate /> */}
      <AuthorPublicationDate
        publicationValue={publicationValue}
        setPublicationValue={setPublicationValue}
        clearAllLimits={clearAllLimits}
        isOpac={isOpac}
        fromValue={fromValue}
        setFromValue={setFromValue}
        toValue={toValue}
        setToValue={setToValue}
      />

      <AuthorCollectionsComponent
        jsonFacets={searchResults?.jsonfacets}
        data={searchResults}
        collectionsValue={collectionsValue}
        setcollectionsValue={setcollectionsValue}
        allCount={allCount}
      />

      {!isOpac && (
        <AuthorSearchFilter
          yearFromData={searchResults?.jsonfacets?.yearfrom?.buckets}
          jsonFacets={searchResults?.jsonfacets}
        />
      )}
    </Grid>
  );
};

export default AuthorLeftbarFilters;
