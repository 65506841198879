import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { highlightAbstract, highlightKeys } from "../../utils/highlightKeyword";
import { MathJax } from "better-react-mathjax";
import { Colors, dataTypes } from "../../utils/constants";
import Paper from "@mui/material/Paper";
import { removeSpecialCharacters } from "../../utils/helper";

export default function AbstractModal(props: any) {
  const {
    dialogueData,
    handleKeyword,
    searchTerm,
    keywords,
    keywordData,
    isOpac,
    showDoi,
    showAuthorAffiliation,
    isStringOfArray,
    sortAuthorAffliliation,
    authorAffiliation,
    showAbstract,
    location,
    tocGetDataList,
    tocSearchTerm,
    abstractUrl,
    fromJournalToc,
    authorEmailData,
    authorEmail_data,
  } = props;

  return (
    <Grid
      container
      spacing={1}
      sx={{ width: "95%", marginInline: "auto" }}
      rowGap={1}
      direction={"column"}
    >
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Conference proceedings Section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Grid item>
        {dialogueData?.data_type !== dataTypes.conferenceProceedings &&
        dialogueData?.issue_title ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box minWidth={"150px"}>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
              >
                Proceedings:
              </Typography>
            </Box>
            <Typography variant="body2">{dialogueData?.issue_title}</Typography>
          </Box>
        ) : null}
      </Grid>

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Keywords section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Grid item>
        {keywords?.length ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box minWidth={"150px"}>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
              >
                Keywords:
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap" }} flexWrap="wrap">
              {dialogueData?.keywords?.map((val: string, i: number) => {
                return (
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{
                        fontFamily: "Helvetica Neue",
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        textTransform: "capitalize",
                        // padding: i === 0 ? "0px 8px 0px 0px" : "0px 8px",
                        padding: 0,
                        ":hover": { textDecoration: "underline" },
                        color: fromJournalToc ? "black" : "",
                      }}
                      onClick={() => {
                        if (!fromJournalToc) {
                          handleKeyword(val, null, dialogueData);
                        }
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: fromJournalToc
                            ? highlightKeys(val, tocSearchTerm)
                            : highlightKeys(val, searchTerm),
                        }}
                      />
                    </Button>
                    <Box paddingLeft={"8px"} />
                    <Divider
                      orientation="vertical"
                      style={{
                        height: "50%",
                        alignSelf: "center",
                      }}
                    />
                    <Box paddingRight={"8px"} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : null}
      </Grid>

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Abstract Section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Grid item>
        {showAbstract && dialogueData?.abstract ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box minWidth={"150px"}>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
              >
                Abstract:
              </Typography>
            </Box>
            <MathJax>
              <Typography variant="body2" sx={{ textAlign: "justify" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: fromJournalToc
                      ? highlightAbstract(
                          tocGetDataList,
                          tocSearchTerm,
                          dialogueData?.article_id,
                          dialogueData?.abstract
                        )
                      : highlightAbstract(
                          keywordData,
                          searchTerm,
                          dialogueData?.article_id,
                          dialogueData?.abstract
                        ),
                  }}
                />
              </Typography>
            </MathJax>
          </Box>
        ) : (
          <Box>
            {abstractUrl != "" && abstractUrl != null ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box minWidth={"150px"}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                  >
                    Abstract:
                  </Typography>
                </Box>
                <Typography variant="body2">
                  <a
                    target="_blank"
                    style={{ color: Colors.primary }}
                    href={`${abstractUrl}`}
                    rel="noreferrer"
                  >
                    {abstractUrl}
                  </a>
                </Typography>
              </Box>
            ) : // for DF-1342
            // <Typography style={{ textAlign: "center", marginTop: 20 }}>
            //   Abstract not available
            // </Typography>
            null}
          </Box>
        )}
      </Grid>

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Doi Section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Grid item>
        {dialogueData?.data_type == dataTypes.journalArticle ||
        dialogueData?.data_type == dataTypes.journals ||
        dialogueData?.data_type == dataTypes.magazine ||
        dialogueData?.data_type == dataTypes.dissertationThesis ||
        dialogueData?.data_type == dataTypes.preprint ||
        dialogueData?.data_type == dataTypes.conferenceProceedings ||
        dialogueData?.data_type == dataTypes.audioVideo ||
        isOpac ? (
          <div>
            {dialogueData?.articledoi ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box minWidth={"150px"}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                  >
                    DOI:
                  </Typography>
                </Box>
                <Typography variant="body2">
                  <a
                    target="_blank"
                    style={{ color: Colors.primary }}
                    href={`https://doi.org/${dialogueData?.articledoi}`}
                    rel="noreferrer"
                  >
                    https://doi.org/{dialogueData?.articledoi}
                  </a>
                </Typography>
              </Box>
            ) : null}
          </div>
        ) : null}
      </Grid>

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Citation Section>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

      <Grid item>
        {dialogueData?.data_type == dataTypes.journalArticle ||
        dialogueData?.data_type == dataTypes.journals ||
        isOpac ? (
          <>
            {showDoi && dialogueData?.articledoi ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box minWidth={"150px"}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                  >
                    Citation:
                  </Typography>
                </Box>
                <div
                  className="scite-badge"
                  data-doi={dialogueData?.articledoi}
                  data-layout="horizontal"
                ></div>
              </Box>
            ) : null}
          </>
        ) : null}
      </Grid>

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<< Author Affiliation Section>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Grid item>
        {dialogueData?.data_type == dataTypes.journalArticle ||
        dialogueData?.data_type == dataTypes.journals ||
        dialogueData?.data_type == dataTypes.audioVideo ||
        dialogueData?.data_type == dataTypes.preprint ||
        dialogueData?.data_type == dataTypes.conferenceProceedings ||
        dialogueData?.data_type == dataTypes.magazine ||
        dialogueData?.data_type == dataTypes.bookSeries ||
        dialogueData?.data_type == dataTypes.dissertationThesis ||
        isOpac ? (
          <div>
            {showAuthorAffiliation?.length ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box minWidth={"150px"}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                    pt="1.2rem"
                  >
                    Author Affiliations:
                  </Typography>
                </Box>
                <Box
                // sx={screen ? { width: "100%" } : { width: "70%" }}
                >
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      size="medium"
                    >
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
                          <TableCell>Author Name</TableCell>
                          <TableCell>Affliliation</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isStringOfArray(dialogueData?.author_details) ? (
                          sortAuthorAffliliation(
                            dialogueData?.author_details
                          )?.map((row: string, i: number) => (
                            <>
                              {row.split("$")[1] && (
                                <TableRow
                                  key={row}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.split("$")[0]}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {row.split("$")[1]}
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          ))
                        ) : (
                          <>
                            {Array.isArray(authorAffiliation) &&
                              authorAffiliation?.map((obj: any, i: number) => (
                                <TableRow
                                  key={i}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {obj?.author_fname +
                                      " " +
                                      obj?.author_lname}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {removeSpecialCharacters(
                                      obj?.author_affiliation
                                    )}
                                    {obj.author_country_name
                                      ? ", " + obj?.author_country_name
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            ) : null}
          </div>
        ) : null}
      </Grid>

      {/**==================== Author Email =============================*/}
      <Grid item>
        {dialogueData?.data_type == dataTypes.journalArticle ||
        dialogueData?.data_type == dataTypes.journals ||
        dialogueData?.data_type == dataTypes.audioVideo ||
        dialogueData?.data_type == dataTypes.preprint ||
        dialogueData?.data_type == dataTypes.conferenceProceedings ||
        dialogueData?.data_type == dataTypes.magazine ||
        dialogueData?.data_type == dataTypes.bookSeries ||
        dialogueData?.data_type == dataTypes.dissertationThesis ||
        isOpac ? (
          <>
            {authorEmailData?.length ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box minWidth={"150px"}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                  >
                    Corresponding Email:
                  </Typography>
                </Box>
                <div>{authorEmail_data}</div>
              </Box>
            ) : null}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
