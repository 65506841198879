import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { Colors, dataTypes } from "../../utils/constants";
import NewIcon from "../../assets/img/New.png";
import { onSearch as search } from "../../screens/Search/onSearch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updataLibraryOpac,
  updateCollectionValue,
  updateConsortiaCollection,
  updateJgateCollection,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
} from "../../store/slice/filterSlice";
import { updatePage } from "../../store/slice/searchSlice";
import { getQueryParams, showIsProfileToast } from "../../utils/helper";
import { personalCoreCount } from "../../api/Search/CollectionApi";
import { basicSearchForCounts } from "../../api/Search/BasicSearch.api";
import { updateQueries } from "../../store/slice/appliedQueries";
import { setSearch } from "../../api/Search/SetSearch";
import { queryConstructor } from "../../api/formQuery";
import { notify } from "../../utils/Notify";

//Type
interface PropType {
  jsonFacets?: any;
  data?: any;
  collectionsValue?: string;
  setcollectionsValue?: any;
  allCount?: number | string;
}

const CollectionsComponent = (props: PropType) => {
  let { jsonFacets, data, collectionsValue, setcollectionsValue, allCount } =
    props;

  const dispatch = useAppDispatch();
  const searchReducer = useAppSelector((state) => state.searchReducer);
  const filterReducer = useAppSelector((state) => state.filterReducer);
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );
  const [isProfileUser, setIsProfileUser] = useState(false);
  const dataType: any = useAppSelector((state) => state.searchReducer.dataType);
  const setSearchReducer = useAppSelector((state) => state.setSearch);

  const dataTypeToString =
    Array.isArray(dataType) && dataType?.map((val) => val.toString());
  const queryCount = useAppSelector((state) => state.queryForCount);

  const [count, setCount] = useState({
    jgateCollection: 0,
    personalLibCollection: 0,
    myLibrary: 0,
    consortiaSubscription: 0,
  });

  useEffect(() => {
    if (
      !filterReducer.libraryOpac &&
      !filterReducer.myLibraryCollection &&
      !filterReducer.myPersonalLibCollection &&
      !filterReducer.jgateCollection &&
      !filterReducer.onosCollection &&
      !filterReducer.consortiaCollection
    ) {
      if (
        customerDetails?.product_type === "1" ||
        customerDetails?.product_type === "1,7"
      ) {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateConsortiaCollection(false));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updateJgateCollection(true));

        dispatch(
          updateQueries({
            key: "jgateCollection",
            value: true,
          })
        );
      } else if (customerDetails?.product_type === "7") {
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateJgateCollection(false));
        dispatch(updateConsortiaCollection(true));
        dispatch(updateOnosCollection(false));
        dispatch(updatePersonalLibrary(false));
        dispatch(updataLibraryOpac(false));

        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }
  }, [customerDetails]);

  const handleChangeCollections = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updatePage(1));
    if (event.target.value === "MyLibraryCollection") {
      setcollectionsValue((event.target as HTMLInputElement).value);
    } else if (
      event.target.value === "MyPersonalLibraryCollection" &&
      isProfileUser
    ) {
      setcollectionsValue((event.target as HTMLInputElement).value);
    } else if (
      event.target.value === "J-GateCollection" ||
      event.target.value === "ConsortiaSubscriptions" ||
      event.target.value === "ONOSCollections" ||
      event.target.value === "Library OPAC"
    ) {
      setcollectionsValue((event.target as HTMLInputElement).value);
    }
    dispatch(updateCollectionValue(event?.target?.value));

    if (event.target.value === "J-GateCollection") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(true));

      dispatch(
        updateQueries({
          key: "jgateCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "ConsortiaSubscriptions") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));

      dispatch(
        updateQueries({
          key: "consortiaSubscriptions",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "MyLibraryCollection") {
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateMyLibraryCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));

      dispatch(
        updateQueries({
          key: "myLibraryCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "ONOSCollections") {
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(true));
      dispatch(updatePersonalLibrary(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));

      dispatch(
        updateQueries({
          key: "onosCollection",
          value: true,
        })
      );

      triggerEvent.current = triggerEvent.current + 1;
    } else if (event.target.value === "Library OPAC") {
      fetchOpacData();
    } else if (event.target.value === "MyPersonalLibraryCollection") {
      if (isProfileUser) {
        if (count.personalLibCollection === 0) {
          notify(
            "info",
            "Please visit Personal Library to configure My Favorites"
          );
        }
        dispatch(updateMyLibraryCollection(false));
        dispatch(updateConsortiaCollection(false));
        dispatch(updateOnosCollection(false));
        dispatch(updataLibraryOpac(false));
        dispatch(updatePersonalLibrary(true));

        dispatch(
          updateQueries({
            key: "myPersonalLibraryCollection",
            value: true,
          })
        );

        triggerEvent.current = triggerEvent.current + 1;
      } else {
        console.log("not profiel user");
        showIsProfileToast(
          "Please login as profile user to view this collection"
        );
      }
    }
  };

  async function fetchOpacData() {
    if (!customerDetails?.informaticscustomer_id) return;
    let searchTerm = searchReducer.searchTerm.toString();
    dispatch(updateMyLibraryCollection(false));
    dispatch(updateConsortiaCollection(false));
    dispatch(updateOnosCollection(false));
    dispatch(updatePersonalLibrary(false));
    dispatch(updataLibraryOpac(true));

    dispatch(
      updateQueries({
        key: "opac",
        value: true,
      })
    );

    dispatch(search());
  }

  const triggerEvent = useRef(0);

  useEffect(() => {
    if (triggerEvent.current) applyFilter();
  }, [triggerEvent.current]);

  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }
  }, []);

  useEffect(() => {
    updateCollectionCount();
  }, [queryCount.allTextQuery, queryCount.fullTextQuery]);

  useEffect(() => {
    updateSetSearchCollectionCount();
  }, [setSearchReducer.setSearchQuery]);

  async function applyFilter() {
    dispatch(search("", customerDetails, true));
  }

  // display the collection based on selected document types
  const showMyLib =
    Array.isArray(dataTypeToString) &&
    (dataTypeToString.includes("1") ||
      dataTypeToString.includes("2") ||
      dataTypeToString.includes("3"));
  const showKohaFilter = customerDetails?.other_product_type === "Koha";
  const showPersonalLib =
    Array.isArray(dataTypeToString) &&
    (dataTypeToString.includes("1") || dataTypeToString.includes("2")) &&
    isProfileUser;

  const showJgateCollection = customerDetails?.product_type?.includes("1");
  const showConsortiaSubscription =
    customerDetails?.product_type?.includes("7");

  async function updateCollectionCount() {
    try {
      // If set search enabled
      if (
        setSearchReducer.isSetSearchEnabled &&
        setSearchReducer.setSearchQuery
      ) {
        return;
      }

      let encodedString = encodeURIComponent(
        `${customerDetails?.informaticscustomer_id} OR ${customerDetails?.consortia_virtualid}`
      );
      let removeConsortiaParam = `&fq__acl_group=(${encodedString})`;

      let keywordToRemove = customerDetails?.informaticscustomer_id
        ? `&fq__acl_group=${customerDetails?.my_library_filter}`
        : "";

      const consortiaFilter =
        "&fq__acl_group=" + customerDetails?.consortia_filter;

      // const decodedQuery = decodeURIComponent(queryCount.allTextQuery);
      const decodedQuery = decodeURIComponent(queryCount.fullTextQuery);

      let query = decodedQuery.replace(
        new RegExp("\\b" + keywordToRemove + "\\b", "gi"),
        ""
      );
      query = decodedQuery.replace(keywordToRemove, "");
      let finalQuery = query.replace(removeConsortiaParam, "");
      finalQuery = finalQuery.replace(consortiaFilter, "");

      /**
       *  To get the count of J-gate collection
       */
      let res = await basicSearchForCounts(finalQuery);
      let count = res?.hits;
      if (count) {
        setCount((prev) => ({
          ...prev,
          jgateCollection: count,
        }));
      }

      /**
       *  To get the count of My Personal Library collection
       */
      const isProfileUser = sessionStorage.getItem("isProfileUser") === "true";
      if (isProfileUser) {
        let personalQuery = query.replace(
          "profileuserid=",
          "fq__profileuser_id="
        );
        personalQuery = personalQuery.replace(consortiaFilter, "");
        let finalQery = "";
        if (customerDetails?.product_type === "7") {
          finalQery = personalQuery + `&fq__acl_group=(${encodedString})`;
        } else {
          finalQery = personalQuery;
        }
        let personalCore = await personalCoreCount(finalQery);
        let personalCollectionCount = personalCore?.hits;
        if (personalCollectionCount) {
          setCount((prev) => ({
            ...prev,
            personalLibCollection: personalCollectionCount,
          }));
        } else {
          setCount((prev) => ({
            ...prev,
            personalLibCollection: 0,
          }));
        }
      }

      /**
       *  To get the count of My Library collection
       */
      // let myLibRes = await basicSearchForCounts(
      //   query + "&fq__acl_group=" + customerDetails?.my_library_filter
      // );
      // let myLibCount = myLibRes?.hits;

      // if (myLibCount) {
      //   setCount((prev) => ({
      //     ...prev,
      //     myLibrary: myLibCount,
      //   }));
      // } else {
      //   setCount((prev) => ({
      //     ...prev,
      //     myLibrary: 0,
      //   }));
      // }

      /**
       *  To get the count of Consortia subscription collection
       */
      // if (consortiamaster_id && showMyLib) {
      //   let consortiaParam = `&fq__acl_group=${customerDetails?.consortia_filter}`;
      //   let consortiaRes = await basicSearchForCounts(query + consortiaParam);
      //   let consortiaCount = consortiaRes?.hits;

      //   if (consortiaCount) {
      //     setCount((prev) => ({
      //       ...prev,
      //       consortiaSubscription: consortiaCount,
      //     }));
      //   } else {
      //     setCount((prev) => ({
      //       ...prev,
      //       consortiaSubscription: 0,
      //     }));
      //   }
      // }
    } catch (err) {
      console.log(err);
    }
  }

  async function updateSetSearchCollectionCount() {
    if (
      !setSearchReducer.isSetSearchEnabled &&
      !setSearchReducer.setSearchQuery
    )
      return;
    /**
     *  To get the count of J-gate collection
     */
    let setSearchQuery = setSearchReducer.setSearchQuery;

    // ============> Removing the collection filter realted query from setSearchQuery =======================
    let queryObj = getQueryParams(setSearchQuery);
    delete queryObj.fq__acl_group;
    setSearchQuery = await queryConstructor(queryObj);
    // ============================= End =================================

    let res = await setSearch(setSearchQuery, "all", "count");
    let count = res?.hits;

    if (count) {
      setCount((prev) => ({
        ...prev,
        jgateCollection: count,
      }));
    }

    /**
     *  To get the count of My Personal Library collection
     */
    const isProfileUser = sessionStorage.getItem("isProfileUser") === "true";
    if (isProfileUser) {
      let personalCore = await setSearch(setSearchQuery, "myPersonal", "count");
      let personalCollectionCount = personalCore?.hits;
      if (personalCollectionCount) {
        setCount((prev) => ({
          ...prev,
          personalLibCollection: personalCollectionCount,
        }));
      } else {
        setCount((prev) => ({
          ...prev,
          personalLibCollection: 0,
        }));
      }
    }

    /**
     *  To get the count of My Library collection
     */
    // const myLibRes = await setSearch(setSearchQuery, "myLibrary", "count");
    // let myLibCount = myLibRes?.hits;

    // if (myLibCount) {
    //   setCount((prev) => ({
    //     ...prev,
    //     myLibrary: myLibCount,
    //   }));
    // } else {
    //   setCount((prev) => ({
    //     ...prev,
    //     myLibrary: 0,
    //   }));
    // }

    /**
     *  To get the count of Consortia subscription collection
     */
    // if (consortiamaster_id && showMyLib) {
    //   let consortiaRes = await setSearch(
    //     setSearchQuery,
    //     "myConsortia",
    //     "count"
    //   );
    //   let consortiaCount = consortiaRes?.hits;

    //   if (consortiaCount) {
    //     setCount((prev) => ({
    //       ...prev,
    //       consortiaSubscription: consortiaCount,
    //     }));
    //   } else {
    //     setCount((prev) => ({
    //       ...prev,
    //       consortiaSubscription: 0,
    //     }));
    //   }
    // }
  }

  // If thesis doc type selected form the document type filter only show the jgate collection
  const isThesisDocTypeSelected =
    filterReducer.sourceType.length === 1 &&
    filterReducer.sourceType.includes(dataTypes.dissertationThesis);
  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={{ paddingInline: "7%", paddingBlock: "4%" }}>
          <Typography sx={webStyle.titleHeader} gutterBottom>
            Collections
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={collectionsValue}
            onChange={handleChangeCollections}
          >
            {showJgateCollection && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="J-GateCollection"
                control={<Radio />}
                // label={`J-Gate Collection ${
                label={`Global Journal Collection ${
                  count.jgateCollection ? "(" + count.jgateCollection + ")" : ""
                }`}
              />
            )}
            {/* {!isThesisDocTypeSelected && count?.myLibrary && showMyLib ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="MyLibraryCollection"
                control={<Radio />}
                label={`${
                  customerDetails?.customershortname != null
                    ? customerDetails?.customershortname + " " + "Subscribed"
                    : "My Library Collection"
                } ${count.myLibrary ? "(" + count.myLibrary + ")" : ""}`}
              />
            ) : null} */}
            {/* {showKohaFilter && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value={"Library OPAC"}
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    Library Catalogue{" "}
                    <img src={NewIcon} style={{ width: "50px" }} />{" "}
                  </Box>
                }
              />
            )} */}
            {!isThesisDocTypeSelected && showPersonalLib ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="MyPersonalLibraryCollection"
                control={<Radio />}
                label={`My Personal Library Collection ${
                  count.personalLibCollection
                    ? "(" + count.personalLibCollection + ")"
                    : "(0)"
                } `}
              />
            ) : null}

            {/* {!isThesisDocTypeSelected &&
            showConsortiaSubscription &&
            count.consortiaSubscription ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="ConsortiaSubscriptions"
                control={<Radio />}
                label={`Consortia Subscriptions ${
                  count.consortiaSubscription
                    ? "(" + count.consortiaSubscription + ")"
                    : ""
                }`}
              />
            ) : null} */}
            {/* {!isThesisDocTypeSelected && customerDetails?.is_enable_onos ? (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="ONOSCollections"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    ONOS Collections
                    <img src={NewIcon} style={{ width: "50px" }} />{" "}
                  </Box>
                }
              />
            ) : null} */}
          </RadioGroup>
        </Box>
      </Box>
    </>
  );
};

export default CollectionsComponent;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
