import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { shareFolder } from "../../../api/MyLibrary/MyFolders.api";
import IconButton from "@mui/material/IconButton";
import { notify } from "../../../utils/Notify";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { usagelogApi } from "../../../api/usageReportApi";

interface Props {
  show: boolean;
  title: string;
  foldername: string;
  folderId: string;
}

const ShareFolderModal = ({ show, title, foldername, folderId }: Props) => {
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.login);
  const userEmail = sessionStorage.getItem("user_email");

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const shareFolderHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => shareFolder(obj),
    onSuccess: ({ data }) => {
      const isSharedWithAlready = data.data[0][0].already_shared_with;

      if (isSharedWithAlready) {
        notify("warning", "Folder already shared with this email");
      } else {
        notify("success", "Folder Shared Successfully");
        dispatch(hideModal());
        queryClient.invalidateQueries({ queryKey: ["GetMyFolders"] });
      }
    },
  });

  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");

  const handleShare = async () => {
    if (email === userEmail) {
      notify("warning", "You cannot share folder with yourself");
      return;
    }

    if (!email) {
      notify("warning", "Email is required to share folder");
      return;
    }
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      113,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
    const formData = new FormData();
    formData.append("folderid", folderId);
    formData.append("fromprofileuserid", user_id);
    formData.append("sharedemailIds", email);
    formData.append("personalremarks", message);

    shareFolderHandler.mutate({ formData: formData });
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="ShareFolderModal-Title"
      aria-describedby="ShareFolderModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          minWidth: "28vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {title}&nbsp;-&nbsp;{foldername}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Text sx={{ mb: 1 }}>
            Email Id <span style={{ color: "red", fontSize: "bold" }}>*</span>
          </Text>
          <TextField
            size="small"
            placeholder="Multiple email IDs to be separated by semicolon ;"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </Box>

        <Box sx={{ width: "100%", mb: 2 }}>
          <Text sx={{ mb: 1 }}>Message</Text>
          <TextField
            fullWidth
            multiline
            rows={3}
            size="small"
            placeholder="Type your message"
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
          />
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleShare}
          >
            Share Folder
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareFolderModal;
