import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import { useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextfield from "../../components/formInputs/TextField";
import { useForm } from "react-hook-form";
import { validatePassword } from "../../utils/validatePassword";
import { userLogin } from "../../api/userApi";
import { loginSuccess } from "../../store/slice/loginSlice";
import { notify } from "../../utils/Notify";
import { clearDataTypeFilters, reset } from "../../store/slice/searchSlice";
import { useState } from "react";
import { clearCustomerDetails } from "../../store/slice/customerDetails";
import { RememberMe } from "@mui/icons-material";
import api from "../../api/endpoints";
import { usagelogApi } from "../../api/usageReportApi";

interface PropType {
  open: boolean;
  isProfileUser: string;
}

const LoginModal = (props: PropType) => {
  const { open, isProfileUser } = props;
  const { control, setError, handleSubmit, setValue } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState<any>("");
  const [checked, setChecked] = useState<any>(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const allowProfileSignup = customerDetails?.allow_profile;

  function handlePassword() {
    setShowPassword((prev) => !prev);
  }

  const getStoredData = () => {
    let emailVal: any = localStorage.getItem("email");
    let passVal: any = localStorage.getItem("password");
    let rememberVal: any = localStorage.getItem("rememberMe");

    if (emailVal != "" && passVal != "" && rememberVal === "true") {
      setValue("userName", emailVal);
      setValue("password", passVal);
    }
  };
  useEffect(() => {
    getStoredData();
  }, [open]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data?.ip);
        sessionStorage.setItem("user_ipv4_address", data?.ip);
      } catch (error) {
        setIpAddress("0");
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const logProfileUserUsageData = (usageActionID: any, profileData: any) => {
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      null,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      ipAddress,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  async function onSubmit(data: any) {
    try {
      const { userName, password } = data;

      const response: any = await userLogin({
        email: userName,
        password,
        ipAddress: 0,
      });

      // localStorage.setItem("rememberMe", rememberMe.toString());

      // clearing user data
      dispatch(reset());
      dispatch(clearCustomerDetails());
      dispatch(clearDataTypeFilters());
      sessionStorage.clear();

      // updating user data
      sessionStorage.setItem("session_key", response?.session_key);

      //Save the user sessio in local storage
      sessionStorage.setItem("csrf_token", response?.csrf_token);
      sessionStorage.setItem("user_id", response?.user_id);
      sessionStorage.setItem("preference", "false");

      sessionStorage.setItem(
        "informaticscustomer_id",
        response?.informaticscustomer_id
      );
      sessionStorage.setItem("isIPLogin", "false");
      dispatch(loginSuccess(response));
      if (response?.data === "Success") {
        logProfileUserUsageData(2, response);
      }
      window.location.reload();
    } catch (err: any) {
      if (err?.response?.data?.data) {
        notify("error", err?.response?.data?.data);
      } else {
        notify("error", "Something went wrong! Please try again");
      }
    }
  }

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe");
    if (rememberMeValue === "true") {
      setRememberMe(true);
    }
  }, []);

  const dispatch = useAppDispatch();

  function handleKeyPress(e: any) {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  }

  const handleCloseIcon = () => {
    dispatch(hideModal());
    notify(
      "error",
      "Login to your profile with valid credentials using Signin"
    );
  };
  return (
    <Dialog open={open} onClose={() => dispatch(hideModal())} maxWidth={false}>
      {/* ------------------------Title --------------------------------------- */}
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
          Login as Profile User
        </Typography>
        <IconButton onClick={handleCloseIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/*----------------------------- Content------------------------------------- */}
      <DialogContent
        sx={{ width: { sx: "100%", sm: "100%", md: "400px", lg: "400px" } }}
      >
        <Stack direction={"column"} gap={1}>
          <Typography
            fontFamily={"Lora"}
            fontSize={"34px"}
            fontWeight={"700"}
            sx={{
              fontSize: "1rem",
            }}
          >
            User Name
          </Typography>
          <CustomTextfield
            control={control}
            name="userName"
            fullWidth
            size="medium"
            rules={{ required: "User Name is required!" }}
            placeholder="eg. John"
            onKeyPress={handleKeyPress}
            inputValue={email}
          />
          <Typography
            fontFamily={"Lora"}
            fontSize={"34px"}
            fontWeight={"700"}
            sx={{
              fontSize: "1rem",
            }}
          >
            Password
          </Typography>
          <CustomTextfield
            control={control}
            name="password"
            fullWidth
            size="medium"
            rules={{ required: "Password is required!" }}
            placeholder="********"
            handleIcon={handlePassword}
            showeyeIcon
            type={showPassword ? "text" : "password"}
            eyeIconStatus={showPassword}
            onKeyPress={handleKeyPress}
            inputValue={password}
          />

          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            type="submit"
            sx={{ marginTop: 2 }}
          >
            Submit
          </Button>

          <Divider sx={{ my: 3 }} />
          <Button variant="text" href="/resetpassword">
            Forgot password?
          </Button>

          <Box>
            <Typography
              fontWeight={700}
              textAlign="center"
              fontFamily={"Helvetica Neue"}
              fontSize={"14px"}
            >
              Want to Login with Shibboleth?{" "}
              <a
                className="linktext_redirect"
                href={`${api.baseApi}/api/shibboleth_login/shibboleth_log_in`}
              >
                Login
              </a>
            </Typography>
          </Box>

          {(allowProfileSignup && isProfileUser === "false") ||
          customerDetails?.customer_id === 0 ? (
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                fontWeight={500}
                textAlign="center"
                fontFamily={"Helvetica Neue"}
                fontSize={"14px"}
                sx={{ color: "#212121" }}
              >
                Don&apos;t have an account?{" "}
                <Button href="/signup">Sign Up</Button>
              </Typography>
            </Box>
          ) : null}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
