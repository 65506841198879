import { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { Switch } from "../../components/Switch";
import { queryBuilderStyles as sx } from "./QueryBuilder.styles";
import Chip from "@mui/material/Chip";
import HeaderSection from "../Search/HeaderSection";
import QueryBuilder from "./QueryBuilder";
import useQBFormField, { QBFormValues } from "../../hooks/useQBFormField";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  GetCustomerByIDDatatype,
  GetCustomerByIDSubject,
} from "../../types/GetCustomerByID.types";
import { temporaryQueryConstructor } from "./TemporaryQueryConstructor";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useGetMySearchPreference } from "../../api/Preferences/Subjects.api";
import {
  clearDataTypeFilters,
  clearSearchTerm,
  updateAdvSearchQuery,
  updateDataType,
  updateFullText,
  updateLinkToDisplay,
  updateMarkedArticleCount,
  updateNewSearchStatus,
  updatePage,
  updateRow,
  updateSearchResults,
  updateSearchTerm,
  updateSearchType,
  updateSubjectName,
  updateSubjectType,
} from "../../store/slice/searchSlice";
import { queryConstructor } from "../../api/formQuery";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import {
  isPreferenceAdded,
  removeOperatorFromEnd,
  showIsProfileToast,
} from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { setModal } from "../../store/slice/modalSlice";
import {
  basicSearchForCounts,
  basicSearchMain,
} from "../../api/Search/BasicSearch.api";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updataLibraryOpac,
  updateAllFilter,
  updateCollectionValue,
  updateConsortiaCollection,
  updateJgateCollection,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
  updateSort,
} from "../../store/slice/filterSlice";
import { usagelogApi } from "../../api/usageReportApi";
import { notify } from "../../utils/Notify";
import JournalPopUp from "./JournalPopUp";
import { getListOfJournal } from "../../api/browseJournal";
import objectToQueryString from "../browseJournal/objToQuery";
import { GetMyPreferenceData } from "../../types/GetMySubjects.types";
import { dataTypes } from "../../utils/constants";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import { clearAllSetSearch } from "../../store/slice/setSearch";
import { updateIsAuthorResult } from "../../store/slice/authorFinderSlice";
import { updateBrowseJournalSearch } from "../../store/slice/browseJournal";
import FormAdvanceQueryTerm from "./FormAdvanceQueryTerm";
import { constants } from "buffer";
import { Link } from "@mui/material";
import { CustomTooltip } from "../MyLibrary/MyAlerts/Alerts.style";
import { IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export type QBField = { id: number };

const AdvancedSearch = () => {
  const { fields, control, append, remove, handleSubmit, watch, setValue } =
    useQBFormField();
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);
  const navigate = useNavigate();
  let userId = sessionStorage.getItem("user_id");
  let session_key = sessionStorage.getItem("session_key");

  const isPreferenceEnabled = sessionStorage.getItem("preference") === "true";
  const [onJournal, setJournal] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState<number[]>([0]);
  const [isPreference, setIsPreference] = useState(isPreferenceEnabled);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [subjectData, setSubjectData] = useState<GetCustomerByIDSubject[]>([]);
  const [displayQuery, setDisplayQuery] = useState<string>("");
  const [allTags, setAllTags] = useState<GetCustomerByIDDatatype[]>([]);
  const [journalData, setJournalData] = useState<any[]>([]);
  const [onChange, setOnChange] = useState<any>(fields);
  const [indexOf, setIndexOf] = useState<any>();
  const [selectedJournal, setSelectedJournal] = useState<string[]>([]);
  const [autoSearchKeyword, setAutoSearchKeyword] = useState<any>("");
  const [autoSearch, setAutoSearch] = useState<any>([]);
  const [pageIndex, setpageIndex] = useState<any>();
  const [checkDoi, setCheckDoi] = useState(false);
  const [extraStringQuery, setExtraStringQuery] = useState("");
  //Get Customer Data
  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  //Get Personalized Data for Preference
  const { data: myPreference, refetch } = useGetMySearchPreference(
    selector.user_id,
    isPreference
  );
  const searchReducer = useAppSelector((state) => state.searchReducer);

  const profileData = useAppSelector((state: any) => state.login);

  const [selectedLetterTagsPublisher, setSelectedLetterTagsPublisher] =
    useState(Array(28).fill(false));
  const [selectedTagPublisher, setSelectedTagPublisher] = useState<String>("");

  const updateSelectedTagsPublisher = (element: any, index: any) => {
    const tagPublisherMapping: any = {
      2: "A OR B OR C",
      3: "D OR E OR F",
      4: "G OR H OR I",
      5: "J OR K OR L",
      6: "M OR N OR O",
      7: "P OR Q OR R",
      8: "S OR T OR U",
      9: "V OR X OR Y OR Z",
    };
    let temp = Array(28).fill(false);
    if (selectedTagPublisher[element] && element == 0) {
      setSelectedLetterTagsPublisher(temp);
      setSelectedTagPublisher("");
    } else {
      if (element == 0) {
        temp[0] = true;
        setSelectedLetterTagsPublisher(temp);
        setSelectedTagPublisher("");
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTagsPublisher(temp);
        setSelectedTagPublisher("");
      } else {
        temp[element] = true;
        setSelectedLetterTagsPublisher(temp);
        if (tagPublisherMapping.hasOwnProperty(element)) {
          setSelectedTagPublisher(tagPublisherMapping[element]);
        }
        // setSelectedTagPublisher(String.fromCharCode(element + 63));
      }
    }
  };
  const handleCloseJournalData = () => {
    const ofFields = [...fields];
    if (
      ofFields &&
      Array.isArray(ofFields) &&
      indexOf >= 0 &&
      indexOf < ofFields.length
    ) {
      // @ts-ignore
      ofFields[indexOf].journalSelected = [];
      // @ts-ignore
      ofFields[indexOf].selectedJournal = [];
    }
    setJournal(!onJournal);
  };

  const profileData1 = useAppSelector(
    (state: any) => state.customer.customerDetails
  );

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  const profileStatus = sessionStorage.getItem("isProfileUser");

  useEffect(() => {
    refetch();

    dispatch(clearAllFilters());
    dispatch(clearDataTypeFilters());
    dispatch(updateMarkedArticleCount([]));
    dispatch(clearAllFiltersValue());
    dispatch(clearSearchTerm());
    dispatch(clearAllSetSearch());
    dispatch(updateSearchType("2"));
    dispatch(updateIsAuthorResult(false));
    dispatch(updateBrowseJournalSearch(false));
  }, [dispatch, refetch]);

  const showLoginModal = useCallback(() => {
    if (!customerData?.allow_profile) return;
    if (sessionStorage.getItem("isLoginModalShowed") === "true") return;
    // if (profileStatus === "false" || customerData?.customer_id === 0) {
    if (customerData?.customer_id === 0) {
      dispatch(
        setModal({
          modalType: "SHOW_LOGIN",
          modalProps: {
            open: true,
            isProfileUser: profileStatus,
          },
        })
      );
    }
    sessionStorage.setItem("isLoginModalShowed", "true");
  }, [customerData?.customer_id, dispatch, profileStatus]);

  useEffect(() => {
    showLoginModal();
  }, [profileStatus, showLoginModal, customerData?.customer_id]);

  //Set Initial Data
  useEffect(() => {
    try {
      if (customerData) {
        let filteredSubjectData: GetCustomerByIDSubject[] = [];
        let filteredSubjectIds: number[] = [];
        let filteredSubjectNames: string[] = [];
        let filteredAllTags: GetCustomerByIDDatatype[] = [];
        let filteredSelectedTags: number[] = [];
        let isAllSelected: boolean = true;
        const selectedDocTypes =
          Array.isArray(searchReducer.dataType) &&
          searchReducer.dataType?.filter((x) => x !== 2);
        const selectedSubjects =
          Array.isArray(searchReducer.subjectType) && searchReducer.subjectType;

        //Clear All Tag's Filters
        dispatch(clearDataTypeFilters());

        if (isPreference && myPreference) {
          //Get My Subject Data
          filteredSubjectData = myPreference.Subjects?.map(
            ({ subject2_details }) =>
              subject2_details.filter(({ is_favorite }) => is_favorite)
          )
            .flat()
            ?.map((l2) => {
              return {
                datsubjectmasterlevel1_id: -1, //Just for Integrity's Sake
                datsubjectmasterlevel2_id: l2.subject_id2,
                level2subject_name: l2.subject_name2,
              };
            });

          //Get My Subject Data IDs
          filteredSubjectIds = filteredSubjectData?.map(
            ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
          );
          //Get My Subject Data Names
          filteredSubjectNames = filteredSubjectData?.map(
            ({ level2subject_name }) => level2subject_name
          );

          //Get My Sources
          filteredAllTags = myPreference?.DataTypes?.map((source) => {
            return {
              dat_resourcetype_id: source.datatype_id,
              accesstype: source.datatype_name,
              image_small: source.datatype_name.toLocaleLowerCase(),
            };
          });

          //Get My Source ID's
          filteredSelectedTags = myPreference?.DataTypes.filter(
            ({ is_favorite }) => is_favorite
          )?.map(({ datatype_id }) => datatype_id);

          filteredAllTags = customerData?.datatypes?.filter(
            (x) => x.dat_resourcetype_id !== 2
          );

          //Should Select All Tags if True
          isAllSelected =
            filteredSelectedTags.length === myPreference?.DataTypes.length;
        } else {
          //Get All Subject Data

          filteredSubjectData = customerData.subject;

          //Get All Subject Data IDs
          filteredSubjectIds = customerData.subject?.map(
            ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
          );

          //Get My Subject Data Names
          filteredSubjectNames = customerData.subject?.map(
            ({ level2subject_name }) => level2subject_name
          );

          //Get All Sources
          filteredAllTags = customerData?.datatypes?.filter(
            (x) => x.dat_resourcetype_id !== 2
          );
        }

        //Update Subjects
        setSubjectData(() => filteredSubjectData);

        //Update Subject Ids
        dispatch(updateSubjectType(filteredSubjectIds));
        setSelectedSubjects(() => filteredSubjectIds);

        //Update Subject Names
        dispatch(updateSubjectName(filteredSubjectNames));
        // setSelectedSubjectNames(() => filteredSubjectNames);

        let docArray: any = [];

        if (filteredAllTags) {
          docArray = [
            {
              dat_resourcetype_id: 0, // Custom ID, 0 => All
              accesstype: "All",
              image_small: "all",
            },
            ...filteredAllTags,
          ];
        }

        let selectedDoc = isAllSelected
          ? docArray?.map((x: any) => x?.dat_resourcetype_id)
          : filteredSelectedTags;

        /**
         * updating doc types
         */
        if (selectedTags.length && !isPreference) {
          setSelectedTags(selectedTags);
        } else if (
          selectedDocTypes &&
          selectedDocTypes.length &&
          !isPreference
        ) {
          setSelectedTags(selectedDocTypes);
        } else {
          setSelectedTags(selectedDoc);
        }

        /**
         * updating subject types
         */
        if (selectedSubjects && selectedSubjects.length && !isPreference) {
          setSelectedSubjects(() => selectedSubjects);
          // updating subnames
          let subNames: string[] = [];
          for (let id of selectedSubjects) {
            for (let obj of customerData?.subject) {
              if (id === obj?.datsubjectmasterlevel2_id) {
                subNames.push(obj?.level2subject_name);
              }
            }
          }
          dispatch(updateSubjectName(subNames));
          // setSelectedSubjectNames(() => subNames);
        } else {
          setSelectedSubjects(() => filteredSubjectIds);
          //Update Subject Names
          dispatch(updateSubjectName(filteredSubjectNames));
          // setSelectedSubjectNames(() => filteredSubjectNames);
        }

        //Update Tags
        setAllTags(() => docArray);
        // setSelectedTags(selectedDoc);
        // dispatch(updateDataType(selectedDoc));
      }
    } catch (err) {
      console.log("Unknown error occoured");
    }
  }, [customerData, dispatch, isPreference, myPreference]);
  const setStandardTerm = (term: string) => {
    switch (term) {
      case "Title/Abstract/Keyword":
        return "TI/KW/Abst : ";
      case "Title":
        return "TI : ";
      case "Keyword only":
        return "KW : ";
      case "Title/Keyword":
        return "TIKW : ";
      case "Publication Year":
        return "Date : ";
      case "Author":
        return "AU : ";
      case "Author Affiliation":
        return "AF : ";
      case "ISSN":
        return "ISSN : ";
      case "DOI":
        return "DOI : ";
      case "Journal":
        return "JRNL : ";
    }
  };

  const setStandardTermForUi = (term: string) => {
    switch (term) {
      case "Title/Abstract/Keyword":
        return "TI/KW/Abst";
      case "Title":
        return "TI";
      case "Keyword only":
        return "KW";
      case "Title/Keyword":
        return "TIKW";
      case "Publication Year":
        return "Date";
      case "Author":
        return "AU";
      case "Author Affiliation":
        return "AF";
      case "ISSN":
        return "ISSN";
      case "DOI":
        return "DOI";
      case "Journal":
        return "JRNL";
    }
  };

  // To Update Query Editor TextField
  useEffect(() => {
    watch("myFields");
    const subscription = watch(({ myFields }: any) => {
      if (myFields) {
        const condtions = myFields?.map((x: any) => x.conditionField);
        const searchTerms = myFields?.map((x: any) => `#${x.qbIndex + 1}`);
        const criteria = myFields?.map((x: any) => x.criteriaField);
        const term = myFields?.map((x: any, i: number) => {
          if (criteria[i] == "Author") {
            console.log("myFields2", x);
            return `${
              x.author_lastname && x.author_firstname_fz
                ? x.author_lastname + "," + x.author_firstname_fz
                : x.author_lastname
                ? x.author_lastname
                : x.author_firstname_fz
                ? x.author_firstname_fz
                : ""
            } `;
          } else if (criteria[i] == "Publication Year") {
            return `${x.from !== undefined ? `${x.from}` : ""}${
              x.to !== undefined ? ` - ${x.to}` : ""
            }`;
          } else {
            return x.searchTerm;
          }
        });

        const formQueryX = (a: any[], b: any[], c: any[], d: any[]) => {
          let arr: string[] = [];
          if (a.length && b.length) {
            for (let i = 0; i < a.length; i++) {
              if (d[i]) {
                if (
                  c[i] === "Title/Abstract/Keyword" ||
                  c[i] === "Title" ||
                  c[i] === "Keyword only" ||
                  c[i] === "Title/Keyword"
                ) {
                  let term = d[i];
                  if (d[i].split(/\s+/).length > 1) {
                    let q = d[i].replace(/^\(|\)$/g, "");
                    term = q?.trim() ? `[${q?.trim()}]` : "";
                  }
                  let criteria = setStandardTermForUi(c[i])?.replace(":", "");
                  arr = [...arr, term, criteria, b[i]];
                } else {
                  let criteria = setStandardTermForUi(c[i])?.replace(":", "");
                  arr = [...arr, d[i]?.trim(), criteria, b[i]];
                }
              }
            }
          }
          return arr;
        };

        const formQuery = (a: any[], b: any[], c: any[], d: any[]) => {
          let arr: string[] = [];
          if (a.length && b.length) {
            for (let i = 0; i < a.length; i++) {
              if (d[i]) {
                if (
                  c[i] === "Title/Abstract/Keyword" ||
                  c[i] === "Title" ||
                  c[i] === "Keyword only" ||
                  c[i] === "Title/Keyword"
                ) {
                  let term = d[i];
                  if (d[i].split(/\s+/).length > 1) {
                    let q = d[i].replace(/^\(|\)$/g, "");
                    term = `(${q})`;
                  }
                  arr = [...arr, setStandardTerm(c[i]), term, b[i]];
                } else {
                  arr = [...arr, setStandardTerm(c[i]), d[i], b[i]];
                }
              }
            }
          }
          return arr;
        };

        let x = formQuery(searchTerms, condtions, criteria, term);
        x.pop();
        setDisplayQuery(() => x.join("  "));
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  //Handle Tags
  const handleTags = (id: number) => {
    // usageReport("13");
    logBasicSearchUsageData(13);
    sessionStorage.setItem("preference", "false");
    setIsPreference(false);
    let allDocIds: any = customerData?.datatypes
      .filter((x) => x.dat_resourcetype_id !== 2)
      ?.map((obj) => obj?.dat_resourcetype_id);
    if (id === 0) {
      // if the preference is on and user select all the preference toggle should be turn off
      sessionStorage.setItem("preference", "false");
      setIsPreference(false);
      //"All" is Selected
      if (selectedTags.length === allDocIds?.length + 1) {
        setSelectedTags([]);
      } else {
        setSelectedTags(() => [...allDocIds, 0]);
      }
    } else {
      if (selectedTags.includes(id)) {
        //Remove
        const newArr = selectedTags
          .filter((item) => item !== id)
          .filter((x) => x !== 0);
        setSelectedTags(() => newArr);
      } else {
        //Add Current & deselct "All"
        let curr = selectedTags.filter((item) => item !== 0);
        setSelectedTags([...curr, id]);
      }
    }
  };

  //Main Search Function
  const handleFormSubmit = async ({ myFields: dasFields }: any) => {
    let myFields: any[] = dasFields;

    if (
      myFields.length > 0 &&
      !myFields.at(-1)?.searchTerm &&
      myFields.at(-1)?.criteriaField !== "Publication Year" &&
      myFields.at(-1)?.criteriaField !== "Author"
    ) {
      myFields = myFields.slice(0, -1);
    }

    setLoading(true);
    let advancedSearchQuery = formAdvanceQuery(myFields);
    // Have to remove if the last word is any operator [AND, OR, NOT] not followed by any other word.
    advancedSearchQuery = removeOperatorFromEnd(advancedSearchQuery);

    const searchTerm = extraStringQuery
      ? formSearchTerm(myFields) + " " + extraStringQuery
      : formSearchTerm(myFields);
    if (searchTerm === "specialChar") {
      notify("error", "Please input valid keyword / topic to search");
      setLoading(false);
      return;
    }

    let tags = [...selectedTags];
    if (tags.includes(1) && !tags.includes(2)) tags.push(2);

    let encodedTags = "";
    if (selectedTags.length === 1 && selectedTags[0] === 0) {
      encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24)";
    } else {
      encodedTags = `(${temporaryQueryConstructor(tags)})`;
    }

    dispatch(updateDataType(tags));

    let encodedSubjects = "";
    if (selectedSubjects.length === 0) {
      encodedSubjects = `(${temporaryQueryConstructor(
        subjectData?.map((x) => x.datsubjectmasterlevel2_id)
      )})`;
    } else {
      encodedSubjects = `(${temporaryQueryConstructor(selectedSubjects)})`;
    }

    let queryObj: any = {
      page: 1,
      sort: "dateofpublication desc",
      fq__resource_type: encodedTags,
      rows: 15,
      customerid: selector.informaticscustomer_id,
      sessionid: sessionStorage.getItem("session_key"),
      op_mode: "and",
      profileuserid: userId,
      datsearchtype_id: "2",
    };

    if (encodedSubjects !== "()") {
      queryObj.fq__subjects_id_l2 = encodedSubjects;
    }
    // queryObj.datsearchtype_id = 2;

    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      queryObj.fq__acl_group = customerData?.consortia_filter;
    }
    // update advance search query to redux
    dispatch(updateAdvSearchQuery(advancedSearchQuery));

    let fullTextQuery = await queryConstructor({
      ...queryObj,
      // fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${profileData.informaticscustomer_id}))`,
    });

    let logAdvanceQuery = await queryConstructor({
      ...queryObj,
      logsearchhistory: true,
      "fq__(fulltext": `true OR acl_group=(${profileData.informaticscustomer_id}))`,
    }); //To log the search

    let allQuery = await queryConstructor({
      ...queryObj,
    });

    fullTextQuery = fullTextQuery + "&" + advancedSearchQuery;
    allQuery = allQuery + "&" + advancedSearchQuery;
    logAdvanceQuery = logAdvanceQuery + "&" + advancedSearchQuery; //To log the search

    // Update Query
    dispatch(
      updateQuery({
        fullTextQuery: fullTextQuery,
        allTextQuery: allQuery,
      })
    );

    const searchedData = {
      key: "search-term",
      value: searchTerm.trim(),
    };

    dispatch(
      updateAllFilter({
        key: "search-term",
        value: searchTerm,
      })
    );

    const fullTextData = await basicSearchMain(fullTextQuery);
    const allData = await basicSearchMain(allQuery);
    await basicSearchMain(logAdvanceQuery);
    await linkToDisplay(fullTextData?.docs);

    // to get count
    const allText = await basicSearchForCounts(allQuery);
    const fulltext = await basicSearchForCounts(fullTextQuery);

    if (fulltext.hits === 0) {
      dispatch(updateSearchResults(allData));
    } else {
      dispatch(updateSearchResults(fullTextData));
    }

    // Copied from SearchHistory
    dispatch(updateSort("dateofpublication desc"));
    dispatch(clearAllFiltersValue());
    dispatch(updateFullText(true));
    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(updateAllFilter(searchedData));
    dispatch(updateNewSearchStatus(true));
    dispatch(updateRow(15));
    dispatch(updatePage(1));

    dispatch(updateFullText(true));
    setLoading(false);
    logBasicSearchUsageData(14);

    if (fullTextData?.docs) {
      navigate("/basicSearchScreen", {
        state: {
          searchTerm: searchTerm,
          fullTextQuery: fullTextQuery,
          allQuery: allQuery,
          allCount: allText?.hits,
          fullCount: fulltext?.hits,
        },
      });
    }
  };

  const formAdvanceQuery = (myFields: any): string => {
    function addParentheses(input: string) {
      input = input.toLocaleLowerCase();
      if (
        input.includes(" or ") ||
        input.includes(" and ") ||
        input.includes(" not ")
      ) {
        // Split the input string by logical operators (AND, OR, NOT)
        const terms = input.split(/\b(and|or|not)\b/);

        // Remove empty strings from the array
        const filteredTerms = terms.filter(
          (term: string) => term.trim() !== ""
        );

        // Add parentheses around each non-operator single term
        const formattedTerms = filteredTerms.map((term: string) => {
          const trimmedTerm = term.trim();
          const isOperator = /\b(and|or|not)\b/.test(trimmedTerm);

          if (isOperator || !trimmedTerm.includes(" ")) {
            return isOperator ? ` ${term.trim()} ` : `${term.trim()}`; // Keep operators and the last term as-is
          } else {
            return `(${trimmedTerm})`; // Add parentheses around single terms
          }
        });

        // Join the terms back together with the original operators
        const output = formattedTerms.join("");

        return output;
      } else {
        return input;
      }
    }

    try {
      let queryString = "";
      for (let i = 0; i < myFields.length; i++) {
        let isLastField = i === myFields.length - 1;
        if (
          myFields[i].criteriaField === "Title/Abstract/Keyword" &&
          myFields[i].searchTerm !== ""
        ) {
          if (
            myFields[1]?.searchTerm === "" &&
            myFields.length === 2 &&
            myFields[1]?.criteriaField !== "Author" &&
            myFields[1]?.criteriaField !== "Publication Year"
          ) {
            queryString +=
              ("titleKeywordsAbs=" +
                myFields[i].searchTerm.trim(" ").includes(" ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" or ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" and ") &&
                !myFields[i].searchTerm
                  .toLocaleLowerCase()
                  .includes(" not ")) ||
              (myFields[i].searchTerm.trim(" ").includes("(") &&
                myFields[i].searchTerm.trim(" ").includes(")"))
                ? `(${myFields[i].searchTerm.replace(/^\(|\)$/g, "")})`
                : addParentheses(myFields[i].searchTerm);
            break;
          }
          queryString +=
            `titleKeywordsAbs=${
              (myFields[i]?.searchTerm.trim(" ").includes(" ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" or ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" and ") &&
                !myFields[i].searchTerm
                  .toLocaleLowerCase()
                  .includes(" not ")) ||
              (myFields[i].searchTerm.trim(" ").includes("(") &&
                myFields[i].searchTerm.trim(" ").includes(")"))
                ? `(${myFields[i].searchTerm.replace(/^\(|\)$/g, "")})`
                : addParentheses(myFields[i].searchTerm)
            }` +
            `${isLastField ? "" : " "}` +
            `${
              (!isLastField && myFields[myFields.length - 1].searchTerm) ||
              (myFields[myFields.length - 1].from &&
                myFields[myFields.length - 1].to) ||
              myFields[myFields.length - 1].author_firstname_fz ||
              myFields[myFields.length - 1].author_lastname
                ? myFields[i].conditionField
                : ""
            }` +
            `${isLastField ? "" : " "}`;
        }
        if (myFields[i].criteriaField === "Title") {
          queryString +=
            `title_fz=${
              (myFields[i]?.searchTerm.trim(" ").includes(" ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" or ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" and ") &&
                !myFields[i].searchTerm
                  .toLocaleLowerCase()
                  .includes(" not ")) ||
              (myFields[i].searchTerm.trim(" ").includes("(") &&
                myFields[i].searchTerm.trim(" ").includes(")"))
                ? `(${myFields[i].searchTerm.replace(/^\(|\)$/g, "")})`
                : addParentheses(myFields[i].searchTerm)
            }` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }
        if (myFields[i].criteriaField === "Title/Keyword") {
          queryString +=
            `(title_fz=${
              (myFields[i]?.searchTerm.trim(" ").includes(" ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" or ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" and ") &&
                !myFields[i].searchTerm
                  .toLocaleLowerCase()
                  .includes(" not ")) ||
              (myFields[i].searchTerm.trim(" ").includes("(") &&
                myFields[i].searchTerm.trim(" ").includes(")"))
                ? `(${myFields[i].searchTerm.replace(/^\(|\)$/g, "")})`
                : addParentheses(myFields[i].searchTerm)
            } OR keywords_fz:${
              (myFields[i]?.searchTerm.trim(" ").includes(" ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" or ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" and ") &&
                !myFields[i].searchTerm
                  .toLocaleLowerCase()
                  .includes(" not ")) ||
              (myFields[i].searchTerm.trim(" ").includes("(") &&
                myFields[i].searchTerm.trim(" ").includes(")"))
                ? `(${myFields[i].searchTerm.replace(/^\(|\)$/g, "")})`
                : addParentheses(myFields[i].searchTerm)
            })` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }
        if (myFields[i].criteriaField === "Publication Year") {
          queryString +=
            `yearfrom=[${myFields[i].from + " TO " + myFields[i].to}]` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }
        if (myFields[i].criteriaField === "Keyword only") {
          queryString +=
            `keywords_fz=${
              (myFields[i]?.searchTerm.trim(" ").includes(" ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" or ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" and ") &&
                !myFields[i].searchTerm
                  .toLocaleLowerCase()
                  .includes(" not ")) ||
              (myFields[i].searchTerm.trim(" ").includes("(") &&
                myFields[i].searchTerm.trim(" ").includes(")"))
                ? `(${myFields[i].searchTerm.replace(/^\(|\)$/g, "")})`
                : addParentheses(myFields[i].searchTerm)
            }` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }

        // @ts-ignore
        if (myFields[i].criteriaField === "Journal") {
          // @ts-ignore
          const journalArrays = fields[i]?.selectedJournal[0].split(", ");
          const result = journalArrays
            .map((journal: any) => `"${journal}"`)
            .join(" OR ");
          queryString +=
            `resource_name_tk=(${result.replace(/&/g, "and")})` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }

        if (myFields[i].criteriaField === "Author Affiliation") {
          queryString +=
            `author_address_fz=${
              (myFields[i]?.searchTerm.trim(" ").includes(" ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" or ") &&
                !myFields[i].searchTerm.toLocaleLowerCase().includes(" and ") &&
                !myFields[i].searchTerm
                  .toLocaleLowerCase()
                  .includes(" not ")) ||
              (myFields[i].searchTerm.trim(" ").includes("(") &&
                myFields[i].searchTerm.trim(" ").includes(")"))
                ? `(${myFields[i].searchTerm.replace(/^\(|\)$/g, "")})`
                : addParentheses(myFields[i].searchTerm)
            }` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }
        if (myFields[i].criteriaField === "ISSN") {
          queryString +=
            `issn="${myFields[i].searchTerm?.replace(/['"]/g, "")}"` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }
        if (myFields[i].criteriaField === "DOI") {
          queryString +=
            `articledoi="${myFields[i].searchTerm
              ?.replace(/['"]/g, "")
              .toLowerCase()}"` +
            `${isLastField ? "" : " "}` +
            `${!isLastField ? myFields[i].conditionField : ""}` +
            `${isLastField ? "" : " "}`;
        }
        if (myFields[i].criteriaField === "Author") {
          queryString +=
            // `author_all_fz=("${myFields[i].author_firstname_fz}")${
            //   myFields[i].author_lastname
            //     ? `AND author_all_fz:("${myFields[i].author_lastname}") `
            //     : ""
            // }` +
            // myFields[i].author_lastname
            //   ? `(author_all_fz=${myFields[i].author_firstname_fz}` +
            //     " AND " +
            //     `author_all_fz=${myFields[i].author_lastname})`
            //   : `(author_all_fz=${myFields[i].author_firstname_fz})` +
            //     `${isLastField ? "" : " "}` +
            //     `${!isLastField ? myFields[i].conditionField : ""}` +
            //     `${isLastField ? "" : " "}`;

            myFields[i].author_lastname && myFields[i].author_firstname_fz
              ? // ? `(author_all_fz=${myFields[i].author_firstname_fz}` +
                //   " AND " +
                //   `author_all_fz=${myFields[i].author_lastname})`
                `author_all_fz=("${myFields[i].author_lastname}, ${myFields[i].author_firstname_fz}")`
              : myFields[i].author_lastname
              ? `(author_all_fz=${myFields[i].author_lastname})` +
                `${isLastField ? "" : " "}` +
                `${!isLastField ? myFields[i].conditionField : ""}` +
                `${isLastField ? "" : " "}`
              : `(author_all_fz=${myFields[i].author_firstname_fz})` +
                `${isLastField ? "" : " "}` +
                `${!isLastField ? myFields[i].conditionField : ""}` +
                `${isLastField ? "" : " "}`;
        }
      }
      // -----------------------------------For Loop ends -----------------------------------------//
      // TO ADD THE () IN THE SEARCH IF THE USER EDITS THE SEARCH IN QUERY BUILDER
      let x = queryString.split("=");
      let b = displayQuery.split(" : ");
      let a: any = [];
      function removeTermsNotPresentInBoth(
        string1: string,
        string2: string
      ): [string, string, any] {
        // Extract terms from both strings
        var terms1 = (string1.match(/\b\w+\b/g) || []) as any;
        var terms2 = (string2.match(/\b\w+\b/g) || []) as any;

        // Identify terms present in only one of the strings
        var termsToRemove = terms1
          .filter((term: any) => !terms2.includes(term))
          .concat(terms2.filter((term: any) => !terms1.includes(term)));

        // Remove identified terms from both strings
        termsToRemove.forEach((term: any) => {
          string1 = string1.replace(
            new RegExp(`\\b${term}\\b\\s*(?:AND|OR|NOT)?\\s*`, "i"),
            ""
          );
          string2 = string2.replace(
            new RegExp(`\\b${term}\\b\\s*(?:AND|OR|NOT)?\\s*`, "i"),
            ""
          );
        });
        if (x.length == b.length || myFields.length == 1) {
          return [string1.trim(), string2.trim(), []];
        } else {
          return [string1.trim(), string2.trim(), termsToRemove];
        }
      }
      let [updatedString1, updatedString2, removedStr] =
        removeTermsNotPresentInBoth(
          x[x.length - 1].includes("keywords_fz")
            ? b[b.length - 1]
            : x[x.length - 1],
          myFields.length - 1 > 0 ? b[b.length - 1] : x[x.length - 1]
        );
      let extraStr = removedStr.map((item: string) => {
        if (
          item == "and" ||
          item == "or" ||
          item == "not" ||
          item == "AND" ||
          item == "OR" ||
          item == "NOT" ||
          item == "TO"
        ) {
          if (item == "TO") {
            return;
          }
          return item;
        } else {
          return item.trim().includes(" ")
            ? `titleKeywordsAbs=(${item})`
            : `titleKeywordsAbs=${item}`;
        }
      });
      function q(qS: string, dS: string) {
        setExtraStringQuery(extraStr.join(" "));
        let modifiedQueryString = dS
          .replace(removedStr.join(" "), "")
          .split(/\s{2}/);
        let c: any = [];
        modifiedQueryString.map((item: any, index: any) => {
          if (
            item.trim().includes("TI/KW/Abst :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let modifiedST;
            if (
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" and ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" or ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" not ")
            ) {
              modifiedST = addParentheses(modifiedQueryString[index + 1]);
            }
            // else if (modifiedQueryString[index + 1].trim().includes("(") &&
            //   modifiedQueryString[index + 1].trim().includes(")")) {
            //   modifiedST = `${modifiedQueryString[index + 1].replace(
            //     /^\(|\)$/g,
            //     ""
            //   )}`;
            // }
            // else if (
            //   modifiedQueryString[index + 1].trim().includes(" ") ||
            //   (modifiedQueryString[index + 1].trim().includes("(") ||
            //     modifiedQueryString[index + 1].trim().includes(")"))
            // ) {
            //   modifiedST = `(${modifiedQueryString[index + 1]})`;
            // }
            else {
              modifiedST = modifiedQueryString[index + 1];
            }
            let z = item.replace(
              "TI/KW/Abst :",
              `titleKeywordsAbs=${modifiedST.trim()} ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("TIKW :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let keyWordSearchTerm;
            let titleKeywordSearchTerm;
            if (
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" and ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" or ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" not ")
            ) {
              keyWordSearchTerm = addParentheses(
                modifiedQueryString[index + 1]
              );
            } else {
              keyWordSearchTerm = modifiedQueryString[index + 1];
            }
            // TITLE KEYWORD
            if (
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" and ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" or ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" not ")
            ) {
              titleKeywordSearchTerm = addParentheses(
                modifiedQueryString[index + 1]
              );
            } else {
              titleKeywordSearchTerm = modifiedQueryString[index + 1];
            }
            let z = item.replace(
              "TIKW :",
              `(title_fz=${titleKeywordSearchTerm.trim()} OR keywords_fz=${keyWordSearchTerm.trim()}) ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("KW :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let modifiedST;
            if (
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" and ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" or ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" not ")
            ) {
              modifiedST = addParentheses(modifiedQueryString[index + 1]);
            } else {
              modifiedST = modifiedQueryString[index + 1];
            }
            let z = item.replace(
              "KW :",
              `keywords_fz=${modifiedST.trim()} ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("TI :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let modifiedST;
            if (
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" and ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" or ") ||
              modifiedQueryString[index + 1]
                .toLocaleLowerCase()
                .includes(" not ")
            ) {
              modifiedST = addParentheses(modifiedQueryString[index + 1]);
            } else {
              modifiedST = modifiedQueryString[index + 1];
            }
            let z = item.replace(
              "TI :",
              `title_fz=${modifiedST.trim()} ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("Date :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let dates = modifiedQueryString[index + 1].replace(" - ", " TO ");
            let z = item.replace(
              "Date :",
              `yearfrom=[${dates.trim()}] ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("AU :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let z = item.replace(
              "AU :",
              `author_all_fz=("${modifiedQueryString[index + 1].trim()}") ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("AF :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let modifiedST;
            if (
              modifiedQueryString[index + 1].trim().includes(" ") ||
              (modifiedQueryString[index + 1].trim().includes("(") &&
                modifiedQueryString[index + 1].trim().includes(")"))
            ) {
              modifiedST = `(${modifiedQueryString[index + 1].replace(
                /^\(|\)$/g,
                ""
              )})`;
            } else {
              modifiedST = modifiedQueryString[index + 1];
            }
            let z = item.replace(
              "AF :",
              `author_address_fz=${modifiedST.trim()} ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("ISSN :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let z = item.replace(
              "ISSN :",
              `issn=${modifiedQueryString[index + 1].replace(/['"]/g, "")} ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("DOI :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let z = item.replace(
              "DOI :",
              `articledoi="${modifiedQueryString[index + 1]
                .replace(/['"]/g, "")
                .trim()
                .toLowerCase()}" ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          } else if (
            item.trim().includes("JRNL :") &&
            modifiedQueryString[index + 1] != " "
          ) {
            let jr = modifiedQueryString[index + 1]
              .split(", ")
              .map((journalListing) => `"${journalListing.trim()}"`);
            let z = item.replace(
              "JRNL :",
              `resource_name_tk=(${jr.join(" OR ").trim()}) ${
                modifiedQueryString[index + 2] !== undefined
                  ? modifiedQueryString[index + 2]
                  : ""
              }`
            );
            c.push(z);
          }
        });
        return c.join(" ");
      }

      let modQuery = q(queryString, displayQuery);
      return modQuery + extraStr.join(" ");
    } catch (err) {
      console.log(err);
      return "";
    }
  };
  function formAdvanceQueryX(): string {
    let finalQuery = ""; // Final output exact query that need to send to the api
    const userInput = displayQuery;
    let qArray = [];
    const queryKeys = [
      "TI/KW/Abst",
      "TI",
      "KW",
      "TIKW",
      "Date",
      "AU",
      "AF",
      "ISSN",
      "DOI",
      "JRNL",
    ];
    const commonOperator = ["AND", "OR", "NOT"];

    function isOperator(value: string) {
      if (!value) return false;
      if (commonOperator.includes(value.toLocaleUpperCase())) {
        return true;
      } else {
        return false;
      }
    }

    // Split the userinput string to form url query
    const splitedArray = customSplit2(userInput);

    if (!Array.isArray(splitedArray)) return ""; // Return if is not an array;

    // Loop through the splitedArray
    for (let i = 0; i < splitedArray.length; i++) {
      let flag = false;

      if (splitedArray[i]?.startsWith("(") && splitedArray[i]?.endsWith(")")) {
        let arr = customSplit(splitedArray[i]?.slice(1, -1));

        // Check any query keys availabel in the arr
        let found = false;
        for (let obj of arr) {
          if (queryKeys.includes(obj)) {
            found = true;
            break;
          }
        }
        if (found) {
          let qArr = FormAdvanceQueryTerm(arr);
          let str = `(${qArr.join(" ")})`;
          if (isOperator(splitedArray[i + 1])) {
            str += ` ${splitedArray[i + 1]}`;
          }
          qArray.push(str);
          flag = true;
        }
      }

      if (splitedArray[i] === "TI/KW/Abst") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim();
        let operator = splitedArray[i + 1];
        let term = `titleKeywordsAbs=${searchTerm}${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "TIKW") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim();
        let operator = splitedArray[i + 1];
        let term = `(title_fz=${searchTerm} OR keywords_fz=${searchTerm})${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "KW") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim();
        let operator = splitedArray[i + 1];
        let term = `keywords_fz=${searchTerm}${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "TI") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim();
        let operator = splitedArray[i + 1];
        let term = `title_fz=${searchTerm}${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "Date") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim()?.replace(/[()]/g, "");
        let operator = splitedArray[i + 1];
        let dates = searchTerm.replace(" - ", " TO ");
        const term = `yearfrom=[${dates.trim()}]${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "AU") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim();
        let operator = splitedArray[i + 1];
        const term = `author_all_fz=("${searchTerm}")${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "AF") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim();
        let operator = splitedArray[i + 1];
        let term = `author_address_fz=${searchTerm}${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "ISSN") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim()?.replace(/['"]/g, "");
        let operator = splitedArray[i + 1];
        let term = `issn=${searchTerm}${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "DOI") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim()?.replace(/['"]/g, "");
        let operator = splitedArray[i + 1];
        let term = `articledoi="${searchTerm.toLowerCase()}"${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      } else if (splitedArray[i] === "JRNL") {
        flag = true;
        let searchTerm = splitedArray[i - 1]?.trim();
        let operator = splitedArray[i + 1];

        let value = searchTerm
          .split(", ")
          .map((journalListing) => `"${journalListing.trim()}"`);
        const term = `resource_name_tk=(${value.join(" OR ").trim()})${
          isOperator(operator) ? ` ${operator}` : ""
        }`;
        qArray.push(term);
      }

      // handling the extra search term added by user
      if (
        !queryKeys.includes(splitedArray[i]) &&
        !isOperator(splitedArray[i]) &&
        !queryKeys.includes(splitedArray[i + 1]) &&
        !flag
      ) {
        let term = `titleKeywordsAbs=${splitedArray[i]}${
          isOperator(splitedArray[i + 1]) ? ` ${splitedArray[i + 1]}` : ""
        }`;
        qArray.push(term);
      }
    }
    finalQuery = qArray.join(" ");
    finalQuery = replaceBrackets(finalQuery);
    return finalQuery;
  }

  function replaceBrackets(str: string) {
    // Replace [ with (
    let replacedStr = str.replace(/\[/g, "(");

    // Replace ] with )
    replacedStr = replacedStr.replace(/\]/g, ")");

    return replacedStr;
  }

  function customSplit(str: string): any[] {
    // Split the string using a regular expression if string is enclose by [] it will be taken as one string
    let x = str.match(/\[[^\]]*\]|[^[\]\s]+/g);
    if (x) {
      return x;
    } else {
      return str.split(" ");
    }
  }

  function customSplit2(str: string) {
    // Split the string by " ". if it enclosed by [] or () will take it as one word
    return str.match(/\[[^\]]*\]|\([^\)]*\)|[^[\]\(\)\s]+/g);
  }

  function isSpecialCharacters(input: any) {
    // Define a regular expression pattern that matches any character that is not a letter or digit
    const regex = /^[^a-zA-Z0-9]+$/;

    // Use the test() method to check if the input matches the pattern
    return regex.test(input);
  }

  const formSearchTerm = (myFields: any): string => {
    try {
      const queryKeys = [
        "TI/KW/Abst :",
        "TI :",
        "KW :",
        "TIKW :",
        "Date :",
        "AU :",
        "AF :",
        "ISSN :",
        "DOI :",
        "JRNL :",
      ];

      let query = displayQuery;
      for (let obj of queryKeys) {
        query = query?.replaceAll(obj, "");
      }

      query = replaceBrackets(query);
      // -----------------------------------For Loop ends -----------------------------------------//
      return query;
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  async function checkPreferenceAdded(myPreference: GetMyPreferenceData) {
    if (!myPreference) return;

    const filteredSelectedTags = myPreference?.DataTypes.filter(
      ({ is_favorite }: any) => is_favorite
    ).map(({ datatype_id }) => datatype_id);
    if (filteredSelectedTags?.filter((ele: any) => ele !== 2)?.length === 0) {
      setIsPreference(false);
      sessionStorage.setItem("preference", "false");
    }
  }

  const handlePreference = (event: React.ChangeEvent<HTMLInputElement>) => {
    logBasicSearchUsageData(112);
    if (sessionStorage.getItem("isProfileUser") === "true") {
      let isMyPrefAdded: boolean = true;
      if (myPreference) {
        isMyPrefAdded = isPreferenceAdded(myPreference);
      }
      if (!isMyPrefAdded && event.target.checked) {
        dispatch(
          setModal({
            modalType: "PREFERENCE",
            modalProps: {
              open: true,
              checkPreferenceAdded,
            },
          })
        );
      }
      if (!event.target.checked) {
        dispatch(clearDataTypeFilters());
        // On dissabling the preference select all doc types
        let allDocIds: any = customerData?.datatypes
          .filter((x) => x.dat_resourcetype_id !== 2)
          ?.map((obj) => obj?.dat_resourcetype_id);
        setSelectedTags(() => [...allDocIds, 0]);
      }
      setIsPreference(event.target.checked);
      sessionStorage.setItem("preference", event.target.checked.toString());
    } else {
      showIsProfileToast("Please login as profile user to use the preferences");
    }
  };

  const setJournalResult = useCallback(async () => {
    let resourceList = "";

    //  @ts-ignore
    if (fields[indexOf]?.contain) {
      let key = "";
      autoSearchKeyword
        ?.split(" ")
        ?.map((ele: any) => (key += ele?.replace(/^"+|“+|"+|”$/g, "") + "\\ "));
      resourceList +=
        key?.indexOf(":\\") > -1
          ? `(resource_name_fz="${key
              .split(":\\")
              .join("/:\\")}*" OR resource_shortname_fz="${key
              .split(":\\")
              .join("/:\\")}*") AND `
          : `(resource_name_tk=${key}* OR resource_shortname_tk=${key}*)`;
    }
    // @ts-ignore
    else if (fields[indexOf]?.start) {
      let key = "";
      autoSearchKeyword
        ?.split(" ")
        ?.map((ele: any) => (key += ele?.replace(/^"+|“+|"+|”$/g, "") + "\\ "));
      resourceList +=
        key?.indexOf(":") > -1
          ? `(resource_name_fz=("*${key
              ?.substring(key?.indexOf(":") + 2)
              ?.split(" ")
              .join("\\ ")}"*)+OR+resource_shortname_fz:("*${key
              ?.substring(key?.indexOf(":") + 2)
              ?.split(" ")
              .join("\\ ")}"*)) AND`
          : `(resource_name_fz=(${key}) +OR+resource_shortname_fz:(${key})) AND `;
    }

    let tags = [...selectedTags];
    if (tags.includes(1) && !tags.includes(2)) tags.push(2);

    let encodedTags = "";
    if (selectedTags.length === 1 && selectedTags[0] === 0) {
      encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24)";
    } else {
      encodedTags = `(${temporaryQueryConstructor(tags)})`;
    }

    let encodedSubjects = "";
    if (selectedSubjects.length === 0) {
      encodedSubjects = `(${temporaryQueryConstructor(
        subjectData?.map((x) => x.datsubjectmasterlevel2_id)
      )})`;
    } else {
      encodedSubjects = `(${temporaryQueryConstructor(selectedSubjects)})`;
    }

    resourceList = `&${resourceList.slice(0, -6)})`;
    let apiJournalData: any = {
      // @ts-ignore
      page: fields[indexOf]?.pageIndex || 1,
      rows: 15,
      profileuserid: userId,
      sessionid: session_key,
      facet_fields:
        "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,resource_name_initial",

      fq__active_flag: true,
      sort: "resource_name_tk asc",
      // json_facet:JSON.stringify({"resource_name_tk":{"type":"terms","field":"resource_name_tk","limit":15,"sort":"index asc"}}),
      fl: "resource_name",
      op_mode: "and",
      fq__fulltext_ACL: "(0)",
    };
    if (selectedTagPublisher !== "" && selectedTagPublisher !== "0-9") {
      Object.assign(apiJournalData, {
        fq__resource_name_initial: selectedTagPublisher,
      });
    }

    if (encodedSubjects.length > 3) {
      Object.assign(apiJournalData, {
        fq__subjects_id_l2: encodedSubjects,
      });
    }

    if (encodedTags.length > 2) {
      Object.assign(apiJournalData, {
        fq__resource_type: encodedTags,
      });
    }

    let response;
    if (autoSearchKeyword.length > 0) {
      response = await getListOfJournal(
        "resourcecore?" + objectToQueryString(apiJournalData) + resourceList
      );
    } else {
      response = await getListOfJournal(
        "resourcecore?" + objectToQueryString(apiJournalData)
      );
    }
    setJournalData(response?.data.data.docs);
  }, [
    autoSearchKeyword,
    fields,
    indexOf,
    selectedTagPublisher,
    session_key,
    userId,
  ]);

  useEffect(() => {
    setJournalResult();
  }, [selectedTagPublisher, pageIndex, setJournalResult]);

  const onPaginate = (val: any) => {
    // @ts-ignore
    if (val == "-1" && fields[indexOf].pageIndex !== 0) {
      // @ts-ignore
      fields[indexOf].pageIndex = fields[indexOf].pageIndex - 1;
    } else {
      // @ts-ignore
      fields[indexOf].pageIndex = fields[indexOf].pageIndex + 1;
    }
    // @ts-ignore
    setpageIndex(fields[indexOf].pageIndex);
  };

  async function linkToDisplay(dataArray: any) {
    if (!Array.isArray(dataArray)) return;
    let formData = new FormData();

    let data = dataArray?.map((obj: any) => {
      let object: any = {
        customer_id: customerData?.informaticscustomer_id,
        article_id: obj?.article_id,
        consortia_virtualid: customerData?.consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      return object;
    });

    let formApiData = {
      data,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      dispatch(updateLinkToDisplay(response?.data));
    }
  }

  useEffect(() => {
    selectAllTags();
  }, [selectedTags.length]);

  useEffect(() => {
    if (
      profileData1?.product_type === "1" ||
      profileData1?.product_type === "1,7"
    ) {
      dispatch(updateCollectionValue("J-GateCollection"));
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(true));
    } else if (profileData1?.product_type === "7") {
      dispatch(updateCollectionValue("ConsortiaSubscriptions"));
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
    }
  }, []);
  function selectAllTags() {
    let allDocIds: any = customerData?.datatypes
      .filter((x) => x.dat_resourcetype_id !== 2)
      ?.map((obj) => obj?.dat_resourcetype_id);

    let selectedDocs = selectedTags.filter((str) => str !== 2);

    if (allDocIds?.length === selectedDocs.length) {
      setSelectedTags((prev) => [...prev, 0]);
      sessionStorage.setItem("preference", "false");
      setIsPreference(false);
    }
  }

  function openTutorialLink() {
    //window.open("https://youtu.be/H2oyJDz4Bew", "_blank");
    window.open("https://youtu.be/eBJvYSs_Gao", "_blank");
  }

  return (
    <Box className="search-builder-container">
      <HeaderSection isAdvanceSearch />

      <Stack
        direction={"row"}
        spacing={10}
        alignItems="center"
        paddingTop={{ xs: "2rem" }}
        sx={{ pl: "66px" }}
      >
        <Text variant="h5" fontWeight={"600"}>
          Search Builder
        </Text>
        <Stack direction={"row"} spacing={1} alignItems="center">
          <FormControlLabel
            control={
              <Switch
                sx={{ m: 1 }}
                checked={isPreference}
                onChange={handlePreference}
                inputProps={{ "aria-label": "Preferences Switch" }}
              />
            }
            label="Preferences"
            labelPlacement="start"
          />
          <Stack>
            <CustomTooltip
              title={
                <>
                  <span style={{ fontSize: "14px" }}>
                    Enable & Configure the preferences for Subjects & Document
                    types for customized search.
                  </span>
                  <br />
                  <span style={{ fontSize: "14px" }}>
                    Click on "Preferences" from Profile dropdown for settings
                  </span>
                </>
              }
              arrow
              placement="top"
            >
              <IconButton size="medium">
                <InfoOutlinedIcon fontSize="inherit" />
              </IconButton>
            </CustomTooltip>
          </Stack>
        </Stack>

        <Link
          style={{
            cursor: "pointer",
            color: "#e35c19",
            textDecorationColor: "#e35c19",
          }}
          onClick={openTutorialLink}
        >
          Take a Quick Tour of Search Builder
        </Link>
      </Stack>

      {/* Tags */}
      <Stack
        direction={"row"}
        spacing={1.2}
        marginTop={1}
        flexWrap="wrap"
        sx={{ pl: "66px" }}
      >
        {allTags?.map((data) => (
          <Chip
            key={data.dat_resourcetype_id}
            label={
              data.accesstype === dataTypes.journals
                ? "Journal Articles"
                : data?.accesstype
            }
            color="primary"
            variant={"outlined"}
            onClick={() => handleTags(data.dat_resourcetype_id)}
            sx={{
              p: "4px",
              borderRadius: "6px",
              border: "1px solid",
              borderColor: selectedTags.includes(data.dat_resourcetype_id)
                ? "primary.main"
                : "#DCD2EF",
              background: selectedTags.includes(data.dat_resourcetype_id)
                ? "#DCD2EF"
                : "#FFF",
              color: selectedTags.includes(data.dat_resourcetype_id)
                ? "primary.main"
                : "#71717A",
              textTransform: "capitalize",
              "&:hover": {
                background: "#DCD2EF",
              },
            }}
          />
        ))}
      </Stack>

      {/* Query Builder */}
      {fields?.map((data, i) => (
        <QueryBuilder
          key={data.id}
          setJournalResult={setJournalResult}
          setJournal={setJournal}
          index={i}
          append={append}
          remove={remove}
          qbField={fields}
          control={control}
          fields={fields}
          setIndexOf={setIndexOf}
          pageIndex={pageIndex}
          handleSubmit={handleSubmit(handleFormSubmit)}
          selectedTags={selectedTags}
          setCheckDoi={setCheckDoi}
          checkDoi={checkDoi}
        />
      ))}

      {/* Query Editor */}
      <Box my={4} sx={{ pl: "66px" }}>
        <Text variant="h5" fontWeight={"600"}>
          Query Editor
        </Text>
        <Box sx={sx.queryEditor}>
          <Box>
            <Text fontWeight={"500"}>Query</Text>
            <TextField
              fullWidth
              value={displayQuery}
              onChange={(e) => setDisplayQuery(`${e.target.value}`)}
              sx={{
                width: { xs: "300px", sm: "600px", md: "600px", lg: "1000px" },
                fontWeight: 600,
                backgroundColor: "#FFF",
              }}
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleSubmit(handleFormSubmit)}
            sx={{ height: "51px", width: "130px", marginY: "1.5rem" }}
            disabled={!selectedTags.length || !displayQuery}
          >
            {loading ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#fff",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              "Search"
            )}
          </Button>
        </Box>
      </Box>

      {/* Journal Modal */}
      {fields?.map((_: any, i: number) => (
        <JournalPopUp
          key={i}
          setValue={setValue}
          journalData={journalData}
          handleCloseJournalData={handleCloseJournalData}
          onJournal={onJournal}
          selectedLetterTagsPublisher={selectedLetterTagsPublisher}
          updateSelectedTagsPublisher={updateSelectedTagsPublisher}
          onChange={onChange}
          indexOf={indexOf}
          setSelectedJournal={setSelectedJournal}
          selectedJournal={selectedJournal}
          fields={fields}
          setJournal={setJournal}
          setAutoSearchKeyword={setAutoSearchKeyword}
          setAutoSearch={setAutoSearch}
          autoSearch={autoSearch}
          setJournalResult={setJournalResult}
          onPaginate={onPaginate}
          setOnChange={setOnChange}
        />
      ))}
    </Box>
  );
};

export default AdvancedSearch;
