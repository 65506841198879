import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { notify } from "../../../utils/Notify";
import { checkMultipleArticleExistence, removeArticleFromFolder } from "../../../api/MyLibrary/MyFolders.api";
import { hideModal } from "../../../store/slice/modalSlice";
import { useDispatch } from "react-redux";
import { onSearch } from "../../../screens/Search/onSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { onSearch as search } from "../../../screens/Search/onSearch";
import { useLocation } from "react-router-dom";
import { onAuthorResult } from "../../../screens/AuthorFinder/onAuthorResult";
import { SaverArticlesType } from "../../../types/interface";
import { updateSavedArticles } from "../../../store/slice/searchSlice";
import { queryClient } from "../../../App";

interface PropType {
  isOpen: boolean;
  article_id: string;
  folderId: string;
  setJournalResults?: any;
}

export default function RemoveArticle(props: PropType) {
  const { isOpen, article_id, folderId, setJournalResults } = props;
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const isAuthorSearch = useAppSelector(state => state.authorReducer.isAuthorResult);
  const isBrowseJournalSearch = useAppSelector(state => state.browseJournalSearch.isBrowseJournalSearch);
  let userId = useAppSelector((state) => state.login.user_id);
  let reloadFolder = useAppSelector((state) => state.saveSearchHistory.folder);

  const location = window.location

  async function removeArticle() {
    try {
      if (!article_id) return;
      if (!folderId) return;
      const formData = new FormData();
      formData.append("articleId", article_id);
      formData.append("folderid", folderId?.toString());

      const res = await removeArticleFromFolder(formData);
      notify("success", res[0][0]);
      if(location?.pathname === "/authorfindersearch" || isAuthorSearch) {
        appDispatch(onAuthorResult())
      }
      if (location?.pathname !== "/BrowseJournalTableSearch/BrowseJournalSearch" && location.pathname !== "/authorfindersearch" && !isAuthorSearch && !isBrowseJournalSearch) {
        appDispatch(search());
      }
      if (location?.pathname == "/BrowseJournalTableSearch/BrowseJournalSearch" || isBrowseJournalSearch) {
        setJournalResults && setJournalResults();
        checkArticleExistanceInFolder(article_id)
      }
      if(location?.pathname == `/my-library/folders/${reloadFolder.folderId}`){
    setTimeout(() => {
      queryClient.invalidateQueries(["GetFolderArticles"]);
    },2000)
      }
      dispatch(hideModal());
    } catch (err) {
      notify("error", "Something went wrong!");
      dispatch(hideModal());
    }
  }

  async function checkArticleExistanceInFolder(id: string) {
    const articleData = new FormData();

    const articleIds = id;
      
    articleData.append("profile_id", userId);
    articleData.append("article_ids", articleIds.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateSavedArticles(filter_is_saved));
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(hideModal())}
      maxWidth={false}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minWidth: { xs: "100%", sm: "400px", md: "550px" },
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            Remove Article
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent sx={{ mt: "30px" }}>
        <Typography>
          Document already added to folder, Do you want to remove it from the
          folder?
        </Typography>

        <Stack direction={"row"} gap={1} mt={"20px"}>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            onClick={removeArticle}
          >
            Remove
          </Button>
          <Button
            sx={{ minWidth: 150, color: "#111928" }}
            onClick={() => dispatch(hideModal())}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
