import React, { useState } from "react";
import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Colors } from "../../utils/constants";
import { MathJax } from "better-react-mathjax";
import highlightKeyword from "../../utils/highlightKeyword";
import RightDrawerOfBook from "../../Book/rightDrawerBook";

const BookBlocks: React.FC<any> = ({ item }) => {
  const [dialogueData, setDialogueData] = useState({});
  const [top, setTop] = useState(false);

  const handleDivClick = () => {
    const url = item?.bookurl;
    if (url) {
      const newTab = window.open("", "_blank");
      if (newTab) {
        newTab.location.href = url;
      } else {
        console.error("Unable to open a new tab");
      }
    }
  };

  const handlerightDrawer = (data: any) => {
    setTop(!top);
    setDialogueData(data);
  };

  return (
    <Box sx={webStyles.container}>
      <Box sx={webStyles.flexBox} onClick={() => handlerightDrawer(item)}>
        <Box style={{ width: "90%" }}>
          <Typography
            gutterBottom
            sx={[
              webStyles.text,
              { fontSize: "1.3rem", fontFamily: "Lora", fontWeight: 600 },
            ]}
            // onClick={() => handlerightDrawer(item)}
          >
            <MathJax>
              <div
                id="title"
                dangerouslySetInnerHTML={{
                  __html: highlightKeyword(item.resource_name, []),
                }}
              />
            </MathJax>
          </Typography>
          <Box style={{ display: "flex" }}>
            {item.authors && (
              <Box sx={[webStyles.flexBox]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {/* {item.authors.join(" | ")} */}
                  {item.authors.map((author: any, index: any) => (
                    <React.Fragment key={index}>
                      {index > 0 && <span style={{ color: "gray" }}> | </span>}
                      {author}
                    </React.Fragment>
                  ))}
                </Typography>
                &nbsp; &nbsp;
              </Box>
            )}
          </Box>

          <Box style={{ display: "flex" }}>
            {item.publisher_name && (
              <Box sx={[webStyles.subText]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {`${item.publisher_name}`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box style={{ display: "flex" }}>
            {item.publication_date && (
              <Box sx={[webStyles.subText]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {`Publication Date: ${item.publication_date}`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box style={{ display: "flex" }}>
            {item.publication_place && (
              <Box sx={[webStyles.subText]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {`Publication  Place:  ${item.publication_place}`}&nbsp;
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box onClick={handleDivClick}>
          {<LockOpenIcon color="secondary" href={item?.bookurl} />}
        </Box>
      </Box>

      <RightDrawerOfBook
        dialogueData={dialogueData}
        handleDawer={handlerightDrawer}
        state={top}
      />
    </Box>
  );
};

export default BookBlocks;

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    // margin: "0.5%",
  },
  container: {
    backgroundColor: Colors.white,
    padding: "2%",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "red",
    marginTop: "1%",
    cursor: "pointer",
  },
  text: {
    fontFamile: "Lora",
  },
  subText: {
    color: Colors.gray_600,
    fontFamily: "Helvetica Neue",
    fontSize: "0.8rem",
  },
  gridStyle: {
    position: "absolute",
    width: "330px",
    // height: "118px",
    right: "1px",
    top: "5px",
    marginTop: "50px",
    background: "#FFFFFF",
    border: "1px dashed rgba(33, 33, 33, 0.1)",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    dispay: "flex",
    justifyContent: "flex-end",
    zIndex: "100000",
  },
};
