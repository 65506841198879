import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { loginSuccess } from "../../store/slice/loginSlice";
import { notify } from "../../utils/Notify";
import { clearDataTypeFilters, reset } from "../../store/slice/searchSlice";
import { clearCustomerDetails } from "../../store/slice/customerDetails";
import { usagelogApi } from "../../api/usageReportApi";

interface objType {
  ip_type: string;
  domainname: string;
  customername: string;
  subscription_status: number;
  informaticscustomer_id: number;
  user_id: number;
  session_key: string;
}
interface PropType {
  open: boolean;
  userArray: objType[];
}

export default function SelectUserModal(props: PropType) {
  let { open, userArray } = props;
  const [selectedUser, setSelectedUser] = useState<objType | null>(null);
  const dispatch = useAppDispatch();
  const [ipAddress, setIpAddress] = useState<string>("");

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data?.ip);
        sessionStorage.setItem("user_ipv4_address", data?.ip);
      } catch (error) {
        setIpAddress("0");
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const logIPBasedUsageData = (usageActionID: any, profileData?: any) => {
    usagelogApi(
      profileData?.user_id,
      profileData?.informaticscustomer_id,
      null,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      ipAddress,
      null,
      profileData?.session_key,
      1,
      null
    );
  };

  function handleDropDown(data: objType) {
    setSelectedUser(data);
  }

  function handleLogin() {
    if (!selectedUser?.informaticscustomer_id) return;
    if (selectedUser.subscription_status === 0) {
      notify("warning", "Subscription is Expired");
      return;
    }

    dispatch(clearCustomerDetails());
    dispatch(reset());
    sessionStorage.clear();
    sessionStorage.setItem(
      "informaticscustomer_id",
      selectedUser?.informaticscustomer_id?.toString()
    );
    sessionStorage.setItem("isProfileUser", "false");
    sessionStorage.setItem("isIPLogin", "true");
    sessionStorage.setItem("user_id", selectedUser?.user_id?.toString());
    sessionStorage.setItem("session_key", selectedUser?.session_key);
    dispatch(
      loginSuccess({
        informaticscustomer_id: selectedUser?.informaticscustomer_id,
        user_id: selectedUser?.user_id,
        session_key: selectedUser?.session_key,
      })
    );
    logIPBasedUsageData(6, selectedUser);
    window.location.reload();
  }

  return (
    <Dialog open={open}>
      {/* ------------------------Title --------------------------------------- */}
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
          IP Login
        </Typography>
        <IconButton onClick={() => dispatch(hideModal())}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/*----------------------------- Content------------------------------------- */}
      <DialogContent
        sx={{ width: { sx: "100%", sm: "100%", md: "400px", lg: "500px" } }}
      >
        <Typography fontSize={"14px"}>
          Select Your Institution below to access J-Gate
        </Typography>
        <Stack gap={1}>
          <Select
            fullWidth
            value={selectedUser ? selectedUser.informaticscustomer_id : ""}
            sx={{ backgroundColor: "#fff" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: "600px" } } }}
          >
            {Array.isArray(userArray) &&
              userArray?.length &&
              userArray?.map((obj) => (
                <MenuItem
                  key={obj?.informaticscustomer_id}
                  value={obj?.informaticscustomer_id}
                  onClick={() => handleDropDown(obj)}
                >
                  <Typography
                    sx={{
                      width: {
                        sx: "100%",
                        sm: "100%",
                        md: "250px",
                        lg: "400px",
                      },
                    }}
                  >
                    {obj?.customername}
                  </Typography>
                </MenuItem>
              ))}
          </Select>

          <Button variant="contained" size="large" onClick={handleLogin}>
            Login
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
