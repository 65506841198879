import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { Colors } from "../../utils/constants";
import Pagination from "../../components/pagination";

import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import SortByRelevenceForBrowser from "../../components/basicSearchTopBar/sortByRelevenceBrowser";
import BrowserJournalBlocks from "../../components/browserJournalblocks/browserJournalblocks";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAlertDetails,
  getListOfJournal,
  journalLock,
} from "../../api/browseJournal";
import objectToQueryString from "./objToQuery";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { accessTypeButtons } from "../../utils/helper";
import {
  alertsAndFav,
  bookSearchResult,
  browseJournalSearchResult,
  updateJournalLockDisplay,
  updateknownAlphasearch,
} from "../../store/slice/browseJournal";
import { goToTop } from "../../utils/helper";
import { ReducerObj } from "../../types/interface";
import {
  updateBrowserPage,
  updateBrowserRow,
  updateCurrentUpdate,
  updatecollection,
} from "../../store/slice/browseFilterSlice";

import { clearAllFilters } from "../../store/slice/browseFilterSlice";
import {
  LinkResolverType,
  getBulkLinkRecords,
} from "../../api/linkResolverApi";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useAppSelector } from "../../store/hooks";
import Spinner from "../../components/spinner";
import { updateLoadingState } from "../../store/slice/filterSlice";
import { setModal } from "../../store/slice/modalSlice";
import NoResultModal from "../../components/modals/NoResultFound";
import SJRModal from "../../components/rightDrawer/SJRModal";
import { updateSort } from "../../store/slice/browseFilterSlice";
import { updateDataType } from "../../store/slice/searchSlice";
//import BrowserJournalBlocks from "../../components/browserJournalblocks/browserJournalblocks";
import BookBlocks from "../../components/browserJournalblocks/bookblock";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Books = ({
  handleEnter,
  setHandleEnter,
  searchContainOrStartWith,
  // keyWords,
  atoz,
  setatoz,
  selectFilters,
  filters,
  setSelectFilters,
  selectedTag,
  myPersonalLibrary,
  collectionFilter,
  setMyPersonalLibrary,
}: any) => {
  const dispatch = useDispatch();
  let userId = sessionStorage.getItem("user_id");
  let session_key = sessionStorage.getItem("session_key");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userData, setUserData] = useState<any>({});

  const [selectedListTags, setselectedListTags] = useState<any[]>([]);
  const [journalSearchResults, setJournalSearchResults] = useState<any[]>([]);
  const [AllNoOfResults, setAllNoOfResults] = useState(0);
  const [loading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState<any[]>([]);
  const [renderFlag, setRenderFlag] = useState(true);
  const [facetFlag, setFacetFlag] = useState(false);
  const [facetFlag1, setFacetFlag1] = useState(false);
  const [facetFlag2, setFacetFlag2] = useState(false);
  const [selectedRankSort, setSort] = useState("Journal");
  const isMounted = useRef(false);
  const [message, setMessage] = useState({ messages: "", open: false });
  const [noresultModal, setNoResultModal] = useState(false);

  const [sortBy, setSortBy] = useState(false);
  // const [sort, setSort] = useState("");

  const [jsonFacet, setJsonFacet] = useState<any>({});

  let selectedLetterTags = Array(28).fill(false);

  const [selectedTags, setSelectedTags] =
    useState<boolean[]>(selectedLetterTags);

  const consortiamasterId = useSelector(
    (state: any) => state?.customer?.customerDetails?.consortiamaster_id
  );

  const [selectedTab, setSelectedTab] = useState("all");

  const [apipage, setApiPage] = useState(1);
  const independentFilter = useSelector(
    (state: any) => state.browseFilterSlice.currentUpdate
  );

  const searchReducer: any = useSelector((state: any) => state.searchReducer);
  const setAlphabet: any = useSelector(
    (state: any) => state.browseFilterSlice.selectAlphabet
  );

  const loginDetails: any = useSelector((state: any) => state.login);

  const rows = useSelector((state: any) => state.browseFilterSlice.rows);
  let currentPage = useSelector((state: any) => state.browseFilterSlice?.page);
  const customerId = useSelector(
    (state: any) => state?.customer?.customerDetails?.informaticscustomer_id
  );
  const consortia_virtualid = useAppSelector(
    (state) => state.customer.customerDetails?.consortia_virtualid
  );

  const profileStatus = sessionStorage.getItem("isProfileUser");

  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  // useEffect(() => {
  //   showLoginModa();
  // }, [profileStatus]);

  useEffect(() => {
    showLoginModa();
  }, [customerData?.customer_id]);

  function showLoginModa() {
    if (!customerData?.allow_profile) return;
    if (sessionStorage.getItem("isLoginModalShowed") === "true") return;
    // if (profileStatus === "false" || customerData?.customer_id === 0) {
    if (customerData?.customer_id === 0) {
      dispatch(
        setModal({
          modalType: "SHOW_LOGIN",
          modalProps: {
            open: true,
            isProfileUser: profileStatus,
          },
        })
      );
      sessionStorage.setItem("isLoginModalShowed", "true");
    }
  }

  useEffect(() => {
    setUserData(searchReducer);
    return () => {
      dispatch(updateCurrentUpdate(false));
    };
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      messages: "",
      open: false,
    });
  };

  const searchResult = useSelector(
    (state: any) => state.browseJournalSearch.bookResult?.docs
  );

  const searchResults = useSelector(
    (state: any) => state.browseJournalSearch.bookResult
  );

  let count = Math.ceil(searchResults?.hits / rows);
  const isLoading = useAppSelector((state) => state.filterReducer.loading);

  function handlePageChange(p: any) {
    dispatch(updateBrowserPage(p));
    // setPage(p);
    isMounted.current = true;
    goToTop();
  }

  function getRowsPerPage(value: number) {
    isMounted.current = true;
    dispatch(updateBrowserRow(value));
    // setRow(value);
  }

  const keyWords = useSelector(
    (state: any) => state.browseJournalSearch.searchKeyword
  );

  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);

  const setBookResults = async () => {
    dispatch(updateLoadingState(true));

    let subjects = "";
    let dataTypes = "";
    if (searchReducer.subjectType?.length > 0) {
      searchReducer.subjectType?.map((element: any, index: any) => {
        subjects += `${element} OR `;
      });
      subjects = `(${subjects.slice(0, -3)})`;
    }

    if (searchReducer?.defaultDataType?.length > 0) {
      searchReducer?.defaultDataType?.map((element: any) => {
        dataTypes += `${element} OR `;
      });

      dataTypes = `(${dataTypes.slice(0, -3)})`;
    }
    let temp: String[] = [];
    let resourceList = "";

    keyWords?.map((element: any) => {
      let key = "";
      let strLen = element.words?.split(" ");
      element.words?.split(" ")?.map((ele: any, indexs: number) => {
        console.log("strLen.length", strLen?.length, indexs);
        strLen?.length - 1 !== indexs
          ? (key += ele?.replace(/^\"+|\“+|\"+|\”$/g, "") + "\\ ")
          : (key += ele);
      });
      if (element.searchType === "Starting With") {
        resourceList +=
          key?.indexOf(":\\") > -1
            ? `fq__resource_name_tk=${key.split(":\\").join("\\:\\")}* AND `
            : `fq__resource_name_tk=${key}* AND `;
      } else if (element.searchType == "Containing") {
        resourceList +=
          key?.indexOf(":") > -1
            ? `fq__resource_name_tk="*${key
                ?.substring(key?.indexOf(":") + 2)
                ?.split(" ")
                .join("\\ ")}"* AND`
            : `fq__resource_name_tk=*${key}* AND `;
      }
    });
    resourceList = `&${resourceList.slice(0, -5)}`;

    if (keyWords?.length == 0) resourceList = "";
    setselectedListTags([...temp, ...keywords]);

    let apiJournalData: any = {
      page: currentPage,
      rows: rows,
      profileuserid: userId,
      sessionid: session_key,
      //   facet_fields:
      //     "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,resource_name_initial",
      facet_fields: "publisher_name,subjects_name_l3,resource_name_initial",

      fq__active_flag: true,
      fq__resource_type: 12,
    };
    if (independentFilter)
      Object.assign(apiJournalData, {
        fq__dateofpublication: "[NOW/YEAR TO NOW/YEAR]",
        fq__createddate: "[NOW-180DAYS TO NOW]",
      });
    if (subjects?.length > 4)
      Object.assign(apiJournalData, { fq__subjects_id_l2: subjects });
    // if (dataTypes.length > 4)
    //   Object.assign(apiJournalData, { fq__resource_type: 12 });
    if (keyWords[keyWords?.length - 1]?.searchType === "Starting With") {
      Object.assign(apiJournalData, { sort: "resource_name_tk asc" });
    }
    if (selectedRankSort == "Journal" || selectedRankSort == "") {
      Object.assign(apiJournalData, { sort: "resource_name_tk asc" });
    }
    if (selectedRankSort !== "" && selectedRankSort !== "Journal")
      Object.assign(apiJournalData, { sort: selectedRankSort });
    if (setAlphabet !== "" && setAlphabet !== "0-9")
      Object.assign(apiJournalData, { fq__resource_name_initial: setAlphabet });

    if (
      selectedFilters.subject?.length > 0 &&
      !filters.includes("subjects_name_l3")
    )
      Object.assign(apiJournalData, {
        subjects_name_l3: encodeURIComponent(
          '("' + selectedFilters.subject.join('" OR "') + '")'
        ),
      });

    if (
      selectedFilters.journalRank.length > 0 &&
      !filters.includes("filter_metrix")
    )
      Object.assign(apiJournalData, {
        filter_metrix: encodeURIComponent(
          '("' + selectedFilters.journalRank.join('" OR "') + '")'
        ),
      });

    if (
      selectedFilters.countryOfPublication.length > 0 &&
      !filters.includes("primary_publisher_country")
    )
      Object.assign(apiJournalData, {
        primary_publisher_country: encodeURIComponent(
          '("' + selectedFilters.countryOfPublication.join('" OR "') + '")'
        ),
      });
    if (
      selectedFilters.sourceType.length > 0 &&
      !filters.includes("fq__resource_source_index")
    )
      Object.assign(apiJournalData, {
        fq__resource_source_index: encodeURIComponent(
          '("' + selectedFilters.sourceType.join('" OR "') + '")'
        ),
      });

    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      apiJournalData.fq__acl_group = customerData.consortia_filter;
    }

    let apiResults: any[] = [...journalSearchResults];
    if (resourceList.length <= 3) resourceList = "";
    let response;
    if (myPersonalLibrary) {
      Object.assign(apiJournalData, {
        fq__profileuser_id: userId,
        fq__is_favourite: true,
      });
      response = await getListOfJournal(
        "personalresourcecore?" +
          objectToQueryString(apiJournalData) +
          resourceList +
          filters
      );
      console.log("response_1");
    } else {
      response = await getListOfJournal(
        "bookscore?" +
          objectToQueryString(apiJournalData) +
          resourceList +
          filters
      );
      console.log("response_3");
    }
    if (
      filters.includes("&fq__fulltext_ACL=") &&
      response?.data.data.docs.length === 0
    ) {
      dispatch(updateLoadingState(false));

      setMessage({
        messages:
          "There are no configured subjects nor subscription information for your library . Please contact your library administrator.",
        open: true,
      });
      return;
    }
    // dispatch(browseJournalSearchResult(response?.data.data));bookSearchResult
    dispatch(bookSearchResult(response?.data.data));
    if (response?.data?.data?.hits === 0) {
      setNoResultModal(true);
    }

    let enabledTagsList: any[] = ["ALL", "(0-9)"];
    if (setAlphabet === "") {
      response?.data.data.facets.facet_fields?.resource_name_initial?.map(
        (ele: any) =>
          Object.keys(ele)[0]?.charAt(0)?.toUpperCase().match(/[a-z]/i)
            ? enabledTagsList.push(
                Object.keys(ele)[0]?.charAt(0)?.toUpperCase()
              )
            : null
      );
      dispatch(updateknownAlphasearch(enabledTagsList));
    }

    if (response?.data.data.docs.length == 0) setRenderFlag(false);
    // setApiPage(apipage + 1);
    setJsonFacet(response?.data.data.facets.facet_fields);
    apiResults = [...response?.data.data.docs];
    setAllNoOfResults(response?.data.data.hits);
    // let apiJson: any = {
    //   usermaster_id: loginDetails.user_id || userId,
    //   resourcemaster_ids: [],
    // };
    // if (response?.data.data.docs.length > 0) {
    //   response?.data.data.docs?.map((element: any, index: any) => {
    //     apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.concat(
    //       element.resourcemaster_id
    //     );
    //   });
    // }

    // apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.toString();
    // let alertRes = await getAlertDetails("?" + objectToQueryString(apiJson));

    // dispatch(alertsAndFav(alertRes?.data.data[0]));

    // Lock Journal
    // let apiLock: any = {
    //   customer_id:
    //     customerId || sessionStorage.getItem("informaticscustomer_id"),
    //   resource_id: [],
    //   access_type: 1,
    // };
    // if (consortiamasterId !== null && consortiamasterId !== undefined)
    //   Object.assign(apiLock, { consortia_id: consortiamasterId });
    // // consortiamasterId
    // if (response?.data.data.docs.length > 0) {
    //   response?.data.data.docs?.map((element: any, index: any) => {
    //     apiLock.resource_id = apiLock.resource_id.concat(
    //       element.resourcemaster_id
    //     );
    //   });
    // }
    // apiLock.resource_id = apiLock.resource_id.toString();

    // if (selectedFilters?.collectionsValueJournal === "My Library Collections") {
    //   Object.assign(apiLock, { context_id: 2 });
    // }

    // if (selectedFilters?.collectionsValueJournal === "Consortia Collections") {
    //   Object.assign(apiLock, { context_id: 3 });
    // }

    // // let journalLockRes = await journalLock("?" + objectToQueryString(apiLock));
    // // dispatch(updateJournalLockDisplay(journalLockRes));
    dispatch(updateLoadingState(false));
  };

  async function closeNoResultModal() {
    setMyPersonalLibrary(false);
    dispatch(updatecollection(""));
    dispatch(clearAllFilters());
    setNoResultModal(false);
  }

  useEffect(() => {
    setBookResults();
  }, [
    filters,
    currentPage,
    setAlphabet,
    keyWords,
    keywords,
    selectedTab,
    myPersonalLibrary,
    facetFlag,
    selectedRankSort,
    facetFlag1,
    facetFlag2,
    rows,
    independentFilter,
  ]);

  useEffect(() => {
    dispatch(updateDataType(searchReducer?.defaultDataType));
  }, []);

  useEffect(() => {
    if (handleEnter) {
      setBookResults();
    }
    setHandleEnter(false);
  }, [handleEnter]);

  useEffect(() => {
    setRenderFlag(true);
  }, [keyWords, keywords, selectedTab, myPersonalLibrary]);

  useEffect(() => {
    setApiPage(1);
    setJournalSearchResults([]);
    setFacetFlag1(true);
  }, [selectedRankSort]);

  useEffect(() => {
    setRenderFlag(true);
  }, [facetFlag1]);

  useEffect(() => {
    setApiPage(1);
    setJournalSearchResults([]);
    setFacetFlag2(!facetFlag2);
  }, [selectedTags]);

  useEffect(() => {
    setRenderFlag(true);
    return () => {
      dispatch(updateSort(""));
    };
  }, [facetFlag2]);

  return (
    <Box>
      <Box bgcolor={"#f5f5f5"}>
        <Box
          sx={[
            webStyle.flexBox,
            { marginTop: "1%", justifyContent: "flex-end" },
          ]}
        >
          {/* <SortByRelevenceForBrowser
            selectedRankSort={selectedRankSort}
            setSort={setSort}
            sortData={searchResults?.facets?.facet_fields?.filter_metrix}
          ></SortByRelevenceForBrowser> */}
        </Box>
        <Grid container>
          <Grid item xs={12}>
            {/* {lock?.length == searchResult?.length && */}
            {searchResult?.map((item: any) => {
              return <BookBlocks item={item}></BookBlocks>;
            })}
          </Grid>
        </Grid>

        <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            count={count}
            handlePageChange={handlePageChange}
            rowsPerResult={rows}
            getRowsPerPage={getRowsPerPage}
            currentPage={currentPage}
          />
          {/* <Spinner isLoading={isLoading} /> */}
        </Box>
      </Box>

      <Snackbar
        open={message.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      ></Snackbar>
      <NoResultModal isOpen={noresultModal} handleClose={closeNoResultModal} />
      <Spinner isLoading={isLoading} />
    </Box>
  );
};

export default Books;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    fontSize: 24,
    color: Colors.black,
  },
  button: {
    margin: "0.5%",
    paddingLeft: "3%",
    paddingRight: "3%",
  },
};
