import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Divider,
  Typography,
  Grid,
  Stack,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Colors } from "../utils/constants";
import DropDownComponent from "../components/browserJournalblocks/dropDownComponent";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  browseJournalSearchResult,
  setSearchKeyword,
  updateBrowseJournalSearch,
} from "../store/slice/browseJournal";

import {
  clearAllFilters,
  updateBrowserPage,
  updateBrowserRow,
  updateselectAlphabet,
} from "../store/slice/browseFilterSlice";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { alertsAndFav, setKeyword } from "../store/slice/browseJournal";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import AlphabeticalFilterForAuthor from "../components/alphabeticalFilter/AlphabeticalFilterForAuthor";
import SearchFilterComponentOfBook from "../components/leftBarComponent/serachFilterOFBook";
import Books from "../screens/browseJournal/books";
import { useAppSelector } from "../store/hooks";
import { booksAutoSuggestion } from "../api/browseJournal";
import objectToQueryString from "../screens/browseJournal/objToQuery";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { goToTop } from "../utils/helper";

export const BookSearch: React.FC = () => {
  const conditions = ["Starting With", "Containing"];
  const [autoSearch, setAutoSearch] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(true); // To control the opening and closing of Autocomplete
  const [keyWord, setKeyWord] = React.useState("");
  const [keyWords, setKeyWords] = React.useState<any[]>([]);
  const [handleEnter, setHandleEnter] = React.useState(false);
  const [message, setMessage] = useState({ messages: "", open: false });
  const [selectedLetterTags, setSelectedLetterTags] = React.useState(
    Array(28).fill(false)
  );
  const [selectedTag, setSelectedTag] = React.useState<String>("");
  const [filters, setFilters] = React.useState<any>("");
  const [myPersonalLibrary, setMyPersonalLibrary] = useState(false);
  const [selectFilters, setSelectFilters] = useState(false);
  const [atoz, setatoz] = useState(false);
  const [collectionFilter, setCollectionFilter] = useState("");

  const dispatch = useDispatch();

  const searchContainOrStartWith = useSelector(
    (state: any) => state.browseJournalSearch.containStartingWith
  );
  const enabledTags = useSelector(
    (state: any) => state.browseJournalSearch.knownAlphasearch
  );
  const bookData: any = useAppSelector(
    (state: any) => state.browseJournalSearch.bookResult
  );

  useEffect(() => {
    goToTop();
  }, []);

  // FOR TITLE A-Z
  const updateSelectedTags = (element: any, index: any) => {
    let temp = Array(28).fill(false);
    dispatch(updateBrowserPage(1));
    // usageReport("86");
    // logBasicSearchUsageData(86);

    if (selectedLetterTags[element]) {
      setSelectedLetterTags(temp);
      setSelectedTag("");
      dispatch(updateselectAlphabet(""));
    } else {
      if (element == 0) {
        temp[0] = true;
        setKeyWord("");
        setSelectedLetterTags(temp);
        setSelectedTag("");
        dispatch(
          setSearchKeyword({
            searchType: "",
            words: "",
          })
        );

        dispatch(updateselectAlphabet(""));
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTags(temp);
        dispatch(updateselectAlphabet("1"));
        setSelectedTag("");
      } else {
        temp[element] = true;
        setSelectedLetterTags(temp);
        setSelectedTag(String.fromCharCode(element + 63));
        dispatch(updateselectAlphabet(String.fromCharCode(element + 63)));
      }
    }
  };

  const onChangeKeyword_Books = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setKeyWord(e.target.value);
    let searchedLetters: any = "";
    if (e.target.value.length >= 3) {
      // @ts-ignore
      let searchedArrayLetters = e.target.value.split(" ");
      searchedArrayLetters?.map((element: string) => {
        searchedLetters += `${element}\\ of\\ `;
      });

      let apiJournalData = {
        resource_name_tk: `${searchedLetters.slice(0, -6)}*`,
      };

      let response = await booksAutoSuggestion(
        objectToQueryString(apiJournalData)
      );

      setAutoSearch(response?.data.data.jsonfacets.resource_name_tk.buckets);
    }
    dispatch(setKeyword(e.target.value));
  };

  return (
    <React.Fragment>
      <Typography sx={webStyles.textResult} variant="h5">
        Book Finder
      </Typography>
      <Box
        mr="3%"
        ml="3%"
        mt={"1%"}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <DropDownComponent items={conditions}></DropDownComponent>
        <Box style={{ flex: 1, alignSelf: "center" }}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={autoSearch?.map((option: any) => option.val) || []}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            value={keyWord}
            sx={{ width: 300 }}
            // @ts-ignore
            onChange={(event: any, newValue: string) => {
              setAutoSearch([]);
              setKeyWord(newValue);
              setKeyWords([
                ...keyWords,
                { searchType: searchContainOrStartWith, words: keyWord },
              ]);
              setIsOpen(false);
            }}
            // value={keyWord}
            size="small"
            // @ts-ignore
            style={{ width: "100%" }}
            onKeyDown={(e) => {
              //logBasicSearchUsageData(88);
              if (e.key === "Enter" && keyWord) {
                //usageReport("88");
                // logBasicSearchUsageData(88);
                setKeyWords([
                  ...keyWords,
                  { searchType: searchContainOrStartWith, words: keyWord },
                ]);
                dispatch(
                  setSearchKeyword({
                    searchType: searchContainOrStartWith,
                    words: keyWord,
                  })
                );
                dispatch(updateselectAlphabet(""));

                // setHandleEnter(true);
                setIsOpen(false);
              }
            }}
            renderInput={(params) => (
              <TextField
                placeholder="Title"
                onChange={onChangeKeyword_Books}
                sx={[
                  webStyles.textInputText,
                  { width: "100%", marginRight: "0.5%" },
                ]}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                  endAdornment: (
                    <InputAdornment position="end">
                      {keyWord ? (
                        <IconButton
                          onClick={() => {
                            dispatch(setKeyword(""));
                            setKeyWord("");
                            dispatch(
                              setSearchKeyword({
                                searchType: searchContainOrStartWith,
                                words: "",
                              })
                            );
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Button
          variant="contained"
          sx={webStyles.button}
          onClick={async () => {
            let res;
            //usageReport("88");
            // logBasicSearchUsageData(88);
            if (keyWord == "") {
              setMessage({
                messages: "Try for some appropriate keywords / terms",
                open: true,
              });
              return;
            }

            clearAllFilters();
            dispatch(
              setSearchKeyword({
                searchType: searchContainOrStartWith,
                words: keyWord,
              })
            );
            setKeyWords([
              ...keyWords,
              { searchType: searchContainOrStartWith, words: keyWord },
            ]);

            // if (tabIndex == 0) {
            //   setatoz(true);
            // }
            dispatch(updateselectAlphabet(""));
            dispatch(updateBrowserPage(1));
          }}
        >
          Search
        </Button>
      </Box>
      <Box ml={"3%"} mr={"3%"} mt={"1%"} display={"flex"}>
        {/* <AlphabeticalFilter
              selectedTags={selectedLetterTags}
              selectOnClick={updateSelectedTags}
            /> */}
        <AlphabeticalFilterForAuthor
          selectedTags={selectedLetterTags}
          selectOnClick={updateSelectedTags}
          enabledTags={enabledTags}
        />
      </Box>
      <Box sx={webStyles.mainContainer}>
        <Box ml={"3%"} mr={"3%"}>
          <Grid container mt={"2%"}>
            <Grid item xs={3}>
              <Box sx={{ paddingBlock: 2 }}>
                <Typography
                  fontWeight={"700"}
                  fontSize={"20px"}
                  lineHeight={"24px"}
                  style={webStyles.resulteText}
                >
                  Results{" "}
                  {bookData?.hits ? bookData?.hits.toLocaleString("en-IN") : ""}
                </Typography>
              </Box>
              {/* <CollectionsComponentOfBrowser
                  setMyPersonalLibrary={setMyPersonalLibrary}
                  setFilters={setFilters}
                  setCollectionFilter={setCollectionFilter}
                ></CollectionsComponentOfBrowser> */}

              <SearchFilterComponentOfBook
                setFilters={setFilters}
              ></SearchFilterComponentOfBook>
            </Grid>

            <Grid item xs={9}>
              {/* Header Div */}
              <Books
                myPersonalLibrary={myPersonalLibrary}
                handleEnter={handleEnter}
                setHandleEnter={setHandleEnter}
                selectedTag={selectedTag}
                setSelectFilters={setSelectFilters}
                filters={filters}
                selectFilters={selectFilters}
                // setatoz={setatoz}
                atoz={atoz}
                keyWords={keyWords}
                searchContainOrStartWith={searchContainOrStartWith}
                setSelectedTag={setSelectedTag}
                collectionFilter={collectionFilter}
                setMyPersonalLibrary={setMyPersonalLibrary}
              ></Books>
            </Grid>
          </Grid>
          <IconButton
            aria-label="Scroll to Top"
            sx={{
              position: "fixed",
              top: "63vh",
              right: "2vw",
              transform: "translateY(-50%)",
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFF",
              color: "primary.main",
              "&:hover": {
                border: "1px solid #D1D5DB",
                backgroundColor: "#FFF",
                color: "primary.main",
                boxShadow: 2,
              },
            }}
            onClick={() => goToTop()}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const webStyles = {
  button: {
    margin: "0.5%",
    // height: 47,
    paddingLeft: "4%",
    paddingRight: "4%",
    borderRadius: 2,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    backgroundColor: "#f5f5f5",
    flex: 1,
  },
  headerText: {
    fontFamily: "Lora",
    color: Colors.primary,
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: "0.9rem",
  },
  checkBox: {
    fontWeight: 400,
    fontSize: "0.875rem",
    color: Colors.primary,
    fontFmaily: "Helvetica Neue",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
    margin: "1% 3.5% 0% 3.5%",
  },
  textInputText: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
  },
  resulteText: {
    fontFamily: "Helvetica Neue",
    fontsSyle: "normal",
    fontWeight: 700,
  },
};
