import React, { useEffect, useState } from "react";
import Nav from "../Nav/MainNav";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  Tooltip,
  Modal,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Colors, authorRole } from "../../utils/constants";
import DdrForm from "./ddrForm";
import {
  getDDRConfirmationCall,
  getDDRFormDetailsCall,
  getDdrRequest,
  getDDRRequestLibraryListCall,
  getSearchResultsApiCall,
  getUserTypesCall,
  submitDDRCall,
} from "../../api/ddrFormApi";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import {
  goToTop,
  isStringOfArray,
  showIsProfileToast,
  sortAuthor,
  sortAuthorDetail,
} from "../../utils/helper";
import Text from "@mui/material/Typography";
import metaData from "../../utils/metadata";
import { AuthorFinderObj } from "../../types/interface";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearSearchTerm,
  updatePage,
  updateRow,
} from "../../store/slice/searchSlice";
import {
  clearAuthorSearchTerm,
  updateAuthorPage,
  updateAuthorRow,
} from "../../store/slice/authorFinderSlice";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updateAllFilter,
  updateAuthor,
  updateResearcher,
  updateSpeaker,
} from "../../store/slice/filterSlice";
import { clearQuery } from "../../store/slice/queryForCountSlice";
import { onAuthorResult as authorsearch } from "../../screens/AuthorFinder/onAuthorResult";
import { onSearch as search } from "../../screens/Search/onSearch";
import IdIcon from "../../assets/img/id.png";
import { MathJax } from "better-react-mathjax";
import { notify } from "../../utils/Notify";
import CloseIcon from "@mui/icons-material/Close";
import { modalBaseStyles } from "../modals/RootModal";
import { usagelogApi } from "../../api/usageReportApi";

function createData(Journal: string, Volume_No: number, Issue_No: number) {
  return { Journal, Volume_No, Issue_No };
}

function DdrScreen() {
  const location = useLocation();
  const details = location?.state?.data;
  const resultFrom = location?.state;

  let infotnaticsCustomerId = sessionStorage.getItem("informaticscustomer_id");
  let userId = sessionStorage.getItem("user_id");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [DdrCustomerList, setDdrCustomerList] = useState([]);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [institution, setInstitution] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [profileType, setprofileType] = React.useState("");
  const [adminNo, setAdminNo] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [iilcheck, setIilcheck] = React.useState(true);
  const [city, setCity] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [DDRRequestTo, setDDRRequestTo] = React.useState("");
  const [ArticleDeliveryTo, setArticleDeliveryTo] = React.useState("");
  const [submitData, setSubmitData] = React.useState<any>();
  const [userDeclarationFlag, setUserDeclarartionFlag] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [authors, setAuthors] = React.useState([]);
  const [regionalInstitute, setRegionalInstitute] = React.useState("");
  const [journalType, setJournalType] = React.useState("2d journals");
  const [volume, setVolume] = React.useState("");
  const [issue, setIssue] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [duplicateData, setDuplicateData] = React.useState(false);
  const [signUpError, setSignupError] = React.useState(false);
  const [getUser, setUser] = React.useState();
  const [validatingFlag, setValidatingFlag] = React.useState(false);
  const [showArticleDeliveryToDropDown, setShowArticleDeliveryToDropDown] =
    React.useState(false);
  const [showDDRRequestToDropDown, setShowDDRRequestToDropDown] =
    React.useState(false);
  const [ddrRequestForm, setDdrRequestForm] = React.useState();
  const [showRegionalStationDropDown, setShowRegionalStationDropDown] =
    React.useState(false);
  const [profileTypeList, setprofileTypeList] = React.useState<any[]>([]);
  const [regionalStationList, setRegionalStationList] = React.useState([
    {
      label: "Yes",
      value: "1",
    },
    {
      label: "No",
      value: "2",
    },
  ]);
  const [DDRRequestToList, setDDRRequestToList] = React.useState<any[]>([]);
  const [articleDeliveryToList, setArticleDeliveryToList] = React.useState<
    any[]
  >([]);
  console.log(
    "datassri",
    Array.isArray(details?.author_details),
    isStringOfArray(details?.author_details),
    sortAuthor(details?.author_details)
  );
  const librarianEmail: any = useSelector(
    (state: any) => state?.customer?.customerDetails?.librarian_emailid
  );
  const profileData = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const customerId = useSelector((state: any) => state.login);

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const fromJournalToc =
    location?.state?.from === "BrowseJournalTableSearch/BrowseJournalSearch" ||
    location?.pathname === "/BrowseJournalTableSearch/BrowseJournalSearch";

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  const userDetails = useAppSelector((state) => state.customer.customerDetails);

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch.collectionsValueJournal
  );

  const contextId =
    resultFrom?.resultFrom === "searchResult" ||
    resultFrom?.resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom?.resultFrom ===
        "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logDDRRequestUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");
    usagelogApi(
      profileData?.user_id,
      profileData?.informaticscustomer_id,
      userDetails?.consortiamaster_id,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  const updateDDRRequestForm = async () => {
    const apiData = {
      UserMasterId: userId,
      customerId: infotnaticsCustomerId,
      // customerId: "1877",
      article_id: details.article_id,
      // article_id: "21380619",
      RequestToCustomerId: "",
    };

    const response = await getDDRRequestLibraryListCall(
      "?" +
        objectToQueryString({
          customerId: infotnaticsCustomerId,
          // customerId: "1877",
          article_id: details.article_id,
          // article_id: "21380619",
        })
    );

    if (response?.data.message == "Ok") {
      {
        apiData.RequestToCustomerId = Object.keys(
          response?.data?.data[0][0]?.customer_list[0]
        )[0];
        let res = await getDDRFormDetailsCall(
          "?" + objectToQueryString(apiData)
        );
        let userDetails: any = res?.data.data[0][0];
        setUser(userDetails);
        setEmail(userDetails?.user_email_id);
        setDepartment(userDetails?.department);
        setPhone(userDetails?.contact_no);
        setDdrRequestForm(userDetails);
        let articleList = userDetails?.article_deliver_to;

        const list: any[] = [];
        articleList?.map((element: any, index: number) => {
          let key = element.informaticsconsortiamember_id;
          list[index] = {
            label: element.customername,
            value: key,
          };

          setArticleDeliveryToList(list);
        });

        // setDDRRequestToList(response?.data?.data[0][0]?.customer_list);
        const reqList = response?.data?.data[0][0]?.customer_list;

        if (Array.isArray(reqList)) {
          let arr = reqList.map((obj: any) => {
            let item = {
              label: Object.values(obj)[0],
              value: Object.keys(obj)[0],
            };
            return item;
          });

          setDDRRequestToList(arr);
        }
      }
    }
  };

  const updateUserProfileList = async () => {
    const response = await getUserTypesCall();
    const apiUserList = response?.data.data[0][0];
    const userList: any[] = [];
    apiUserList?.map((element: any, index: number) => {
      let key = (index + 1).toString();
      userList[index] = {
        label: element[key],
        value: key,
      };
      setprofileTypeList(userList);
    });
  };

  const updateArticleDetails = async () => {
    const apiData = {
      // article_id: "21380619",
      article_id: details.article_id,
    };
    const response = await getSearchResultsApiCall(
      "?" + objectToQueryString(apiData)
    );

    let apiResponse = response?.data.data.docs[0];
    setTitle(apiResponse.title);
    setAuthors(apiResponse.authors_tk);
    setJournalType(apiResponse?.resource_name);
    setVolume(apiResponse.volumefrom);
    setIssue(apiResponse.issuenumberfrom);
    setLoading(false);
  };

  useEffect(() => {
    // updateDDRRequestForm();
    // const getDdrRequestApi = async () => {
    //   const getDdr = await getDdrRequest(details.article_id);
    //   // @ts-ignore
    //   console.log("get ddr req", getDdr.data.data[0]);
    //   setDdrCustomerList(getDdr?.data?.data[0]);
    // };
    // getDdrRequestApi();
    updateDDRRequestForm();
    updateUserProfileList();
    updateArticleDetails();
    goToTop();
  }, []);

  const rows = [
    createData(
      details?.resource_name,
      details?.volumefrom,
      details?.issuenumberfrom
    ),
  ];
  const validate = async (formData?: any) => {
    // setValidatingFlag(false);
    // if (
    //   !firstName ||
    //   !lastName ||
    //   !email ||
    //   !institution ||
    //   !department ||
    //   !adminNo ||
    //   !DDRRequestTo ||
    //   !ArticleDeliveryTo ||
    //   !libraryAdmin ||
    //   !userDeclarationFlag
    // ) {
    //   setValidatingFlag(true);
    // }
    // else {
    if (!iilcheck) {
      showIsProfileToast("Enable ILL declaration");
      return;
    }
    let x = {
      requestingcustomer_id: DDRRequestTo,
      deliveringcustomer_id: ArticleDeliveryTo
        ? ArticleDeliveryTo
        : DDRRequestToList[0]?.value,
      article_id: details.article_id,
      requesteduserid: customerId?.user_id,
      requesteduseremailid: formData?.email,
      requestremarks: formData?.message,
      requester_username: `${formData?.firstName} ${formData?.lastName}`,
      requester_department: formData?.department,
      requester_rollno: formData?.rollNo,
      requester_mobile: formData?.mobileNum,
      requester_designation: formData?.profileType,
      regional_institute: regionalInstitute,
      regional_city: formData?.city,
      regional_pin: formData?.pin,
      librarian_emailid: formData?.admin,

      requester_designation_right: formData?.otherProfile || "",
      //university or college, instituionID, regional station or college, city, pin, article delivery to, library admin
    };
    {
      formData?.profileType !== "Others" &&
        delete x.requester_designation_right;
    }
    if (duplicateData) {
      Object.assign(x, { is_duplicate: true });
    }
    let apiData = {
      data: [x],
    };

    const apiFormData = new FormData();
    apiFormData.append("detailsjson", JSON.stringify(apiData));

    let response = await submitDDRCall(apiFormData);
    if (response?.data.message[0][0][0]?.ddr_reference_id) {
      const getcall = await getDDRConfirmationCall(
        "?" +
          objectToQueryString({
            ddr_reference_id: response?.data.message[0][0][0]?.ddr_reference_id,
          })
      );
      if (
        getcall?.data?.message == "Ok" ||
        getcall?.data?.message[0]?.ddr_reference_id
      ) {
        notify("success", "Request sent successfully");
        navigate(-1);
      }
    }

    // }
  };

  async function handleAuthor(authorName: string) {
    dispatch(clearSearchTerm());
    dispatch(clearAuthorSearchTerm());
    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(clearQuery());
    dispatch(clearAllFiltersValue());

    dispatch(updatePage(1));
    dispatch(updateRow(15));

    dispatch(updateAuthorPage(1));
    dispatch(updateAuthorRow(15));

    if (details?.authors?.length) {
      dispatch(updateAuthor([authorName]));
      // logAbstractUsageData(19);
      let appliedFilter = {
        key: "author",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (details?.speakers?.length) {
      // logAbstractUsageData(22);

      dispatch(updateSpeaker([authorName]));
      let appliedFilter = {
        key: "speaker",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (details?.researcher_tk == authorName) {
      dispatch(updateResearcher([authorName]));
      dispatch(
        updateAllFilter({
          key: "researcher",
          value: authorName,
        })
      );
    }
    // usageReport("19");

    // let data = dispatch(search());
    // !!data && navigate("/basicSearchScreen");

    if (authorReducer.isAuthorResult === true) {
      let res = dispatch(authorsearch());
      !!res && navigate("/authorfindersearch");
    } else if (authorReducer.isAuthorResult === false) {
      let res = dispatch(search());
      !!res && navigate("/basicSearchScreen");
    }
  }

  const handleDuplicateData = () => {
    validate(submitData);
    setIilcheck(false);
    setDuplicateData(false);
    logDDRRequestUsageData(36, details);
  };
  return (
    <Box>
      <Box padding={"1.2% 3%"} sx={webStyles.flexBox}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosNewIcon
            sx={{ alignSelf: "center" }}
            fontSize={"small"}
          ></ArrowBackIosNewIcon>
        </IconButton>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontFamily: "Lora",
            fontWeight: 700,
            alignSelf: "center",
          }}
        >
          {" "}
          Request for Photocopy of an article
        </Typography>
      </Box>
      <Divider></Divider>
      <Box sx={{ padding: "1% 3%" }}>
        <Box
          sx={{
            backgroundColor: Colors.white,
            borderRadius: "16px",
            border: "1px solid #D1D5DB",
          }}
        >
          <Box padding={"1%"}>
            <>
              <Typography style={webStyles.heading}>Article Details</Typography>
              <Typography sx={[webStyles.titleText]} gutterBottom>
                Title
              </Typography>
              <MathJax>
                <Typography sx={webStyles.text}>
                  <div dangerouslySetInnerHTML={{ __html: details?.title }} />
                </Typography>
              </MathJax>
              {/* {Array.isArray(details?.author_details) &&
              isStringOfArray(details?.author_details) &&
              sortAuthor(details?.author_details)?.map(
                (name: string, i: number) => {
                  
                }
                )} */}
              <Typography sx={webStyles.titleText}>Authors</Typography>
              {details?.author_details?.length &&
              !isStringOfArray(details?.author_details) ? (
                <Box sx={{ display: "flex", marginTop: "5px" }} flexWrap="wrap">
                  {sortAuthorDetail(details?.author_details)?.map(
                    (obj: any, i) => {
                      const firstName = obj?.author_fname
                        ? obj?.author_fname
                        : "";
                      const lastName = obj?.author_lname
                        ? obj?.author_lname
                        : "";

                      const reversedName = lastName
                        ? lastName + ", " + firstName
                        : firstName;
                      const displayName = lastName
                        ? lastName.replace(/,/g, " ") +
                          ", " +
                          firstName.replace(/,/g, " ")
                        : firstName.replace(/,/g, " ");
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip title={authorRole[obj?.datauthorrole_id]}>
                            <Button
                              sx={{
                                "&:hover": {
                                  background: authorReducer.searchTerm
                                    ? !authorReducer.searchTerm?.includes(
                                        reversedName
                                      )
                                      ? "transparent"
                                      : "#E4DCFE"
                                    : "transparent",
                                  textDecoration: "underline",
                                },

                                background: authorReducer.searchTerm
                                  ? !authorReducer.searchTerm?.includes(
                                      reversedName
                                    )
                                    ? ""
                                    : "#E4DCFE"
                                  : "",
                                padding: 0,
                                textDecoration: "underline",
                                minWidth: 0,
                                paddingLeft: i === 0 ? 0 : 1,
                                paddingRight: 1,
                                color: fromJournalToc ? "black" : "",
                              }}
                              onClick={() => {
                                if (!fromJournalToc) {
                                  handleAuthor(reversedName);
                                }
                              }}
                            >
                              {displayName.length ? displayName : ""}{" "}
                            </Button>
                          </Tooltip>
                          {obj?.orc_id ? (
                            <a
                              href={`https://orcid.org/${obj?.orc_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={IdIcon}
                                style={{
                                  paddingLeft: "5px",
                                  marginTop: "8px",
                                  paddingRight: "6px",
                                }}
                                alt="IdIcon"
                              />
                            </a>
                          ) : null}
                          <Divider
                            orientation="vertical"
                            style={{
                              height: "60%",
                              alignSelf: "center",
                            }}
                          />
                        </Box>
                      );
                    }
                  )}
                </Box>
              ) : null}

              <Text
                component={"div"}
                sx={{
                  textAlign: "left",
                  fontSize: "16px",
                  color: Colors.primary,
                  fontStyle: "italic",
                  cursor: "pointer",
                  marginBlock: "12px",
                }}
              >
                {metaData(details)}
              </Text>
            </>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: Colors.white,
            borderRadius: "16px",
            border: "1px solid #D1D5DB",
            marginTop: "1%",
          }}
        >
          <DdrForm
            ddrList={DdrCustomerList}
            validate={validate}
            DdrCustomerList={DdrCustomerList}
            firstName={firstName}
            lastName={lastName}
            email={email}
            institution={institution}
            department={department}
            profileType={profileType}
            adminNo={adminNo}
            phone={phone}
            city={city}
            pin={pin}
            message={message}
            DDRRequestTo={DDRRequestTo}
            ArticleDeliveryTo={ArticleDeliveryTo}
            libraryAdmin={librarianEmail}
            userDeclarationFlag={userDeclarationFlag}
            regionalInstitute={regionalInstitute}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setDepartment={setDepartment}
            setPhone={setPhone}
            setTitle={setTitle}
            setAuthors={setAuthors}
            setVolume={setVolume}
            setIssue={setIssue}
            setLoading={setLoading}
            setValidatingFlag={setValidatingFlag}
            setprofileTypeList={setprofileTypeList}
            regionalStationList={regionalStationList}
            DDRRequestToList={DDRRequestToList}
            articleDeliveryToList={articleDeliveryToList}
            setDDRRequestTo={setDDRRequestTo}
            iilcheck={iilcheck}
            setIilcheck={setIilcheck}
            data={getUser}
            setArticleDeliveryTo={setArticleDeliveryTo}
            details={details}
            setSubmitData={setSubmitData}
            ddrRequestForm={ddrRequestForm}
            setDuplicateData={setDuplicateData}
            resultFrom={resultFrom}
          ></DdrForm>
        </Box>
      </Box>
      <Modal
        open={duplicateData}
        onClose={() => {}}
        aria-labelledby="DeleteFolderModal-Title"
        aria-describedby="DeleteFolderModal-Description"
      >
        <Box
          sx={{
            ...modalBaseStyles,
            display: "flex",
            flexDirection: "column",
            width: "35vw",
            maxHeight: "77vh",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "10%",
              display: "flex",
              marginLeft: "auto",
              mb: 2,
            }}
          >
            <IconButton
              onClick={() => {
                setDuplicateData(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Text sx={{}}>
            This is a duplicate request. Do you want to make the request again?
          </Text>

          <Box sx={{ width: "100%", my: 2 }}>
            <Button
              variant="contained"
              sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
              onClick={handleDuplicateData}
            >
              OK
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setDuplicateData(false);
              }}
              sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default DdrScreen;

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.white,
  },
  heading: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: "1.5rem",
  },
  text: {
    fontFamily: "Helvetica Neue",
    fontWeight: 400,
    fontSize: "1rem",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "0.875rem",
    fontFamily: "Helvetica Neue",
    marginTop: "0.9%",
  },
};
