import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getMyFolders } from "../../../api/MyLibrary/MyFolders.api";
import { GetMyFoldersData } from "../../../types/GetMyFolders.types";
import { useQuery } from "@tanstack/react-query";
import { setModal } from "../../../store/slice/modalSlice";

export const useMyFolder = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.user_id);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentFolder, setCurrentFolder] = useState<GetMyFoldersData | null>(
    null
  );

  const { data: myFolders } = useQuery({
    queryKey: ["GetMyFolders"],
    queryFn: () => getMyFolders(userId),
    select: ({ data }): GetMyFoldersData[] => data.data[0][0],
  });

  const closeMenu = () => setAnchorEl(null);

  const handleOptionClick = (
    event: React.MouseEvent<HTMLElement>,
    row: GetMyFoldersData
  ) => {
    event.stopPropagation();
    setCurrentFolder(row);
    setAnchorEl(event.currentTarget);
  };

  const handleTableCellClick = (obj: { id: number; name: string }) =>
    navigate("/my-library/folders/" + obj?.id, {
      state: {
        folderId: obj?.id,
        folderName: obj?.name,
        allFolders: myFolders,
      },
    });

  const addModal = () =>
    dispatch(
      setModal({
        modalType: "ADD_FOLDER",
        modalProps: {
          show: true,
          title: "Add New Folder",
          isExternalLinks: false,
        },
      })
    );

  return {
    anchorEl,
    closeMenu,
    currentFolder,
    handleOptionClick,
    handleTableCellClick,
    myFolders,
    addModal,
  };
};
